// https://stackoverflow.com/a/42389266/974351

const Plugin = {};

Plugin.install = (Vue) => {
	Vue.directive('focus', {
		inserted(el) {
			// Focus the element
			el.focus();
		},
		update(el, binding) {
			let value = binding.value;
			if (value) {
				Vue.nextTick(() => {
					el.focus();
				});
			}
		},
	});
};

export default Plugin;
