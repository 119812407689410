var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "decks-container" }, [
    _vm.title
      ? _c("h3", { staticClass: "decks-container__heading ui header" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      staticClass: "ui centered inline loader medium",
      class: { active: !this.ready && !_vm.shouldShowPlaceholders },
    }),
    _vm._v(" "),
    _vm.shouldDisplayEmptyState
      ? _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [_c("EmptyStateDecks", [_vm._t("noDecksMessage")], 2)],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-deck-info-cards-grid" },
      _vm._l(_vm._decks, function (deck, index) {
        return _c("ListCard", {
          key: _vm.deckKeys(index),
          ref: "deckItem",
          refInFor: true,
          attrs: {
            list: deck,
            "source-deck": _vm.sourceDeck,
            loading: _vm.shouldShowPlaceholders,
            "show-set-theme-image-button": _vm.showSetThemeImageButton,
            "show-cta-buttons": _vm.showCtaButtons,
            "selected-deck-id": _vm.selectedDeckId,
            "is-link": _vm.isLink,
          },
          on: { "card-clicked": _vm.onCardClicked },
        })
      }),
      1
    ),
    _vm._v(" "),
    !_vm.shouldDisplayEmptyState
      ? _c(
          "div",
          { staticClass: "decks-container__navigation-section" },
          [
            _vm.requestMoreDecks
              ? _c(
                  "button",
                  {
                    staticClass:
                      "decks-container__load-more ui right labeled icon button",
                    class: { loading: _vm.busy },
                    on: { click: _vm.loadMore },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm.noMoreItems
                            ? _vm.$t("dashboard.noMoreDecks")
                            : _vm.$t("dashboard.loadMore")
                        ) +
                        "\n\t\t\t"
                    ),
                    _c("i", { staticClass: "chevron down icon" }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.moreLink !== ""
              ? _c(
                  "RouterLink",
                  {
                    staticClass:
                      "decks-container__see-more ui tiny right labeled icon circular button",
                    attrs: { to: { name: _vm.moreLink } },
                  },
                  [
                    _c("i", { staticClass: "right chevron icon" }),
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.$t("dashboard.seeMore")) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }