var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-offset-menu",
      class: { "c-offset-menu--active": _vm.isMenuActive },
      attrs: { "aria-hidden": !_vm.isMenuActive },
    },
    [
      _c("FocusLock", { attrs: { disabled: !_vm.isMenuActive } }, [
        _c(
          "div",
          {
            staticClass: "c-offset-menu__list",
            class: { "c-offset-menu__list--fade": _vm.isMenuActive },
          },
          [
            _c(
              "div",
              { staticClass: "c-offset-menu__item c-offset-menu__header" },
              [
                _c(
                  "button",
                  {
                    staticClass: "c-offset-menu__btn-close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.hideOffsetMenu.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "c-offset-menu__icon-close icon close",
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("PageNav", { attrs: { "is-logged-in": _vm.isLoggedIn } }),
            _vm._v(" "),
            _c("hr", { staticClass: "separator" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "language-section",
                on: {
                  click: function ($event) {
                    _vm.onLanguageClick && _vm.onLanguageClick()
                  },
                },
              },
              [
                _c("span", { staticClass: "language-section__title" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.$t("language")) + ":\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "language-section__value" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.activeLocale.text) +
                      "\n\t\t\t\t\t"
                  ),
                  _c("img", {
                    staticClass: "language-section__flag",
                    attrs: {
                      alt: _vm.activeLocale.text,
                      src: _vm.activeLocale.flagUrl,
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "language-section__icon icon angle right",
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.isLoggedIn
              ? _c("div", { staticClass: "c-offset-menu__item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "c-offset-menu__link",
                      attrs: { href: "/auth/logout" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.logout()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$t("header.logout")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }