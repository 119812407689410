var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TextColorsRenderer",
    {
      attrs: { text: _vm.tagName },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function (ref) {
            var foreground = ref.foreground
            var background = ref.background
            return [
              _c(
                "RouterLink",
                {
                  attrs: {
                    to: {
                      name: "tag",
                      params: { id: _vm.tagName },
                    },
                  },
                },
                [
                  _c(
                    "strong",
                    {
                      staticClass: "tag",
                      class: { "tag--highlighted": _vm.highlighted },
                      style: {
                        background: background,
                        color: foreground,
                      },
                    },
                    [
                      _vm.isRecommended
                        ? _c("span", { staticClass: "tag__icon" }, [
                            _vm._v("⭐"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "tag__text" }, [
                        _vm._v(_vm._s(_vm.tagName)),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v("\n\t,\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }