<template>
	<div
		ref="drimmer"
		class="drimmer"
	/>
</template>

<script>
	const steps = [
		{
			selector: '.c-dashboard__action-btns-form',
		},
		{
			selector: '.c-dashboard__popular-searches',
		},
		{
			selector: '.your-activity .toggle-button',
		},
		{
			selector: '.c-deck-info-cards-grid .c-deck-info-card',
		},
		{
			selector: '[data-qa="steps-button-next"]',
		},
	];
	const offset = 20;

	export default {
		name: 'Drimmer',
		data: () => ({
			currStep: -1,
			isVisible: false,
		}),
		mounted() {
			document.addEventListener('keypress', (evt) => {
				if (evt.code === 'KeyQ') {
					this.toggleVisibility();
				}

				if (evt.code === 'KeyA') {
					if (evt.shiftKey) {
						this.prevStep();
					} else {
						this.nextStep();
					}
				}
			});
		},
		methods: {
			toggleVisibility() {
				this.$refs.drimmer.style.opacity = this.isVisible ? 0 : 1;
				this.isVisible = !this.isVisible;
			},
			prevStep() {
				this.goToStep(steps[--this.currStep]);
			},
			nextStep() {
				this.goToStep(steps[++this.currStep]);
			},
			goToStep(step) {
				if (!step) {
					console.log('No next step available');
					return;
				}

				const node = document.querySelector(step.selector);
				const bodyRect = document.body.getBoundingClientRect();
				const elementRect = node.getBoundingClientRect();

				const top = elementRect.top - bodyRect.top;
				const left = elementRect.left - bodyRect.left;
				const width = elementRect.width;
				const height = elementRect.height;

				const drimmer = this.$refs.drimmer;

				drimmer.style.top = `${top - offset}px`;
				drimmer.style.left = `${left - offset}px`;
				drimmer.style.width = `${width + offset * 2}px`;
				drimmer.style.height = `${height + offset * 2}px`;
			},
		},
	};
</script>

<style scoped>
	.drimmer {
		z-index: 10000;
		box-shadow: rgba(33, 33, 33, 0.5) 0 0 4px 4px inset, rgba(33, 33, 33, 0.5) 0 0 0 5000px;
		position: absolute;
		pointer-events: none;
		border-radius: 50px;
		transition: all 500ms;
		opacity: 0;
	}
</style>
