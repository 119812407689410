import axios from 'axios';
import {
	normalize, epochInDays, isInStreak, streak, epochIntoDateString,
} from '@/store/playStats/helpers';

const getDefaultState = () => ({
	stats: [],
	todayDayEpoch: epochInDays(new Date()),
	requestCached: false,
	requestReady: false,
});

export default () => ({
		namespaced: true,
		state: getDefaultState(),
		getters: {
			nearestDay(state) {
				return Math.max(...state.stats.map(item => item.date));
			},
			streak(state, { nearestDay }) {
				if (isInStreak(nearestDay, state.todayDayEpoch)) {
					return streak(state.stats);
				}

				return 0;
			},
			swipesToday({ stats, todayDayEpoch }) {
				const foundItem = stats.find(item => item.date === todayDayEpoch);

				if (foundItem) {
					return foundItem.count;
				}

				return 0;
			},
		},
		mutations: {
			markCached(state) {
				state.requestCached = true;
			},
			markReady(state) {
				state.requestReady = true;
			},
			storePlayStats(state, { stats }) {
				state.stats = stats;
			},
			incrementGuesses({ stats, todayDayEpoch }) {
				let foundItem = stats.find(item => item.date === todayDayEpoch);

				if (!foundItem) {
					foundItem = ({ _id: epochIntoDateString(todayDayEpoch), date: todayDayEpoch, count: 0 });
					stats.push(foundItem);
				}

				foundItem.count++;
			},
			resetState(state) {
				// Merge rather than replace so we don't lose observers
				// https://github.com/vuejs/vuex/issues/1118
				Object.assign(state, getDefaultState());
			},
		},

		actions: {
			async fetchUserPlayStats({ commit, state, rootGetters }) {
				if (state.requestCached) {
					return;
				}
				commit('markCached');
				let stats;
				if (rootGetters.isLoggedIn) {
					stats = await axios.get('/user/play-stats');
				} else {
					stats = { data: [] };
				}
				stats = normalize(stats.data);

				commit('markReady');
				commit('storePlayStats', { stats });
			},
			resetState({ commit }) {
				commit('resetState');
			},
		},
	});
