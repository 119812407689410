<template>
	<ul class="c-language-radio">
		<li
			v-for="locale in locales"
			:key="locale.code"
			:class="{
				'c-language-radio__item': true,
				'c-language-radio__item--active': activeLocaleCode === locale.code,
			}"
			@click="switchLocale(locale.code)"
		>
			{{ locale.text }}
			<img
				class="c-language-radio__flag"
				:alt="locale.text"
				:src="locale.flagUrl"
			>
		</li>
	</ul>
</template>

<script>
	import { locales } from '@/assets/js/i18n/configuration';

	export default {
	name: 'LanguagesRadio',
		data: () => ({ locales }),
		computed: { activeLocaleCode() { return this.$i18n.locale; } },
		methods: {
			switchLocale(locale) {
				this.$validator.localize(locale);
				this.$emit('language-changed', locale);
				this.$store.dispatch('setLocalStorage', { key: 'preferredLanguage', value: locale });
				this.$i18n.locale = locale;
			},
		},
	};
</script>

<style scoped>
	.c-language-radio {
		list-style-type: none;
		padding-left: 0;
		margin: 0;
	}

	.c-language-radio__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: var(--spacing-8) var(--spacing-16);
		font-weight: bold;
		cursor: pointer;
		margin: 0 calc(var(--spacing-16) * -1);
		transition: background 200ms ease;
	}

	.c-language-radio__item--active, .c-language-radio__item:hover {
		background: var(--grey-8);
	}

	.c-language-radio__flag {
		height: 18px;
		border: 2px solid white;
		box-sizing: initial;
		background: white;
		border-radius: 3px;
	}
</style>
