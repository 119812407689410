<template>
	<DecksContainer
		:source-deck="deck"
		:decks="decks"
		:title="$t('whyDontYouTrySomeMoreDecks')"
		:fetch-decks="fetchDecks"
		:results-amount="resultsAmount"
		:more-link="''"
		style="--decks-container-heading-border-bottom: none"
		@card-clicked="onCardClicked"
	/>
</template>
<script>
	import DecksContainer from '@/components/dashboard/DecksContainer';
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';

	export default {
		name: 'SimilarDecks2',
		components: {
			DecksContainer,
		},
		props: {
			resultsAmount: {
				type: Number,
				default: null,
			},
			deck: {
				type: Object,
				required: true,
			},
		},
		computed: {
			decks() {
				return this.$store.getters.similarDecksByDeckId(this.deck._id) || [];
			},
		},
		methods: {
			onCardClicked(e) {
				logAnalyticsEvent(...events.suggestedDeckCardClick, e.deckId);
			},
			async fetchDecks() {
				await this.$store.dispatch('fetchSimilarDecks', { deckId: this.deck._id });
			},
		},
	};
</script>
