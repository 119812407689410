// https://optimize.google.com/optimize/home/

if (typeof window.gtag === 'undefined') {
	window.gtag = function gtag() {
		// eslint-disable-next-line prefer-rest-params
		window.dataLayer && window.dataLayer.push(arguments);
	};
}

const experiments = [
	{
		id: '2x2brKMCR8i6hdQVgq3sBg',
		name: 'knowledgeProgress',
	},
	{
		id: 'cN5mxMWmRI-8stC3H8AAFA',
		name: 'aaTest',
	},
];

/**
 * This function will set in localStorage the values from the experiments if one matches.
 * If value is 1, then it's true, otherwise false.
 */
export const initExperiments = $store => gtag('event', 'optimize.callback', {
	callback(value, id) {
		const experiment = experiments.find(experiment => experiment.id === id);

		if (experiment) {
			$store.dispatch(
				'setLocalStorage',
				{
					key: experiment.name,
					value: value === '1',
				},
			);
		}
	},
});
