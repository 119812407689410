var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 90 90" } },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M77.99 19.58L37.13.53c-2.82-1.32-6.17-.09-7.49 2.73l-3.88 8.31 39.88 8.84c6.08 1.35 9.91 7.37 8.57 13.44l-3.04 13.7 9.55-20.48c1.31-2.82.09-6.17-2.73-7.49z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M64.42 25.91L20.4 16.15c-3.04-.67-6.05 1.24-6.72 4.28L12 28h38.73C56.96 28 62 33.05 62 39.27v23.56l6.7-30.2c.67-3.04-1.24-6.05-4.28-6.72z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d: "M5.65 33.64h45.09c3.11 0 5.64 2.53 5.64 5.64v45.09c0 3.11-2.53 5.64-5.64 5.64H5.65c-3.11 0-5.64-2.53-5.64-5.64V39.28a5.64 5.64 0 0 1 5.64-5.64z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }