var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "c-feedback",
          attrs: { type: "button" },
          on: { click: _vm.onClick },
        },
        [
          _c("span", [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("satisfactionFeedback.tellUsWhatDoYouThink")) +
                "\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "c-feedback__icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "20",
                height: "20",
                viewBox: "0 0 24 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M22 3v13h-11.643l-4.357 3.105v-3.105h-4v-13h20zm2-2h-24v16.981h4v5.019l7-5.019h13v-16.981zm-5 6h-14v-1h14v1zm0 2h-14v1h14v-1zm-6 3h-8v1h8v-1z",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "SuiModal",
        {
          attrs: { size: "tiny" },
          model: {
            value: _vm.modalOpened,
            callback: function ($$v) {
              _vm.modalOpened = $$v
            },
            expression: "modalOpened",
          },
        },
        [
          _c("i", { staticClass: "close icon", on: { click: _vm.closeModal } }),
          _vm._v(" "),
          _c(
            "SuiModalContent",
            { attrs: { scrolling: "" } },
            [
              _c("TransitionExpand", [
                !_vm.submitted
                  ? _c(
                      "div",
                      [
                        _c("p", { staticClass: "paragraph" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.$t(
                                  "satisfactionFeedback.whatDoYouThinkOfThis"
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("FeedbackAnswers", {
                          attrs: {
                            "more-answers": "",
                            "no-spacing": "",
                            "left-aligned": "",
                            "on-feedback": _vm.onFeedback,
                            highlighted: _vm.sentiment,
                          },
                        }),
                        _vm._v(" "),
                        _c("TransitionExpand", [
                          _vm.sentiment
                            ? _c(
                                "div",
                                [
                                  _c("div", { staticClass: "spacer-24" }),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "paragraph" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t(
                                            "satisfactionFeedback.whatKindOfFeedback"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                        gap: "var(--spacing-8)",
                                      },
                                    },
                                    _vm._l(_vm.feedbackTypes, function (ref) {
                                      var value = ref.value
                                      var text = ref.text
                                      return _c(
                                        "button",
                                        {
                                          key: value,
                                          staticClass: "c-btn c-btn--small",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              _vm.kindOfFeedback = value
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.$t(text)) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("TransitionExpand", [
                                    _vm.kindOfFeedback
                                      ? _c("div", [
                                          _c("div", {
                                            staticClass: "spacer-24",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "paragraph" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.$t(_vm.feedbackText)
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ui form" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "field" },
                                                [
                                                  _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.content,
                                                        expression: "content",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "input-customisation",
                                                    attrs: { rows: "3" },
                                                    domProps: {
                                                      value: _vm.content,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.content =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "spacer-12",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "submit-container" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "c-btn c-btn--cta c-btn--small",
                                                  attrs: { type: "button" },
                                                  on: { click: _vm.submit },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ui icon paper plane",
                                                  }),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "satisfactionFeedback.submit"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("TransitionExpand", [
                _vm.submitted
                  ? _c("div", [
                      _c("div", { staticClass: "feedback-thanks" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.$t("satisfactionFeedback.thankYouForFeedback")
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "submit-container" }, [
                        _c(
                          "button",
                          {
                            staticClass: "c-btn c-btn--cta c-btn--small",
                            attrs: { type: "button" },
                            on: { click: _vm.closeModal },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.$t("close")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }