import axios from 'axios';
import { fetchStream } from '@/assets/js/utils/fetchStream';

const getDefaultState = () => ({
});

export default () => ({
	namespaced: true,
	state: getDefaultState(),
	getters: {},
	mutations: {},

	actions: {
		async generateCards(_, { deckId, ...payload }) {
			const url = `/generator/create-cards/${deckId || ''}`;
			return axios.post(url, payload);
		},
		async generateMoreCards(_, { deckId, ...payload }) {
			return axios.post(`/generator/create-more-cards/${deckId}`, payload);
		},

		async assistant(_, { conversationId, onChunk, ...payload }) {
			const url = `/generator/assistant/${conversationId || ''}`;

			// eslint-disable-next-line no-return-await
			return await fetchStream({
				url,
				options: {
					method: 'POST',
					body: JSON.stringify(payload),
					headers: {
						'Content-Type': 'application/json',
					},
				},
			}, onChunk);
		},
	},
});
