var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FocusLock",
    [
      _c(
        "SuiModal",
        {
          attrs: { open: _vm.opened },
          on: {
            changed: function (opened) {
              return _vm.$emit("change", opened)
            },
          },
        },
        [
          _c("ModalCloseButton", {
            attrs: { "modal-shown": _vm.opened },
            on: {
              input: function (opened) {
                return _vm.$emit("change", opened)
              },
            },
          }),
          _vm._v(" "),
          _c("SuiModalHeader", [_vm._v("Manage Deck Metadata")]),
          _vm._v(" "),
          _c(
            "SuiModalContent",
            { staticStyle: { color: "black" }, attrs: { scrolling: "" } },
            [
              _c("AnswersRadio", {
                staticStyle: { "margin-bottom": "var(--spacing-12)" },
                attrs: { compact: "", options: _vm.models },
                model: {
                  value: _vm.model,
                  callback: function ($$v) {
                    _vm.model = $$v
                  },
                  expression: "model",
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-btn c-btn--cta c-btn--small",
                  class: { "c-btn--loading": _vm.generatingMetadata },
                  staticStyle: { "margin-bottom": "var(--spacing-24)" },
                  on: { click: _vm.generateMetadata },
                },
                [_vm._v("\n\t\t\t\tGenerate Metadata\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "ui form",
                  attrs: { action: "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.saveMetadata.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "field" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          gap: "var(--spacing-8)",
                        },
                      },
                      _vm._l(["english language"], function (f) {
                        return _c(
                          "button",
                          {
                            staticClass: "c-btn c-btn--small",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.fieldOfStudy = f
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" + _vm._s(f) + "\n\t\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tField of Study\n\t\t\t\t\t\t"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fieldOfStudy,
                            expression: "fieldOfStudy",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.fieldOfStudy },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.fieldOfStudy = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          gap: "var(--spacing-8)",
                        },
                      },
                      _vm._l(
                        ["a1", "a2", "b1", "b2", "c1", "c2"],
                        function (l) {
                          return _c(
                            "button",
                            {
                              staticClass: "c-btn c-btn--small",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.level = l
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(l) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tLevel\n\t\t\t\t\t\t"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.level,
                            expression: "level",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.level },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.level = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tLanguages\n\t\t\t\t\t\t"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.languages,
                            expression: "languages",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.languages },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.languages = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tLanguage Front\n\t\t\t\t\t\t"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.languageFront,
                            expression: "languageFront",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.languageFront },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.languageFront = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tLanguage Back\n\t\t\t\t\t\t"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.languageBack,
                            expression: "languageBack",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.languageBack },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.languageBack = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tTags\n\t\t\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "tags-section" },
                        _vm._l(_vm.tagsArray, function (tag) {
                          return _c("Tag", {
                            key: tag,
                            attrs: { "tag-name": tag },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tags,
                            expression: "tags",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.tags },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.tags = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tTags (GPT-4o)\n\t\t\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "tags-section" },
                        _vm._l(_vm.tagsGPT40Array, function (tag) {
                          return _c("Tag", {
                            key: tag,
                            attrs: { "tag-name": tag },
                            nativeOn: {
                              "!click": function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.toggleTag(tag)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("label", [
                      _vm._v("\n\t\t\t\t\t\tTags (GPT-3.5)\n\t\t\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "tags-section" },
                        _vm._l(_vm.tagsGPT3Array, function (tag) {
                          return _c("Tag", {
                            key: tag,
                            attrs: { "tag-name": tag },
                            nativeOn: {
                              "!click": function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.toggleTag(tag)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { clas: "field" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "c-btn c-btn--cta c-btn--small",
                        class: { "c-btn--loading": _vm.savingMetadata },
                        attrs: { type: "submit" },
                      },
                      [_vm._v("\n\t\t\t\t\t\tSave\n\t\t\t\t\t")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }