export const featureToggles = [
	{ key: 'knowledgeProgress', defaultValue: '1' },
	{ key: 'surveyInvitation', defaultValue: '0' },
	{ key: 'article', defaultValue: '0' },
	{ key: 'multipleSingleChoice', defaultValue: '1' },
	{ key: 'draggableStickers', defaultValue: '0' },
	{ key: 'disableCategoriesStep', defaultValue: '0' },
	{ key: 'recordingFeatures', defaultValue: '0' },
	{ key: 'generatorAI', defaultValue: '1' },
	{ key: 'searchAISuggestions', defaultValue: '0' },
	{ key: 'imageUpload', defaultValue: '0' },
	{ key: 'nextDeckSuggestion', defaultValue: '0' },
	// For testing purposes
	{ key: 'featureDisabledByDefault', defaultValue: '0' },
	{ key: 'featureEnabledByDefault', defaultValue: '1' },
];
