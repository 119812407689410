var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SuiModal",
    {
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _c("i", { staticClass: "close icon", on: { click: _vm.closeModal } }),
      _vm._v(" "),
      _c("SuiModalContent", { staticStyle: { padding: "0 !important" } }, [
        _c("div", { staticClass: "c-illustration-modal-body" }, [
          _c("div", {
            staticClass: "c-illustration-modal-body__hero",
            class: {
              "c-illustration-modal-body__hero--generator-ai":
                _vm.modalType === "signUpGeneratorAI" ||
                _vm.lastModalType === "signUpGeneratorAI",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-illustration-modal-body__content",
              staticStyle: {
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [
              _c("TransitionExpand", [
                _vm.signViaMagicResponse === null
                  ? _c("div", [
                      _vm.modalType.includes("signUp") ||
                      _vm.lastModalType.includes("signUp")
                        ? _c("div", { staticClass: "c-sign-popup" }, [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "c-sign-popup__header c-heading c-heading--third",
                              },
                              [
                                _vm.modalType === "signUp" ||
                                _vm.lastModalType === "signUp"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t("header.extendYourStay")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.modalType === "signUpAction" ||
                                _vm.lastModalType === "signUpAction"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t(
                                              "header.firstCreateAnAccount"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.modalType === "signUpCreateDeck" ||
                                _vm.lastModalType === "signUpCreateDeck"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t("header.createYourFirstDeck")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.modalType === "signUpGeneratorAI" ||
                                _vm.lastModalType === "signUpGeneratorAI"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t("header.createWithAIHeader")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.modalType === "signUp" ||
                            _vm.lastModalType === "signUp"
                              ? _c(
                                  "p",
                                  { staticClass: "c-sign-popup__subheader p" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t(
                                            "header.signUpForFreeMultideckerAccount"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.modalType === "signUpAction" ||
                            _vm.lastModalType === "signUpAction"
                              ? _c(
                                  "p",
                                  { staticClass: "c-sign-popup__subheader p" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t(
                                            "header.onceYouCreatedYourAccount"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.modalType === "signUpCreateDeck" ||
                            _vm.lastModalType === "signUpCreateDeck"
                              ? _c(
                                  "p",
                                  { staticClass: "c-sign-popup__subheader p" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t(
                                            "header.signUpForFreeMultideckerAccountShort"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.modalType === "signUpGeneratorAI" ||
                            _vm.lastModalType === "signUpGeneratorAI"
                              ? _c(
                                  "p",
                                  { staticClass: "c-sign-popup__subheader p" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t("header.createWithAISubheader")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-sign-popup__newsletter" },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newsletter,
                                        expression: "newsletter",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      value: "newsletter",
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.newsletter)
                                        ? _vm._i(_vm.newsletter, "newsletter") >
                                          -1
                                        : _vm.newsletter,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.newsletter,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = "newsletter",
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.newsletter = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.newsletter = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.newsletter = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("header.yesNewsletter")) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.signViaEmailActive
                              ? _c("div", [
                                  false
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "c-sign-popup__button ui facebook button",
                                          class: {
                                            loading:
                                              _vm.busyButton === "facebook",
                                          },
                                          attrs: {
                                            href:
                                              "/auth/login/facebook?newsletter=" +
                                              this.newsletter +
                                              "&redirect=" +
                                              _vm.redirect,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sign(
                                                "signUp",
                                                "Facebook"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "facebook icon",
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$t("header.signUpFacebook")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "c-sign-popup__button ui google plus button",
                                      class: {
                                        loading: _vm.busyButton === "google",
                                      },
                                      attrs: {
                                        href:
                                          "/auth/login/google?newsletter=" +
                                          this.newsletter +
                                          "&redirect=" +
                                          _vm.redirect,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sign("signUp", "Google")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "google icon" }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t("header.signUpGoogle")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "c-sign-popup__button ui black plus button",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.sign("signUp", "Email")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "envelope icon" }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.$t("header.signUpEmail")) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm.signViaEmailActive
                                  ? _c("SignViaEmail", {
                                      attrs: {
                                        redirect: _vm.redirect,
                                        newsletter: _vm.newsletter,
                                        "sign-type": "sign-up",
                                        "back-to-sign-options":
                                          _vm.backToSignOptions,
                                      },
                                      on: {
                                        "sign-magic-response":
                                          _vm.onSignMagicResponse,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.signViaEmailActive === false &&
                                (_vm.modalType.includes("signUp") ||
                                  _vm.lastModalType.includes("signUp"))
                                  ? _c(
                                      "div",
                                      { staticClass: "c-sign-popup__terms" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t(
                                                "header.bySigningUpYouAgreeToOur"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                        _c(
                                          "RouterLink",
                                          {
                                            attrs: {
                                              to: { name: "privacyPolicy" },
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.closeModal.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.$t("privacyPolicy")
                                                ) +
                                                ".\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p c-sign-popup__switch_sign-type",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t("header.alreadyHaveAnAccount")
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "c-sign-popup__link",
                                        attrs: { href: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.changeModalToSignIn.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.$t("header.signIn")) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.modalType === "signIn" ||
                      _vm.lastModalType === "signIn"
                        ? _c(
                            "div",
                            { staticClass: "c-sign-popup" },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "c-sign-popup__header c-heading c-heading--third",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("header.welcomeBack")) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "c-sign-popup__subheader p" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t("header.signInToGetAccess")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.signViaEmailActive
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "c-sign-popup__newsletter",
                                      },
                                      [
                                        _c("label", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.newsletter,
                                                expression: "newsletter",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              value: "newsletter",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.newsletter
                                              )
                                                ? _vm._i(
                                                    _vm.newsletter,
                                                    "newsletter"
                                                  ) > -1
                                                : _vm.newsletter,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.newsletter,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = "newsletter",
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.newsletter =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.newsletter = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.newsletter = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$t("header.yesNewsletter")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "c-sign-popup__button ui facebook button",
                                        class: {
                                          loading:
                                            _vm.busyButton === "facebook",
                                        },
                                        attrs: {
                                          href:
                                            "/auth/login/facebook?newsletter=" +
                                            this.newsletter +
                                            "&redirect=" +
                                            _vm.redirect,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sign(
                                              "signIn",
                                              "Facebook"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "facebook icon",
                                        }),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t("header.loginFacebook")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "c-sign-popup__button ui google plus button",
                                        class: {
                                          loading: _vm.busyButton === "google",
                                        },
                                        attrs: {
                                          href:
                                            "/auth/login/google?newsletter=" +
                                            this.newsletter +
                                            "&redirect=" +
                                            _vm.redirect,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sign("signIn", "Google")
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "google icon" }),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t("header.loginGoogle")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "c-sign-popup__button ui black plus button",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.sign("signIn", "Email")
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "envelope icon",
                                        }),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t("header.loginEmail")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.signViaEmailActive
                                ? _c("SignViaEmail", {
                                    attrs: {
                                      redirect: _vm.redirect,
                                      newsletter: _vm.newsletter,
                                      "sign-type": "sign-in",
                                      "back-to-sign-options":
                                        _vm.backToSignOptions,
                                    },
                                    on: {
                                      "sign-magic-response":
                                        _vm.onSignMagicResponse,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p c-sign-popup__switch_sign-type",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("header.noAccount")) +
                                      " "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "c-sign-popup__link",
                                      attrs: { href: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.changeModalToSignUp.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.$t("header.createOne")) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("TransitionExpand", [
                _vm.signViaMagicResponse !== null
                  ? _c("div", [
                      _c("div", { staticClass: "c-sign-popup" }, [
                        _vm.signViaMagicResponse.res.data.success
                          ? _c("div", [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "c-sign-popup__header c-heading c-heading--third",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("header.checkYourInbox"))
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.signViaMagicResponse.intention === "sign-in"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c-sign-popup__sign-feedback",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "header.clickTheLinkToSignIn",
                                              {
                                                email:
                                                  _vm.signViaMagicResponse
                                                    .destination,
                                              }
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.signViaMagicResponse.intention === "sign-up"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c-sign-popup__sign-feedback",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "header.clickTheLinkToSignUp",
                                              {
                                                email:
                                                  _vm.signViaMagicResponse
                                                    .destination,
                                              }
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm.signViaMagicResponse.res.data.error.code ===
                            1001
                          ? _c(
                              "div",
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "c-sign-popup__header c-heading c-heading--third",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.weDidntRecognizeThatEmail"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "header.wouldYouLikeToSignInWithDifferentEmail"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.signViaEmailActive
                                  ? _c("SignViaEmail", {
                                      attrs: {
                                        redirect: _vm.redirect,
                                        newsletter: _vm.newsletter,
                                        "sign-type": "sign-in",
                                        "back-to-sign-options":
                                          _vm.backToSignOptions,
                                      },
                                      on: {
                                        "sign-magic-response":
                                          _vm.onSignMagicResponse,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p c-sign-popup__switch_sign-type",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.$t("header.noAccount")) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "c-sign-popup__link",
                                        attrs: { href: "" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.changeModalToSignUp.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.$t("header.createOne")) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }