<template>
	<div>
		<button
			type="button"
			class="c-btn c-btn--secondary c-btn--small"
			:class="{ 'c-btn--inverted': inverted }"
			@click="signIn()"
		>
			{{ $t("header.signIn") }}
		</button>
		<span class="u-divider" />
		<button
			type="button"
			class="c-btn c-btn--cta c-btn--small"
			@click="signUp()"
		>
			{{ $t("header.createAnAccount") }}
		</button>
	</div>
</template>

<script>
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';

	export default {
		name: 'LoginButtons',
		props: {
			analyticsLabel: {
				type: String,
				required: true,
			},
			inverted: {
				type: Boolean,
				default: true,
			},
		},
		methods: {
			signUp() {
				this.$store.dispatch('changeModalType', 'signUp');
				logAnalyticsEvent(...events.signUpModalDisplay, this.analyticsLabel);
			},
			signIn() {
				this.$store.dispatch('changeModalType', 'signIn');
				logAnalyticsEvent(...events.signInModalDisplay, this.analyticsLabel);
			},
		},
	};
</script>
