<template>
	<div
		class="swing-card"
		:class="{ 'swing-card--invalid': invalid, 'swing-card--compact-fonts': compactFonts }"
		@click="toggleVisibility"
	>
		<div class="swing-card__aside">
			<slot name="aside" />
		</div>
		<SwingCardShell
			:card-id="word._id"
			:local-visible-side="localVisibleSide"
			:progress="progress"
			:previous-progress="previousProgress"
			:display-progress="displayProgress"
			:delay-animation="delayAnimation"
			:display-stickers="displayStickers"
			@sticker-drop="$emit('sticker-drop')"
		>
			<Notch
				v-if="backAvailable && localVisibleSide === SIDES.FRONT"
				class="swing-card__notch-front"
			>
				<span>
					<i class="grey reply icon" />
				</span>
			</Notch>
			<Notch
				v-if="backAvailable && localVisibleSide === SIDES.BACK"
				class="swing-card__notch-back"
			>
				<span>
					<i
						class="share icon"
						style="color: white;"
					/>
				</span>
			</Notch>
			<slot name="notch" />
			<template slot="default">
				<slot />
			</template>
			<template slot="centered">
				<div
					v-if="localVisibleSide === SIDES.FRONT"
					class="swing-card__front"
					:class="{ 'swing-card__front--long-text': isFrontLongText }"
				>
					<p>
						<TextReplacer
							:content="front"
							:replace="replaceContent"
							:injected="contentToBeReplaced"
						/>
					</p>
				</div>
				<div
					v-if="localVisibleSide === SIDES.BACK"
					class="swing-card__back"
					:class="{ 'swing-card__back--long-text': isBackLongText }"
				>
					<p>{{ back }}</p>
				</div>
			</template>
		</SwingCardShell>
	</div>
</template>

<script>
	import ClickTracker from '@/components/deckPlay/ClickTracker';
	import TextReplacer from '@/components/TextReplacer';
	import SwingCardShell from '@/components/swing/SwingCardShell';
	import { SIDES } from '@/components/swing/swing';
	import Notch from '@/components/notch/Notch';

	export default {
		name: 'SwingCard',
		components: {
			ClickTracker,
			Notch,
			TextReplacer,
			SwingCardShell,
		},
		props: {
			displayProgress: {
				type: Boolean,
				default: false,
			},
			progress: {
				type: Number,
				default: 0,
			},
			previousProgress: {
				type: Number,
				default: 0,
			},
			visibleSide: {
				type: String,
				default: SIDES.FRONT,
			},
			word: {
				type: Object,
				default: () => ({ word: '', translation: '' }),
			},
			replaceContent: {
				type: Boolean,
				default: false,
			},
			contentToBeReplaced: {
				type: String,
				default: '',
			},
			invalid: {
				type: Boolean,
				default: false,
			},
			compactFonts: {
				type: Boolean,
				default: false,
			},
			delayAnimation: {
				type: Boolean,
				default: false,
			},
			displayStickers: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				localVisibleSide: SIDES.FRONT,
				SIDES,
			};
		},
		computed: {
			front() {
				return this.word.word || this.word.question || '';
			},
			back() {
				return this.word.translation || '';
			},
			isFrontLongText() {
				return this.front.length >= 60;
			},
			isBackLongText() {
				return this.back.length >= 60;
			},
			backAvailable() {
				return Boolean(this.back.length) && this.back !== this.front;
			},
		},
		watch: {
			visibleSide(newValue) {
				if (newValue) {
					this.localVisibleSide = newValue;
				}
			},
		},
		methods: {
			toggleVisibility() {
				if (!this.backAvailable) {
					return;
				}

				if (this.localVisibleSide === SIDES.FRONT) {
					this.localVisibleSide = SIDES.BACK;
				} else {
					this.localVisibleSide = SIDES.FRONT;
				}
			},
		},
	};
</script>
