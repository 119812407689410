<template>
	<span class="c-user-profile">
		<RouterLink
			class="link"
			:to="{ name: 'userProfile',
				params: { id: deckIdToSeo(list.author.displayName, list.author._id) }}"
		>
			<span class="c-user-profile__img-container">
				<i class="user icon" />
			<!--			<img-->
			<!--				v-lazy="`https://www.tinygraphs.com/labs/isogrids/hexa16/${list.author._id}?theme=duskfalling&numcolors=3&size=32&fmt=svg`"-->
			<!--				class="c-user-profile__img"-->
			<!--				alt="user image"-->
			<!--			>-->
			</span>
			<span>{{ list.author.displayName }}</span>
		</RouterLink>
	</span>
</template>

<script>
import { deckIdToSeo } from '@/assets/js/dataServices/utils';

	export default {
		name: 'UserProfile',
		props: ['list'],
		data() {
			return {
				deckIdToSeo,
			};
		},
	};
</script>
<style scoped>
.link {
	color: var(--grey-50);
}
.link:hover {
	text-decoration: underline;
}
</style>
