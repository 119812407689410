<template>
	<div
		v-if="!isNaN(localProgress)"
		class="c-progress"
		:style="{
			'--c-progress-progress': `${localProgress}%`
		}"
	>
		<div
			class="c-progress__progress-indicator"
			:class="{ 'c-progress__progress-indicator--animated': !delayAnimation}"
		>
			<span class="c-progress__progress-text">
				{{ progress }}%{{ progressDiffReadable }}
			</span>
		</div>
	</div>
</template>
<script>
	export const progressDiffReadable = (progressDiff) => {
		if (progressDiff === 0) {
			return '';
		}
		const sign = progressDiff > 0 ? '+' : '';
		return `(${sign}${progressDiff}%)`;
	};

	export default {
		props: {
			progress: {
				type: Number,
				default: 0,
			},
			previousProgress: {
				type: Number,
				default: 0,
			},
			delayAnimation: {
				type: Boolean,
				default: true,
			},
		},
		data: () => ({
			localProgress: NaN,
			timeoutId: NaN,
		}),
		computed: {
			progressDiff() {
				return this.progress - this.previousProgress;
			},
			progressDiffReadable() {
				return progressDiffReadable(this.progressDiff);
			},
		},
		watch: {
			previousProgress() {
				this.handleChange();
			},
			currentProgress() {
				this.handleChange();
			},
			delayAnimation(newVal) {
				if (newVal === false) {
					this.timeoutId && clearTimeout(this.timeoutId);
					this.timeoutId = setTimeout(() => {
						this.localProgress = this.progress;
					}, 100);
				}
			},
		},
		created() {
			this.handleChange();
		},
		methods: {
			handleChange() {
				if ([this.previousProgress, this.progress].some(isNaN)) {
					return;
				}

				this.localProgress = this.previousProgress;
			},
		},
	};
</script>
