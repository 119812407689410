import { medias } from '@/store/matchMedia/medias';

export const initListeners = ($store) => {
	Object.values(medias).forEach((media) => {
		const mql = window.matchMedia(media);

		$store.dispatch('matchMedia/setMatchMedia', {
			media: mql.media,
			matches: mql.matches,
		});

		mql.addEventListener('change', ({ media, matches }) => {
			$store.dispatch('matchMedia/setMatchMedia', { media, matches });
		});
	});
};
