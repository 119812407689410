<template>
	<i
		tabindex="0"
		class="close icon"
		@click="hideModal"
		@keydown.enter="hideModal"
	/>
</template>

<script>
export default {
	model: {
		prop: 'modalShown',
		event: 'input',
	},
	props: {
		modalShown: {
			type: Boolean,
		},
	},
	mounted() {
		document.addEventListener('keydown', this.handleEscKey);
	},

	beforeDestroy() {
		document.removeEventListener('keydown', this.handleEscKey);
	},
	methods: {
		hideModal() {
			this.$emit('input', false);
		},

		handleEscKey(event) {
			if (event.key === 'Escape') {
				this.hideModal();
			}
		},
	},
};
</script>
