var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-deck-info-card c-deck-info-card--with-photo",
      class: {
        "c-deck-info-card--selected": _vm.selectedDeckId === this.list._id,
      },
      on: { click: _vm.cardClick },
    },
    [
      _c(
        _vm.isLink ? "RouterLink" : "div",
        {
          tag: "component",
          staticClass: "c-deck-info-card__main-link",
          class: { "c-deck-info-card--loading": _vm.loading },
          attrs: {
            target: _vm.inIframe ? "_blank" : undefined,
            "data-qa": "list-card",
            event: _vm.loading ? "" : "click",
            to: {
              name:
                _vm.list.type === "article" ? "courseDetails" : "listDetails",
              params: { id: _vm.seoDeckId },
            },
          },
        },
        [
          _c(
            "article",
            { staticClass: "c-deck-info-card__container" },
            [
              _c(
                "Notch",
                {
                  staticClass: "c-deck-info-card__notch",
                  attrs: { simple: "" },
                },
                [
                  _c("AddToFavourites", {
                    attrs: { color: _vm.loading && "#dbd0bd", list: _vm.list },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "header",
                {
                  staticClass: "c-deck-info-card__header",
                  staticStyle: { position: "relative" },
                },
                [
                  _vm.image
                    ? _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.image,
                            expression: "image",
                          },
                        ],
                        staticClass: "c-deck-info-card__image",
                        attrs: { alt: "" },
                      })
                    : _c(
                        "div",
                        { staticClass: "c-deck-info-card__image-placeholder" },
                        [
                          _vm.showSetThemeImageButton
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "c-btn c-btn--white c-btn--small c-btn--rounded",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.$refs.imageUploader.open()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "c-btn__icon image icon",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "c-btn__icon-text" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.$t("deckCreate.setThemeImage")
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                ]
              ),
              _vm._v(" "),
              _c("section", { staticClass: "c-deck-info-card__section" }, [
                _vm.showCtaButtons
                  ? _c(
                      "div",
                      { staticClass: "c-deck-info-card__btn-cta-wrapper" },
                      [
                        _c(
                          "RouterLink",
                          {
                            staticClass:
                              "c-btn c-btn--white c-btn--small c-btn--rounded c-deck-info-card__btn-cta",
                            attrs: {
                              target: _vm.inIframe ? "_blank" : undefined,
                              title: _vm.$t("play"),
                              to: {
                                name: "listPlay",
                                params: { id: _vm.seoDeckId },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "c-btn__icon play icon" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "c-btn__icon-text" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("play")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v("\n\t\t\t\t\t  \n\t\t\t\t\t"),
                        _c(
                          "RouterLink",
                          {
                            staticClass:
                              "c-btn c-btn--white c-btn--small c-btn--rounded c-deck-info-card__btn-cta",
                            attrs: {
                              target: _vm.inIframe ? "_blank" : undefined,
                              title: _vm.$t("preview"),
                              to: {
                                name:
                                  _vm.list.type === "article"
                                    ? "courseDetails"
                                    : "listDetails",
                                params: { id: _vm.seoDeckId },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "c-btn__icon eye icon" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "c-btn__icon-text" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("preview")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isAdmin
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "c-btn c-btn--white c-btn--small c-btn--rounded c-btn--just-icon c-deck-info-card__btn-cta",
                                attrs: {
                                  type: "button",
                                  title: "Edit metadata",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.modalShown = true
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "c-btn__icon icon" }, [
                                  _vm._v("🤖"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "c-deck-info-card__title" }, [
                  _c("strong", [
                    _vm.list.private
                      ? _c("span", [
                          _c("i", {
                            staticClass: "lock icon yellow fitted",
                            attrs: { title: _vm.$t("thisDeckIsPrivate") },
                          }),
                          _vm._v("\n\t\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.list.type === "article"
                      ? _c("span", { staticClass: "c-deck-info-card__type" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.$t("article")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n\t\t\t\t\t\t" + _vm._s(_vm.list.name) + "\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isAdmin
                  ? _c(
                      "div",
                      {
                        staticClass: "c-deck-info-card__section-counter",
                        staticStyle: {
                          "margin-bottom": "var(--spacing-8)",
                          display: "flex",
                          "flex-direction": "column",
                          gap: "var(--spacing-8)",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v("Field of Study: "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.list.gen_fieldOfStudy)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Level: "),
                          _c("strong", [_vm._v(_vm._s(_vm.list.gen_level))]),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Languages: "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.list.gen_languages)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Language Front: "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.list.gen_languageFront)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Language Back: "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.list.gen_languageBack)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-flex",
                              "flex-wrap": "wrap",
                              gap: "var(--spacing-4)",
                            },
                          },
                          _vm._l(_vm.list.gen_tags, function (tag) {
                            return _c("Tag", {
                              key: tag,
                              attrs: {
                                "tag-name": tag,
                                highlighted: _vm.sourceDeckTags.includes(tag),
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "c-deck-info-card__section-counter" },
                  [
                    _c(
                      "span",
                      { staticClass: "c-deck-info-card__cards-amount" },
                      [
                        _c("strong", [
                          _c("i", { staticClass: "ui clone icon outline" }),
                        ]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.list.words.length) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                    _vm._v("\n\t\t\t\t\t       \n\t\t\t\t\t"),
                    _c(
                      "span",
                      { staticClass: "c-deck-info-card__stars-amount" },
                      [
                        _c("strong", [
                          _c("i", { staticClass: "ui heart icon outline" }),
                        ]),
                        _vm._v(" "),
                        _c("strong", [_vm._v(_vm._s(_vm.stars))]),
                      ]
                    ),
                    _vm._v("\n\t\t\t\t\t       \n\t\t\t\t\t"),
                    _c(
                      "span",
                      { staticClass: "c-deck-info-card__section-details" },
                      [
                        _c("UserProfile", {
                          style: _vm.loading ? { opacity: 0 } : {},
                          attrs: { list: _vm.list },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ImageUploader", {
        ref: "imageUploader",
        attrs: { "deck-id": _vm.list._id },
      }),
      _vm._v(" "),
      _vm.isAdmin
        ? _c("ManageDeckMetadataModal", {
            attrs: { deck: _vm.list },
            model: {
              value: _vm.modalShown,
              callback: function ($$v) {
                _vm.modalShown = $$v
              },
              expression: "modalShown",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }