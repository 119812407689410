var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "sign-via-email",
      attrs: {
        novalidate: "",
        name: "signEmailForm",
        "data-vv-scope": "signEmailForm",
      },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.signViaMagicEmail.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "ui header sign-via-email__header",
          attrs: { for: "email-input" },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.$t("header.email")) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ui input",
          class: {
            error:
              _vm.errors.has("signEmailForm.email") &&
              _vm.fields.$signEmailForm.email.touched,
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.emailInputValue,
                expression: "emailInputValue",
              },
              { name: "focus", rawName: "v-focus" },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email",
                expression: "'required|email'",
              },
            ],
            attrs: {
              id: "email-input",
              required: "",
              type: "email",
              placeholder: "sophie@example.com",
              name: "email",
            },
            domProps: { value: _vm.emailInputValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.emailInputValue = $event.target.value
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("TransitionExpand", [
        _vm.errors.has("signEmailForm.email") &&
        _vm.fields.$signEmailForm.email.touched
          ? _c("div", { staticClass: "sign-via-email__submit-error" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.errors.first("signEmailForm.email")) +
                  "\n\t\t"
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "c-btn c-btn--cta sign-via-email__submit-button",
          class: { "c-btn--loading": _vm.signViaEmailBusy },
          attrs: { type: "submit" },
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t(_vm.isSignIn ? "header.signIn" : "header.signUp"))
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "sign-via-email__options-button",
          attrs: { type: "button" },
          on: { click: _vm.backToSignOptions },
        },
        [
          _c("i", {
            staticClass:
              "ui chevron left icon sign-via-email__options-button-icon",
          }),
          _vm._v(" "),
          _c("span", { staticClass: "sign-via-email__options-button-text" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.$t(
                    _vm.isSignIn
                      ? "header.allSignInOptions"
                      : "header.allSignUpOptions"
                  )
                ) +
                "\n\t\t"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }