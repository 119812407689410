<template>
	<div>
		<Modal
			v-model="imagesGalleryModalVisible"
			modal-variation="large"
		>
			<div slot="header">
				{{ $t('deckCreate.selectThemeForYourDeck') }}
			</div>
			<div slot="content">
				<label>
					{{ $t('deckCreate.searchForImages') }}
					<div class="ui mini input">
						<input
							v-model="imagesSearchValue"
							type="text"
						>
					</div>
				</label>

				<span v-if="imageUploadFeature">
					<span style="margin: 0 var(--spacing-8);">or</span>
					<label class="c-btn c-btn--small">
						Upload file
						<input
							type="file"
							accept="image/png, image/jpeg"
							@input="onFileInput"
						>
					</label>
				</span>
				<div />
				<div
					v-if="errorWhileFetchingDataFromImagesEndPoint"
					class="error-state"
				>
					{{ $t('deckCreate.somethingWentWrong') }}
				</div>
				<div v-else>
					<div class="c-gallery-wrapper">
						<div class="c-gallery">
							<div
								v-for="image in images"
								ref="wrappers"
								:key="image._id"
								:class="{
									'c-gallery__image-wrapper': true,
									'c-gallery__image-wrapper--selected': image.id === (currentImage && currentImage.id),
								}"
								@click="selectAnImage(image)"
							>
								<img
									alt
									:class="{
										'c-gallery__image': true,
										'c-gallery__image--pending': !image.ready || !image.src,
									}"
									:src="image.src"
								>
							</div>
						</div>
					</div>
					<div class="c-gallery__button-wrapper">
						<button
							type="button"
							class="ui right labeled icon button"
							@click="loadMore"
						>
							{{ $t('deckCreate.seeMore') }}
							<i class="chevron down icon" />
						</button>
					</div>
				</div>
			</div>
			<template slot="actions">
				<button
					type="button"
					class="ui circular gray button"
					@click="skip"
				>
					{{ $t("buttons.skip") }}
				</button>
				<button
					type="button"
					class="ui circular green button"
					:class="{ loading: uploading }"
					@click="setImage"
				>
					{{ $t('deckCreate.setImage') }}
				</button>
			</template>
		</Modal>
	</div>
</template>

<script>
	import axios from 'axios';
	import shuffle from 'lodash/shuffle';
	import Modal from 'vue-semantic-modal2/src/VueSemanticModal';
	import { mapGetters } from 'vuex';

	let id = 0;

	const getImageBlob = async (src) => {
		const response = await fetch(src);
		return response.blob();
	};

	export default {
		name: 'ImageUploader',
		components: {
			Modal,
		},
		props: ['deckId'],
		data() {
			return {
				imagesGalleryModalVisible: false,
				initialSearchDone: false,
				imagesSearchValue: '',
				currentImage: null,
				imagesByTheKey: {},
				uploading: false,
				errorWhileFetchingDataFromImagesEndPoint: false,
			};
		},
		computed: {
			...mapGetters(['feature']),
			imageUploadFeature() {
				return this.feature('imageUpload');
			},
			images() {
				return this.imagesByTheKey[this.imagesSearchValue];
			},
		},
		watch: {
			async imagesSearchValue() {
				await this.search();
			},
		},
		methods: {
			onFileInput(e) {
				const [firstFile] = e.target.files;

				this.setImageImplementation('{}', firstFile, firstFile);
			},
			async open() {
				if (this.initialSearchDone === false) {
					await this.search();
					const [firstImage] = this.images || [];
					firstImage && this.selectAnImage(firstImage);
				}
				this.imagesGalleryModalVisible = true;
			},
			selectAnImage(image) {
				this.currentImage = image;
			},
			loadMore() {
				this.search();
			},
			async search() {
				this.initialSearchDone = true;
				const imageSearchValue = this.imagesSearchValue;
				const PER_PAGE = 12;

				let images = this.imagesByTheKey[imageSearchValue] || [];

				const amountOfImages = images.length;
				const page = (images.length / PER_PAGE) + 1;

				this.$set(this.imagesByTheKey, imageSearchValue, images);

				const newImages = new Array(PER_PAGE).fill(null).map(() => ({ _id: ++id }));

				images = images.concat(newImages);
				this.$set(this.imagesByTheKey, imageSearchValue, images);

				this.$nextTick(() => {
					const nextOne = this.$refs.wrappers[amountOfImages];
					nextOne.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				});

				let response;
				try {
					response = await axios.get(`https://pixabay.com/api/?key=17059674-a3e38f008e1ee65089b4db522&q=${this.imagesSearchValue}&image_type=photo&pretty=true&per_page=${PER_PAGE}&page=${page}&safesearch=true`);
				} catch (e) {
					this.errorWhileFetchingDataFromImagesEndPoint = true;
					return;
				}
				const { data } = response;

				const hits = shuffle(data.hits || []);

				newImages.forEach((record, index) => {
					const hit = hits[index];
					if (!hit) {
						return;
					}

					this.$set(record, 'id', hit.id);
					this.$set(record, 'src', hit.webformatURL.replace(640, 340));
					this.$set(record, 'thumbnail', hit.thumbnail);
					this.$set(record, 'ready', false);
					this.$set(record, 'hit', hit);

					const img = new Image();
					img.onload = () => {
						this.$set(record, 'ready', true);
					};
					img.src = hit.largeImageURL;
				});
				this.$set(this.imagesByTheKey, imageSearchValue, images);
			},
			skip() {
				this.imagesGalleryModalVisible = false;
				this.$emit('image-upload-skipped');
			},
			async setImage() {
				await this.setImageImplementation();
			},
			async setImageImplementation(metadata, image, fullImage) {
				this.uploading = true;
				metadata = metadata || JSON.stringify(this.currentImage.hit);
				image = image || await getImageBlob(this.currentImage.src);
				fullImage = fullImage || await getImageBlob(this.currentImage.hit.largeImageURL);

				const formData = new FormData();
				formData.append('image', image);
				formData.append('fullImage', fullImage);
				formData.append('metadata', metadata);

				const config = {
					headers: {
						'content-type': 'multipart/form-data',
					},
				};

				const { data } = await axios.post(`/image-upload/${this.deckId}`, formData, config);

				await this.$store.dispatch('setDeckImage', { deckId: this.deckId, image: data });

				this.uploading = false;
				this.imagesGalleryModalVisible = false;
				this.$emit('image-uploaded');
			},
		},
    };
</script>

<style scoped>
	input[type="file"] {
		display: none;
	}

	.c-gallery-wrapper {
		margin-top: 16px;
		max-height: 400px;
		overflow-y: auto;
	}

	.c-gallery {
		--spacing: 16px;

		display: grid;
		grid-gap: var(--spacing);
		grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		min-height: 200px;
		padding: 4px;
	}

	.c-gallery__image-wrapper {
		background: #eee;
		border-radius: 16px;
		overflow: hidden;
	}

	.c-gallery__image-wrapper--selected {
		box-shadow: 0 0 0 4px lightblue;
	}

	.c-gallery__image {
		background: var(--pure-white);
		display: block;
		height: 156px;
		width: 100%;
		object-fit: cover;
		cursor: pointer;
		transition: opacity 200ms, transform 1000ms cubic-bezier(0.190,1.000,0.220,1.000);
	}

	.c-gallery__image:hover {
		transform: scale(1.1);
	}

	.c-gallery__image--pending {
		opacity: 0;
	}

	.c-gallery__button-wrapper {
		text-align: center;
		margin-top: 8px;
		margin-bottom: 16px;
	}

	.error-state {
		padding: var(--spacing-32) 0;
	}
</style>
