var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-results-cards" },
    [
      _vm.showCardsConfiguration
        ? _c(
            "div",
            {
              staticClass: "deck-cards-configuration",
              class: {
                "deck-cards-configuration--knowledge-progress":
                  _vm.knowledgeProgress,
              },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "c-btn c-btn--secondary c-btn--small deck-cards-configuration__button",
                  class: { "c-btn--inverted": _vm.darkBackground },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.cardsSettingsPopOverOpened =
                        !_vm.cardsSettingsPopOverOpened
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "c-btn__icon sliders horizontal icon",
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("displaySettings")) + "\n\t\t"),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "deck-cards-configuration__container" },
                [
                  _c("DeckCardsConfiguration", {
                    attrs: {
                      "sort-by": _vm.sortBy,
                      "update-sort-by": _vm.updateSortBy,
                      "progress-visible": _vm.progressVisible,
                      "toggle-progress-visible": _vm.toggleProgressVisible,
                      "visible-cards-side": _vm.visibleCardsSide,
                      "update-visible-cards-side": _vm.updateVisibleCardsSide,
                      "result-display-type": _vm.resultDisplayType,
                      "change-result-display-type": _vm.changeResultDisplayType,
                      "knowledge-progress": _vm.knowledgeProgress,
                      "toggle-show-answers": _vm.toggleShowAnswers,
                      "show-answers": _vm.showAnswers,
                      "disable-controls": _vm.disableControlsLocal,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "c-results-cards__scrollable-content": _vm.scrollable,
          },
        },
        [
          _vm.resultDisplayType === _vm.resultDisplayTypes.CARDS
            ? _c(
                "transition-group",
                {
                  staticClass: "result-cards-grid",
                  class: {
                    "result-cards-grid--answers": _vm.isChoiceCategoryType,
                  },
                  attrs: { name: "flip-list", tag: "div" },
                },
                _vm._l(_vm.questionCardsSorted, function (card, index) {
                  return _c(
                    "div",
                    {
                      key: card._id,
                      ref: "cards",
                      refInFor: true,
                      staticClass: "c-results-card",
                      style: _vm.seed && _vm.stylee(card._id),
                      attrs: {
                        "data-qa":
                          _vm.isAnswerCorrect(card) === null
                            ? "result-card"
                            : "result-card-" +
                              (_vm.isAnswerCorrect(card) ? "correct" : "wrong"),
                      },
                    },
                    [
                      _vm.isChoiceCategoryType
                        ? _c(
                            "div",
                            { staticClass: "c-results-card__inner" },
                            [
                              _vm._t("aside", null, { card: card }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "question-card",
                                  staticStyle: {
                                    position: "relative",
                                    "pointer-events": "none",
                                  },
                                },
                                [
                                  _c("p", { staticClass: "ui header tiny" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(card.question) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.categoryType ===
                                  _vm.categories.SINGLE_CHOICE
                                    ? _c("div", [
                                        _vm.answers[card._id]
                                          ? _c(
                                              "div",
                                              [
                                                _c("AnswersRadio", {
                                                  attrs: {
                                                    compact: "",
                                                    options:
                                                      _vm.answersToOptions(
                                                        card
                                                      ),
                                                    answer:
                                                      _vm.answers[card._id],
                                                  },
                                                  model: {
                                                    value: card.validAnswer,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        card,
                                                        "validAnswer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "card.validAnswer",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm.showAnswers
                                          ? _c(
                                              "div",
                                              [
                                                _c("AnswersRadio", {
                                                  attrs: {
                                                    compact: "",
                                                    options:
                                                      _vm.answersToOptions(
                                                        card
                                                      ),
                                                    answer:
                                                      _vm.answers[card._id],
                                                  },
                                                  model: {
                                                    value: card.validAnswer,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        card,
                                                        "validAnswer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "card.validAnswer",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c("AnswersRadio", {
                                                  attrs: {
                                                    compact: "",
                                                    options:
                                                      _vm.answersToOptions(
                                                        card
                                                      ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.categoryType ===
                                  _vm.categories.MULTIPLE_CHOICE
                                    ? _c("div", [
                                        _vm.answers[card._id] &&
                                        _vm.answers[card._id].length
                                          ? _c(
                                              "div",
                                              [
                                                _c("AnswersCheckboxes", {
                                                  attrs: {
                                                    "valid-answers":
                                                      _vm.answers[card._id] &&
                                                      card.validAnswers,
                                                    compact: "",
                                                    options:
                                                      _vm.answersToOptions(
                                                        card
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.answers[card._id],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.answers,
                                                        card._id,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "answers[card._id]",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm.showAnswers
                                          ? _c(
                                              "div",
                                              [
                                                _c("AnswersCheckboxes", {
                                                  attrs: {
                                                    compact: "",
                                                    options:
                                                      _vm.answersToOptions(
                                                        card
                                                      ),
                                                  },
                                                  model: {
                                                    value: card.validAnswers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        card,
                                                        "validAnswers",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "card.validAnswers",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c("AnswersCheckboxes", {
                                                  attrs: {
                                                    compact: "",
                                                    options:
                                                      _vm.answersToOptions(
                                                        card
                                                      ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "transition",
                                    { attrs: { name: "show" } },
                                    [
                                      _vm.knowledgeProgress &&
                                      _vm.progressVisible
                                        ? _c("Progress", {
                                            staticStyle: {
                                              "--c-progress-radius": "8px",
                                            },
                                            attrs: {
                                              progress: _vm.progress(card),
                                              "previous-progress":
                                                _vm.previousProgress(
                                                  card,
                                                  index
                                                ),
                                              "delay-animation":
                                                _vm.delayAnimation(card, index),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _c(
                            "SwingCard",
                            {
                              style: {
                                "--swing-card-border-color":
                                  _vm.isAnswerCorrect(card) === null
                                    ? undefined
                                    : _vm.isAnswerCorrect(card)
                                    ? "hsl(134, 81%, 88%)"
                                    : "hsl(354, 81%, 88%)",
                              },
                              attrs: {
                                "compact-fonts": "",
                                word: card,
                                "visible-side": _vm.visibleCardsSide,
                                progress: _vm.progress(card),
                                "previous-progress": _vm.previousProgress(
                                  card,
                                  index
                                ),
                                "display-progress":
                                  _vm.knowledgeProgress && _vm.progressVisible,
                                "delay-animation": _vm.delayAnimation(
                                  card,
                                  index
                                ),
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "aside" },
                                [_vm._t("aside", null, { card: card })],
                                2
                              ),
                              _vm._v(" "),
                              _c("transition", { attrs: { name: "show" } }, [
                                _vm.showAnswers &&
                                _vm.isAnswerCorrect(card) === null
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.deckCategories,
                                        function (category, categoryIndex) {
                                          return _vm.categoryType ===
                                            _vm.categories.CUSTOM
                                            ? _c(
                                                "p",
                                                {
                                                  key: category._id,
                                                  staticClass:
                                                    "c-swipe-direction-indicator",
                                                  class:
                                                    "c-swipe-direction-indicator--category" +
                                                    categoryIndex,
                                                  style: {
                                                    "text-decoration":
                                                      "underline",
                                                    opacity:
                                                      card.category ===
                                                      category._id
                                                        ? 1
                                                        : 0,
                                                  },
                                                  attrs: {
                                                    "data-qa":
                                                      "c-swipe-direction-indicator--category" +
                                                      categoryIndex,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        category.displayName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.categoryType === _vm.categories.CUSTOM &&
                              _vm.isAnswerCorrect(card) !== null
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-swipe-direction-indicator c-swipe-direction-indicator--correct",
                                      class:
                                        "c-swipe-direction-indicator--" +
                                        (_vm.isAnswerCorrect(card)
                                          ? "top"
                                          : "bottom"),
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.getCorrectCardCategory(card)
                                              .displayName
                                          ) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.categoryType === _vm.categories.CUSTOM &&
                              _vm.isAnswerCorrect(card) === false
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-swipe-direction-indicator c-swipe-direction-indicator--wrong",
                                      class:
                                        "c-swipe-direction-indicator--" +
                                        (_vm.isAnswerCorrect(card)
                                          ? "bottom"
                                          : "top"),
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.getAnsweredCardCategory(card)
                                              .displayName
                                          ) +
                                          "\n\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.resultDisplayType === _vm.resultDisplayTypes.TABLE &&
          _vm.questionCardsSorted.length
            ? _c("div", { staticClass: "result-cards-table-container" }, [
                _c(
                  "table",
                  {
                    staticClass:
                      "ui single line small table unstackable result-cards-table-container__table",
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("deckPlay.frontSide"))),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.$t("deckPlay.backSide")))]),
                        _vm._v(" "),
                        _vm.shouldDisplayAnswersColumns
                          ? _c("th", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("deckPlay.yourAnswer")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.shouldDisplayAnswersColumns
                          ? _c("th", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("deckPlay.correctAnswer")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.knowledgeProgress && _vm.progressVisible
                          ? _c("th", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("deckPlay.cardProgress")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "transition-group",
                      { attrs: { name: "flip-list", tag: "tbody" } },
                      _vm._l(_vm.questionCardsSorted, function (card) {
                        return _c(
                          "tr",
                          {
                            key: card._id,
                            class: {
                              positive: _vm.isAnswerCorrect(card) === true,
                              negative: _vm.isAnswerCorrect(card) === false,
                            },
                          },
                          [
                            _c("td", [_vm._v(_vm._s(card.word))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(card.translation))]),
                            _vm._v(" "),
                            _vm.shouldDisplayAnswersColumns
                              ? _c(
                                  "td",
                                  {
                                    style: {
                                      "text-decoration":
                                        _vm.isAnswerCorrect(card) === false
                                          ? "line-through"
                                          : "none",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.getAnsweredCardCategory(card)
                                            .displayName
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.shouldDisplayAnswersColumns
                              ? _c("td", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.isAnswerCorrect(card) !== null
                                          ? _vm.getCorrectCardCategory(card)
                                              .displayName
                                          : ""
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.knowledgeProgress && _vm.progressVisible
                              ? _c("td", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.progress(card)) +
                                      "% "
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(_vm.progressDiffReadable(card))
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.questionCardsSorted.length === 0
            ? _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c("EmptyStateDecks", {
                    attrs: {
                      context: _vm.isChoiceCategoryType ? "questions" : "cards",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "PopOver",
        { attrs: { show: _vm.cardsSettingsPopOverOpened, position: "bottom" } },
        [
          _c(
            "div",
            { staticClass: "c-popover-body--one" },
            [
              _c("span", { staticClass: "c-popover-body__close-btn-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "c-popover-body__close-btn",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.cardsSettingsPopOverOpened = false
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass:
                        "ui close icon c-popover-body__close-btn-icon",
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("DeckCardsConfiguration", {
                attrs: {
                  inline: "",
                  "sort-by": _vm.sortBy,
                  "update-sort-by": _vm.updateSortBy,
                  "progress-visible": _vm.progressVisible,
                  "toggle-progress-visible": _vm.toggleProgressVisible,
                  "visible-cards-side": _vm.visibleCardsSide,
                  "update-visible-cards-side": _vm.updateVisibleCardsSide,
                  "result-display-type": _vm.resultDisplayType,
                  "change-result-display-type": _vm.changeResultDisplayType,
                  "knowledge-progress": true,
                  "toggle-show-answers": _vm.toggleShowAnswers,
                  "show-answers": _vm.showAnswers,
                  "disable-controls": _vm.disableControlsLocal,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }