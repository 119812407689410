const messages = {
	en: {
		title: 'Multidecker: Smart Flashcards that will teach you anything in a blink of an eye',
		mostPopularFlashcardsTitle: 'Most popular flashcards | multidecker',
		privacyPolicyTitle: 'Privacy policy | multidecker',
		dataDeletionTitle: 'Personal Data Deletion Instructions | multidecker',
		flashcardsAIGenerator: 'Flashcards AI Generator | multidecker',
		flashcardsAIGeneratorDescription: 'Create personalized educational content effortlessly with our AI-powered Flashcards Generator. Generate interactive flashcards and quizzes for free. Transform your teaching experience today!',
		description: 'Multidecker Smart Flashcards speeds up the learning process. The only flashcard app that organizes your knowledge. Try them out and see how smart they are.',
		deck: 'Deck',
		article: 'Article',
		section: 'Section',
		author: 'Author',
		play: 'Play',
		preview: 'Preview',
		edit: 'Edit',
		finishEditing: 'Finish editing',
		addSection: 'Add section',
		editSectionName: 'Edit section name',
		remove: 'Remove',
		share: 'Share',
		embed: 'Embed',
		cards: 'Cards',
		or: 'or',
		displaySettings: 'Display settings',
		sortCardsBy: 'Sort cards by',
		noSorting: 'No sorting',
		betterKnownFirst: 'Knowledge: Better known first',
		lessKnownFirst: 'Knowledge: Less known first',
		displayAs: 'Display as',
		table: 'Table',
		flipAllCardsTo: 'Flip all cards to',
		answersVisibility: 'Answers visibility',
		progressVisibility: 'Progress visibility',
		expandedInformation: 'Expanded Information',
		show: 'show',
		hide: 'hide',
		close: 'Close',
		thereAreNoCardsInThisDeck: 'There are no cards in this deck',
		trackYourResults: 'Track your results and progress.',
		youTooCanCreateDeck: 'Looking to create your own custom deck?',
		createDeckNow: 'Create deck now',
		trackYourResults2: 'Don\'t lose your current deck progress!',
		whyDontYouTrySomeMoreDecks: 'Why don\'t you try some more decks?',
		multideckerOnFacebook: 'Multidecker on Facebook',
		course: 'Course',
		scrollToTop: 'Scroll to top',
		youHaveReachedTheEndOfFreeContent: 'You have reached the end of free content.',
		buyFor: 'Buy for',
		buyTheRestFor: 'Buy the rest for',
		tableOfContents: 'Table of contents',
		selectDeck: 'Select deck',
		insertSectionHere: 'Insert section here',
		insertDeckHere: 'Insert deck here',
		privacyPolicy: 'Privacy policy',
		language: 'Choose a language',
		thisDeckIsPrivate: 'This deck is private. Only you have access to it.',
		linkForSharing: 'Link for sharing',
		copyLink: 'Copy Link',
		checkOutThis: 'Check out this fantastic flashcards',
		moreOptions: 'More Options',
		linkCopiedToClipboard: 'The link has been copied to the clipboard',
		date: {
			short: {
				monday: 'Mon',
				tuesday: 'Tue',
				wednesday: 'Wed',
				thursday: 'Thu',
				friday: 'Fri',
				saturday: 'Sat',
				sunday: 'Sun',
			},
			january: 'January',
			february: 'February',
			march: 'March',
			april: 'April',
			may: 'May',
			june: 'June',
			july: 'July',
			august: 'August',
			september: 'September',
			october: 'October',
			november: 'November',
			december: 'December',
		},
		previousMonth: 'Previous month',
		nextMonth: 'Next month',
		landing: {
			title: 'Become a <i>smart</i> learner',
			subtitle: 'Multidecker — a smart flashcard learning tool.',
			britishEnglish: 'British English',
			americanEnglish: 'American English',
			testimonialsTitle: 'Give your learning a boost',
			testimonialsParagraph1: 'We, at multidecker, believe that people can learn anything.',
			testimonialsParagraph2: 'With the right support, direction and encouragement — and also with the right tools — anybody can learn absolutely anything! So can you!',
			organizationTitle: 'Smart Learners value organization',
			organizationSubtitle: 'Organize your knowledge to remember better. Use Multidecker unique four types of decks.',
			cloudTitle: 'Smart Learners keep an open mind',
			cloudSubtitle: 'Multidecker covers many fascinating topics. Try some now.',
			bottomSubtitle: 'Add multidecker to your learning routine today',
			topic: {
				britishVsAmericanEnglish: 'British vs American English',
				englishIdioms: 'English Idioms',
				englishGrammar: 'English Grammar',
				englishPrepositions: 'English Prepositions',
				englishVocabulary: 'English Vocabulary',
				weather: 'Weather',
				money: 'Money',
				business: 'Business',
				shopping: 'Shopping',
				politics: 'Politics',
				music: 'Music',
				britishVsAmericanEnglishLanguageAndCulture: 'British vs American English — language and culture',
				weatherRainWindSnow: 'Weather Rain Wind Snow — English vocabulary',
				moneyTalks: 'Money Talks — English Idioms',
				formalInformal: 'Formal vs Informal — Business Emails',
				shoppingEngPol: 'Shopping — English / Polish',
				politicsEngVocab: 'Politics — English Vocabulary',
				doYouKnowThisSong: 'Do you know this song?',
				sweetOrSaltyFood: 'Sweet or salty food?',
			},
		},
		landingFlashcardsGeneratorAI: {
			heroTitle: 'Elevate Learning: Introducing the Free Flashcards AI Generator',
			heroSubtitle: 'Create Flashcards and Quizzes in a Snap!',
			ctaButton: 'Create Flashcards Now',

			sectionIntroTitle: 'Unleash the Power of AI in Flashcards Creation',
			sectionIntroParagraph1: 'Calling all teachers and students! Are you tired of spending hours creating flashcards, matching games, and quizzes for your lessons?',
			sectionIntroParagraph2: 'Say hello to our revolutionary new feature - the Flashcards AI Generator! With the magic of artificial intelligence, content creation has never been easier.',

			sectionHowItWorksTitle: 'How it Works',
			sectionHowItWorksParagraph1: 'Creating engaging and educational content is as simple as 1, 2, 3!',
			sectionHowItWorksStep1Title: 'Define Your Vision',
			sectionHowItWorksStep1Content: 'Provide the details of what you would like to create - whether it\'s flashcards for vocabulary, a matching game for history, or a quiz for science. Specify the topic, level, field of study, and any specific content you\'d like to see on your materials.',
			sectionHowItWorksStep2Title: 'Let AI Do the Heavy Lifting',
			sectionHowItWorksStep2Content: 'Sit back and relax as our AI-supported tool works its magic. Using advanced algorithms and machine learning, it will craft tailor-made flashcards, games, or quizzes to match your requirements.',
			sectionHowItWorksStep3Title: 'Refine, Save, and Share',
			sectionHowItWorksStep3Content: 'Want to make some tweaks or add a personal touch? No problem! Our intuitive interface allows you to refine the generated content to suit your preferences. Once you\'re satisfied, save them to your personal library or share them with fellow educators and students.',
			sectionExamplesTitle: 'See the Magic in Action - Examples of Flashcards Generated by AI',
			sectionExamplesParagraph1: 'Witness the incredible capabilities of our Flashcards AI Generator with these stunning examples of educational materials it can create. Our AI-powered tool ensures that each flashcard, game, or quiz is intelligently crafted to cater to your specific needs and preferences. Take a sneak peek at the possibilities that await you.',
			sectionExampleVisionDefinedByYou: 'Vision defined by you',
			sectionExampleFlashcardsGeneratedByAI: 'Flashcards generated by AI',
			sectionWhyTitle: 'Why Choose Flashcards AI Generator?',
			sectionWhyReason1Title: 'Save Time and Money',
			sectionWhyReason1Content: 'No more manual content creation, and no need to open your wallet. Let AI handle the work, giving you more time to focus on teaching and learning.',
			sectionWhyReason2Title: 'Versatile Content Options',
			sectionWhyReason2Content: 'Create a wide range of materials, from interactive flashcards to engaging matching games and brain-teasing quizzes.',
			sectionWhyReason3Title: 'Tailored to Your Needs',
			sectionWhyReason3Content: 'Customizable to suit your topic, level, and field of study, ensuring the content aligns perfectly with your educational objectives.',
			sectionWhyReason4Title: 'Share and Collaborate',
			sectionWhyReason4Content: 'Build a community of knowledge-sharing by easily sharing your creations with other teachers and students.',
			sectionTryTitle: 'Don\'t Miss Out - Try it Today!',
			sectionTryParagraph1: 'Take the first step towards effortless content creation. Try the FREE Flashcards AI Generator now and witness the future of education unfold before your eyes.',
			sectionTryParagraph2: 'Sign up now and revolutionize the way you create educational content without any cost.',
			title: 'Title',
			fieldOfStudy: 'Field of study',
			topic: 'Topic',
			level: 'Level',
			frontContentType: 'Front content type',
			backContentType: 'Back content type',
			customCategories: 'Custom categories',
		},
		forTeachers: {
			title: 'Give your students learning boost',
			subtitle: 'Multidecker — a smart flashcard learning tool.',
			createDecks: 'Create flashcards now',
			testimonialsTitle: 'Use the right tool for the right job',
			testimonialsParagraph1: 'We, at multidecker, believe that people can learn anything.',
			testimonialsParagraph2: 'With the right support, direction and encouragement — and also with the right tools — anybody can learn absolutely anything!',
			organizationTitle: 'Smart Learners value organization',
			organizationSubtitle: 'Organize your students knowledge to help them remember better. Use Multidecker unique four types of decks.',
			cloudTitle: 'Smart Learners keep an open mind',
			cloudSubtitle: 'Multidecker covers many fascinating topics. Try some now from our community before creating your first deck.',
			bottomSubtitle: 'Add multidecker to your teaching tools today',
		},
		header: {
			loginFacebook: 'Sign In with Facebook',
			loginGoogle: 'Sign In with Google',
			loginEmail: 'Sign In with email',
			signUpFacebook: 'Sign Up with Facebook',
			signUpGoogle: 'Sign Up with Google',
			signUpEmail: 'Sign Up with email',
			logout: 'Logout',
			createAnAccount: 'Create an Account',
			signIn: 'Sign In',
			signUp: 'Sign Up',
			email: 'Email',
			password: 'Password',
			credentialsDoesntMatch: 'Email and password doesn\'t match',
			allSignInOptions: 'All sign in options',
			allSignUpOptions: 'All sign up options',
			welcomeBack: 'Welcome back',
			extendYourStay: 'Create Your Account',
			signInToGetAccess: 'Sign in to get access to your account.',
			noAccount: 'No account?',
			createOne: 'Create one',
			alreadyHaveAnAccount: 'Already have an account?',
			firstCreateAnAccount: 'First, create an account.',
			signUpForFreeMultideckerAccount: 'Sign up for a free multidecker account, and you’ll get access to many various decks.',
			signUpForFreeMultideckerAccountShort: 'Sign up for a free multidecker account.',
			createYourFirstDeck: 'Create your first amazing deck!',
			createWithAIHeader: 'Save Your AI Generated Flashcards!',
			createWithAISubheader: 'Sign up for a free multidecker account now',
			onceYouCreatedYourAccount: 'Once you’ve created your account, you’ll have unlimited access to multidecker.',
			yesNewsletter: 'Yes, I want to receive Multidecker\'s newsletter!',
			bySigningUpYouAgreeToOur: 'By signing up, you agree to our',
			checkYourInbox: 'Check your inbox',
			clickTheLinkToSignIn: 'Click the link we sent to {email} to confirm the log in.',
			clickTheLinkToSignUp: 'Click the link we sent to {email} to complete your account set-up.',
			weDidntRecognizeThatEmail: 'We didn\'t recognize that email',
			wouldYouLikeToSignInWithDifferentEmail: 'Would you like to sign in with a different email?',
		},
		errors: {
			savingPageFailed: 'Something went wrong :( Please reload the page.',
		},
		notFound: {
			title: '404 – We can\'t seem to find the page you are looking for.',
			deckHint: 'This page either doesn\'t exist or is marked as private. <br>Please log in or contact the author and ask them to make it public.',
			generalHint: 'Head back home',
		},
		notAuthorized: {
			title: '401 – Not authorized.',
			title70k3n: '401 - Your authorization link has expired.',
			subtitle: 'The page you\'re trying to access has restricted access.',
			suggestion70k3n: 'You can request a new sign in or sign up link below:',
		},
		buttons: {
			cancel: 'Cancel',
			confirm: 'Confirm',
			continue: 'Continue',
			create: 'Create',
			skip: 'Skip',
		},
		dashboard: {
			decks: 'Decks:',
			decksByThisAuthor: 'Decks by this author',
			nav: {
				dashboard: 'Dashboard',
				recentlyPlayed: 'Recently played',
				mostFavourite: 'Most popular',
				yours: 'Your decks',
				others: 'Others',
				favourites: 'Favourites',
				createNew: 'Create new',
				searchPlaceholder: 'Search by name...',
				searchCorrectErrors: 'Correct the errors!',
			},
			popularSearches: 'Popular searches',
			searchResultsForQuery: 'Search results for query',
			couldNotFindAnyDeck: 'Couldn\'t find any deck for your query ¯\\_(ツ)_/¯',
			perhapsYouWantCreateOne: 'Perhaps you want create one?',
			feelsLikeMakingAnyNewOnes: 'Feels like making any new ones?',
			createNewForm: {
				title: 'Create a new deck',
				whatWouldYouLikeToCreate: 'What would you like to create?',
				deckName: 'Deck name',
				articleName: 'Article Name',
				deckPrivate: 'Make this deck private.',
				articlePrivate: 'Make this article private.',
				deckDescription: 'A deck is a set of cards that students will play. They will answer by swiping the cards to the correct side.',
				articleDescription: 'The article is educational content grouped into sections. You can also include decks to make it more interactive.',
			},
			learning: {
				title: 'Your learning decks',
				empty: {
					title: 'You don\'t have any learning decks',
					info: {
						prefix: 'Tag',
						othersLink: 'others',
						connector: 'or',
						yourLink: 'your',
						suffix: 'decks that you want to learn them. These will appear here automatically. Then you can start learning.',
					},
				},
			},
			recentlyPlayed: {
				title: 'Recently played decks',
				playAnyDecksAndThoseWillAppearHere: 'Play any decks and those will appear here.',
			},
			mostFavourite: {
				title: 'Most popular decks',
			},
			others: {
				title: 'Others decks',
			},
			favourites: {
				title: 'Your Favourite decks',
				giveSomeLoveToYourFavourites: 'Give some ❤️to your favourite decks so you have them in one place.',
			},
			yours: {
				title: 'Decks created by you',
			},
			seeMore: 'See more',
			noDecksHere: 'No decks here',
			noCardsHere: 'No cards here',
			noQuestionsHere: 'No questions here',
			noMoreDecks: 'There are no more decks',
			loadMore: 'Load more',
		},
		stats: {
			lastTimePlayed: 'Last time played',
			currRunStreak: 'Current run streak',
			never: 'never',
			today: 'today',
			yesterday: 'yesterday',
			day: 'days | day | days',
			youJoinedMD: 'You joined Multidecker ✨',
			yourActivity: 'Your activity',
			hideYourActivity: 'Hide your activity',
			showYourActivity: 'Show your activity',
			youAreLoggedOut: 'You are not signed in, your stats may be incomplete.',
			signIn: 'Sign in',
			toSeeYourActivity: 'to see your full stats.',
			last7days: 'Last 7 days',
			activeDays: 'Active days',
		},
		deckPlay: {
			howToPlay: 'How to play?',
			singleChoiceInstruction: 'Single-choice quiz. Select the answer and then press the "Next" button.',
			multipleChoiceInstruction: 'Multiple-choice quiz. Select one or more answers and then press the "Next" button.',
			tapOnTheAnswerOrSwipeEachCardToTheSides: 'Tap on the answer or swipe each card to the sides. Right – if you know card, left – if you don\'t know. Tap the card to see the reverse.',
			tapOnTheAnswersOrSwipeEachCard: 'Tap on the answer or swipe each card',
			tapTheCardToSeeTheReverse: 'Tap the card to see the reverse.',
			left: 'left',
			right: 'right',
			up: 'up',
			down: 'down',
			noCards: '⚠️This deck doesn\'t have any cards...',
			check: 'Check',
			next: 'Next',
			playAgain: 'Play Again',
			playNext: 'Play Next',
			yourResults: 'Your results',
			scoreIsPrefix: 'Your score is',
			scoreIsSuffix: 'out of {total}.',
			progress: '{swiped} / {total}',
			correctAnswer: 'Correct answer',
			yourAnswer: 'Your answer',
			cardProgress: 'Progress',
			frontSide: 'Front side',
			backSide: 'Back side',
			notYet: 'Not yet',
			IKnow: 'I know',
			correctFeedback: 'Good :)',
			wrongFeedback: 'Oops...',
			playSettings: 'Play settings',
			displayStickers: 'Display stickers on cards',
			results: {
				correctPercent100: 'Brilliant! Impressive work!',
				correctPercent75: 'Well done! Keep up the good work!',
				correctPercent60: 'You did well! Good job!',
				correctPercent40: 'Well, not too bad, but you can do better, can\'t you?',
				correctPercent25: 'It\'s not too impressive, sorry, why don\'t you try again?',
				correctPercent10: 'You are not there ... yet. Why don\'t you try again?',
				correctPercent0: 'Has something gone wrong? Perhaps you could try again?',
			},
		},
		deckCreate: {
			youAreCreatingDeck: 'You are creating deck:',
			youAreEditingDeck: 'You are editing deck:',
			deckType: 'Deck type',
			removeThisDeck: 'Remove',
			editDeckName: 'Edit deck name',
			makeThisDeckPublic: 'Make public',
			makeThisDeckPrivate: 'Make private',
			doYouWantToMakeThisDeckPublic: 'Do you want to make this deck public?',
			doYouWantToMakeThisDeckPrivate: 'Do you want to make this deck private?',
			areYouSure: 'Are you sure?',
			doYouWantToRemoveThisDeck: 'Do you want to remove this deck?',
			editName: 'Edit name',
			question: 'Question',
			addAnswer: 'Add answer',
			nextQuestion: 'Next question',
			answer: 'Answer',
			answers: 'Answers',
			categories: 'Categories',
			cards: 'Cards',
			description: 'Description',
			basic: 'Do I know this?',
			deckTypes: 'Deck types',
			questionTypes: 'Question types',
			example: 'Example:',
			exampleBasic: 'Player will swipe to the right to indicate that they know the answer.',
			exampleBasicTitle: 'How much do you know about the United States?',
			exampleBasicCardText: 'How many stars has the American flag got?',
			exampleBasicCardText2: 'What is the capital of the United States?',
			exampleBasicCardText3: '👨‍🚀 Who was the first U.S. astronaut to travel in space?',
			twoCustomAnswers: 'Two custom categories',
			customAnswers: 'Categorization game ( 2, 3 or 4 categories )',
			exampleTwoCustomAnswersTitle: 'Is it a fruit or a vegetable?',
			exampleTwoCustomAnswers: 'Player will swipe to the right to indicate that banana is a fruit.',
			exampleTwoCustomAnswersCardText: 'Banana 🍌',
			exampleTwoCustomAnswersCardText2: 'Apple 🍏',
			exampleTwoCustomAnswersCardText3: 'Potato 🥔',
			exampleTwoCustomAnswersCardAnswer1: 'Vegetable',
			exampleTwoCustomAnswersCardAnswer2: 'Fruit',
			threeCustomAnswers: 'Three custom categories',
			exampleThreeCustomAnswers: 'Player will swipe to the right to indicate that blizzard relates to snow.',
			exampleThreeCustomAnswersCardText: 'blizzard',
			exampleThreeCustomAnswersCardText2: 'slush',
			exampleThreeCustomAnswersCardText3: 'twister',
			exampleThreeCustomAnswersCardAnswer1: '🌬️ wind',
			exampleThreeCustomAnswersCardAnswer2: '🌨️ snow',
			exampleThreeCustomAnswersCardAnswer3: '🌧️ rain',
			exampleSingleChoice: 'Player will be able to choose only one answer.',
			exampleMultipleChoice: 'Player will be able to choose more than one answer.',
			exampleMultipleChoiceQuestion: 'Which of the following are the prime numbers?',
			fourCustomAnswers: 'Four custom categories',
			singleChoice: 'Single choice',
			multipleChoice: 'Multiple choice',
			exampleFourCustomAnswers: 'Player will swipe to the right to indicate that February is in winter.',
			exampleFourCustomAnswersCardText1: 'February',
			exampleFourCustomAnswersCardText2: 'January',
			exampleFourCustomAnswersCardText3: 'July',
			exampleFourCustomAnswersCardText4: 'April',
			exampleFourCustomAnswersCardAnswer1: '🌻 Summer',
			exampleFourCustomAnswersCardAnswer2: '❄️Winter',
			exampleFourCustomAnswersCardAnswer3: '🌱 Spring',
			exampleFourCustomAnswersCardAnswer4: '🍂 Autumn',
			addCardToThisDeck: 'Add cards to this deck. Fill in the front and back',
			optional: 'optional',
			cardFront: 'Card front:',
			cardBack: 'Card back:',
			cardBackPlaceholder: 'You can either add hints or explanations on the backs of cards',
			addCards: 'Add cards',
			pleaseProvideContentOfTheFrontCard: 'Please provide content of the front card.',
			chooseTheCorrectAnswer: 'Choose the correct answer',
			chooseAtLeastOneCorrectAnswer: 'Choose at least one correct answer',
			addAtLeastTwoAnswers: 'Add at least two answers',
			selectTheAnswerForThisCard: 'Select the category for this card:',
			addCard: 'Add card',
			addCategory: 'Add category',
			add: 'Add',
			answerText: 'Category text',
			answerOne: 'Category one',
			answerTwo: 'Category two',
			answerThree: 'Category three',
			answerFour: 'Category four',
			cardsWillGoHere: 'Cards will go here, you will add them in the next step.',
			youNeedAtLeastTwoCategories: 'You need at least two categories for your deck.',
			youHaveUnsavedChangesInThisStep: 'You have unsaved changes in this step.',
			importMultipleCards: 'Import multiple cards',
			youHaveNCardsInThisDeck: 'You have no cards in this deck | You have 1 card in this deck | You have {count} cards in this deck',
			youHaveNQuestionsInThisDeck: 'You have no questions in this deck | You have 1 question in this deck | You have {count} questions in this deck',
			howTo: 'How to',
			importMultipleHowToOne: 'Front and back have to be separated by tab or semicolon character',
			importMultipleHowToTwo: 'Back is on the right hand side',
			importMultipleHowToThree: 'Each line represents one card',
			separator: 'Separator',
			tab: 'Tab',
			semicolon: 'Semicolon',
			cardsWithoutCustomAnswer: 'Cards without custom answer:',
			flipAllCardsTo: 'Flip cards to:',
			front: 'front',
			back: 'back',
			backUppercase: 'Back',
			hideAnswers: 'Hide answers',
			showAnswers: 'Show answers',
			editCard: 'Edit card',
			editCategory: 'Edit category',
			displayName: 'Name',
			cancel: 'Cancel',
			save: 'Save',
			saved: 'Saved',
			saving: 'Saving...',
			thereAreNoAnswersForThisDeck: 'There are no categories set for this deck. Did you forgot to add them in the previous step?',
			pleaseAddAndSelectAnAnswer: 'Please add and select a category for the card.',
			addFirstCategoryInfo: 'Add here the first category for the deck',
			pleaseSelectAnAnswer: 'Please select a category for the card.',
			answerCantBeEmpty: 'Category can\'t be empty.',
			themeImage: 'Deck image',
			openGallery: 'Set deck image',
			selectThemeForYourDeck: 'Select theme for your deck.',
			searchForImages: 'Search for images',
			somethingWentWrong: 'Something went wrong, please try again.',
			seeMore: 'see more',
			setImage: 'Set image',
			setThemeImage: 'Set theme image',
			shortDeckDescription: 'Short deck description',
			shortDescriptionOfADeck: 'Short description of a deck',
			charactersLeft: 'Characters left',
			preview: 'Preview',
			no: 'No',
			yes: 'Yes',
			finishModalHeader: 'Nice one! Now you can share or embed your deck! 😎',
			yourDeckIsPrivate: 'Your deck is private, only you can access it.',
			yourDeckIsPublic: 'Your deck is now public.',
			makeItPublicToEmbedOrShare: 'Make it public so you can share it with others or embed it on your website.',
			makeItPublicToShare: 'Make it public so you can share it with others.',
			nowYouCanEmbedOrShare: 'You can start sharing it with others or embed it on your website.',
			makeItPublicToEmbed: 'Make it public so that you can embed it on your website.',
			nowYouCanEmbed: 'You can embed it on your website.',
		},
		deckGenerate: {
			generateCardsWithAI: 'Generate Cards with AI',
			generateQuestionsWithAI: 'Generate Questions with AI',
			createCardsWithEase: 'Create Flashcards in a Snap!',
			createQuizWithEase: 'Create Quiz in a Snap',
			bannerText: 'Try our new Flashcards AI Generator. Simply write down your requirements and let our AI-powered generator create your cards in seconds.',
			bannerCTA: 'Try the AI Generator Now',
			letsCreateSomeMagicFlashcards: 'Let\'s create some magic flashcards!',
			lookingForInspirations: 'Looking for inspiration? See examples.',
			tryExamples: 'See Examples',
			hideOptionalFields: 'Hide optional fields',
			showOptionalFields: 'Show optional fields',
			recommended: 'recommended',
			startWithInspiration: 'Start with Sample Inspiration',
			useExampleValues: 'Use Example Values',
			weWillCreateADeckForYou: 'We will create a deck for you! Just give us some details about your deck and you will see results in seconds.',
			describeWhatYouWouldLikeToCreate: 'Describe what you\'d like to create',
			chooseFlashcardsOrQuizType: 'Choose Flashcards or Quiz Type',
			playDeck: 'Play Deck',
			whatKindOfFlashcards: 'What kind of flashcards/quizzes would you like to create?',
			howManyCardsWouldYouLikeToGenerate: 'How many cards would you like to generate?',
			whatIsTheFieldOfStudy: 'What is the field of study?',
			fieldOfStudy: 'Field of study',
			whatIsTheLevel: 'What is the level?',
			clue: 'Clue',
			whatIsTheDeckAbout: 'What is the deck about?',
			describeTheFlashcards: 'Describe the flashcards you would like to create.',
			describeTheQuiz: 'Describe the quiz you would like to create.',
			englishGrammarAdverbsOfFrequency: 'English grammar adverbs of frequency',
			whatTypeOfContentFront: 'What type of content should be on the front of the card?',
			question: 'Question',
			whatTypeOfContentBack: 'What type of content should be on the back of the card?',
			whatCustomCategories: 'What custom categories should the deck have?',
			placeholderFruitsVegetables: 'Fruits, Vegetables',
			generateFlashcards: 'Generate Flashcards',
			generatedCards: 'Generated Cards',
			youCanModifyRemoveOrGenerateMore: 'You can modify, remove or generate more cards before saving them to your deck.',
			generateMoreCards: 'Generate More Cards',
			generateMore: 'Generate More',
			more: 'more',
			discardExistingCardsAndRefine: 'Discard Existing Cards and Refine',
			discardAndRefine: 'Discard and Refine',
			saveCards: 'Save Cards',
			save: 'Save',
			howWouldYouRate: 'How would you rate the quality of the results generated by the AI tool?',
			thankYouForYourRating: 'Great! Thank you for your rating. Could you please provide some specific feedback or comments? We value your input and it will help us improve our services.',
			nCardsHadBeenAdded: 'No cards have been added to your deck | 1 card has been added to your deck | {count} cards have been added to your deck',
			somethingWentWrong: 'Something went wrong while generating flashcards :( Please try again.',
			aiIsWorkingHard: 'Our AI is hard at work generating your flashcards.',
			thisMayTakeUpTo: 'This may take up to 15 seconds!',
		},
		deckEmbed: {
			embedDeck: 'Embed deck',
			size: 'Size',
			showCode: 'Show code',
			copy: 'Copy',
			copied: 'Copied.',
			normal: 'Normal',
			tall: 'Tall',
		},
		stepsButtons: {
			previousStep: 'Back',
			nextStep: 'Next Step',
			finish: 'Finish',
			back: 'Back',
			next: 'Next',
			done: 'Done',
		},
		userInfo: {
			almostThere: 'Almost there!',
			finishCreatingYourAccount: 'Finish creating your account for the full Multidecker experience.',
			yourFullName: 'Your full name',
			yourEmail: 'Your email',
			weWantToTailorYourExperience: 'We want to tailor your experience for your needs.',
			howWouldYouDescribeYourself: 'How would you describe yourself?',
			iAmATeacher: '👩‍🏫  I\'m a teacher',
			iAmAStudent: '👨🏽‍🎓  I\'m a student',
			selectTopics: 'Select topics that matter the most to you.',
			continue: 'Continue',
			english: 'English',
			foreign_languages: 'Foreign Languages',
			geography: 'Geography',
			mathematics: 'Mathematics',
			biology: 'Biology',
			entertainment: 'Entertainment',
			technology: 'Technology',
			business: 'Business',
			science: 'Science',
			law: 'Law',
			'art_&_culture': 'Art & Culture',
			sport: 'Sport',
			history: 'History',
			others: 'Others',
		},
		satisfactionFeedback: {
			doYouLikeMultidecker: 'Do you like multidecker?',
			love: 'love',
			like: 'like',
			neutral: 'neutral',
			dislike: 'dislike',
			hate: 'hate',
			loveIt: 'love it',
			likeIt: 'like it',
			dislikeIt: 'dislike it',
			hateIt: 'hate it',
			greatPleaseTakeAMoment: 'Great! 😀 Please take a moment to like us on facebook!',
			thankYouForYourFeedback: 'Thank you for your feedback, it means a lot to us! 🙏',
			weAreConstantlyTrying: 'We are constantly trying to improve multidecker. Please help us doing that and <a href="https://www.surveymonkey.co.uk/r/MD9JHGC" target="_blank">fill out this short survey</a>.',
			tellUsWhatDoYouThink: 'Tell us what you think about this page',
			whatDoYouThinkOfThis: 'What do you think of this?',
			whatKindOfFeedback: 'What kind of feedback do you have?',
			iHaveA: 'I have a ...',
			bugOrError: 'Bug or Error',
			complaintOrQuestion: 'Complaint or Question',
			suggestion: 'Suggestion',
			compliment: 'Compliment',
			submit: 'Submit',
			maybeLater: 'Maybe later',
			sorryWhatWentWrong: 'Sorry! What went wrong?',
			whatCouldBeBetter: 'What could be better?',
			whatWouldYouLikeToShare: 'What would you like to share with us?',
			whatIsWorkingWell: 'What\'s working well for you?',
			thankYouForFeedback: 'Thank you for your feedback! 😀',
		},
		surveyInvitation: {
			pennyForYourThoughts: '💰 Penny for your thoughts?',
			popoverText1: 'We are reaching out to a few of our newest users to better understand your experience with Multidecker.',
			popoverText2: '<strong>We\'ll give you a 50 zł Allegro voucher for your time.</strong>',
			sureWhyNot: 'Sure, why not?',
			dialogLine1: 'Would you be up for a 45-minute chat this or next week?',
			dialogLine2: 'The conversation will be with our customer researcher, Artur.',
			listItem1: 'You don’t need to prep or bring anything.',
			listItem2: 'This isn’t a sales call.',
			listItem3: 'There’ll be no trick questions.',
			listItem4: '<strong>We’ll send you your 50 zł Allegro voucher right after the call.</strong>',
			dialogLine3: 'There are a few times available in Artur\'s calendar that you can book directly.',
			dialogLine4: 'If none of those work, feel free to <a href="mailto:adelura@gmail.com" target="_blank">reply with your availability</a> and we\'ll set something up.',
			dialogLine5: 'Thank you in advance. Every bit of feedback helps. 😊',
			selectDateAndTime: 'Select date and time',
		},
		userProfile: {
			user: 'user',
			memberSince: 'Member since',
			thisUserDoesNotHaveAnyDeck: 'This user doesn\'t have any decks',
		},
	},
	pl: {
		title: 'Multidecker: Inteligentne fiszki, które nauczą Cię wszystkiego w mgnieniu oka',
		mostPopularFlashcardsTitle: 'Najpopularniejsze fiszki | multidecker',
		privacyPolicyTitle: 'Polityka prywatności | multidecker',
		dataDeletionTitle: 'Instrukcje dotyczące usuwania danych osobowych | multidecker',
		flashcardsAIGenerator: 'Flashcards AI Generator | multidecker',
		flashcardsAIGeneratorDescription: 'Create personalized educational content effortlessly with our AI-powered Flashcards Generator. Generate interactive flashcards and quizzes for free. Transform your teaching experience today!',
		description: 'Inteligentne fiszki multidecker przyspieszają proces uczenia się. Jedyna aplikacja z kartami, która porządkuje Twoją wiedzę. Wypróbuj je i zobacz, jakie są inteligentne.',
		deck: 'Fiszki',
		article: 'Artykuł',
		section: 'Sekcja',
		author: 'Autor',
		play: 'Graj',
		preview: 'Podgląd',
		edit: 'Edytuj',
		finishEditing: 'Zakończ edytowanie',
		addSection: 'Dodaj sekcję',
		editSectionName: 'Zmień nazwę sekcji',
		remove: 'Usuń',
		share: 'Udostępnij',
		embed: 'Osadź',
		cards: 'Karty',
		or: 'albo',
		displaySettings: 'Ustawienia wyświetlania',
		sortCardsBy: 'Sortuj karty po',
		noSorting: 'Domyślne',
		betterKnownFirst: 'Wiedza: Najpierw lepiej znane',
		lessKnownFirst: 'Wiedza: Najpierw mniej znane',
		displayAs: 'Wyświetl jako',
		table: 'Tabelka',
		flipAllCardsTo: 'Odwróć karty w',
		answersVisibility: 'Widoczność odpowiedzi',
		progressVisibility: 'Widoczność postępu',
		expandedInformation: 'Rozszerzona Informacja',
		show: 'pokaż',
		hide: 'ukryj',
		close: 'Zamknij',
		thereAreNoCardsInThisDeck: 'Ten zestaw nie zawiera kart',
		trackYourResults: 'Śledź swoje wyniki i postęp.',
		trackYourResults2: 'Zapisz swoje wyniki i postęp.',
		youTooCanCreateDeck: 'Chcesz stworzyć własny zestaw fiszek?',
		createDeckNow: 'Utwórz fiszki teraz',
		whyDontYouTrySomeMoreDecks: 'Chcesz spróbować więcej fiszek?',
		multideckerOnFacebook: 'Multidecker na Facebooku',
		course: 'Kurs',
		scrollToTop: 'Wróć do góry',
		youHaveReachedTheEndOfFreeContent: 'Dotarłeś do końca bezpłatnej zawartości.',
		buyFor: 'Kup za',
		buyTheRestFor: 'Kup resztę za',
		tableOfContents: 'Spis treści',
		selectDeck: 'Wybierz zestaw',
		insertSectionHere: 'Wstaw tutaj sekcję',
		insertDeckHere: 'Wstaw tutaj zestaw',
		privacyPolicy: 'Polityka prywatności',
		language: 'Wybierz język',
		thisDeckIsPrivate: 'Ten zestaw jest prywatny. Tylko Ty masz do niego dostęp.',
		linkForSharing: 'Link do Udostępniania',
		copyLink: 'Kopiuj Link',
		checkOutThis: 'Sprawdź te fantastyczne fiszki',
		moreOptions: 'Więcej Opcji',
		linkCopiedToClipboard: 'Link został skopiowany do schowka',
		date: {
			short: {
				monday: 'Pon',
				tuesday: 'Wt',
				wednesday: 'Śr',
				thursday: 'Czw',
				friday: 'Pt',
				saturday: 'Sob',
				sunday: 'Nie',
			},
			january: 'Styczeń',
			february: 'Luty',
			march: 'Marzec',
			april: 'Kwiecień',
			may: 'Maj',
			june: 'Czerwiec',
			july: 'Lipiec',
			august: 'Sierpień',
			september: 'Wrzesień',
			october: 'Październink',
			november: 'Listopad',
			december: 'Grudzień',
		},
		previousMonth: 'Poprzedni miesiąc',
		nextMonth: 'Kolejny miesiąc',
		landing: {
			title: 'Zostań <i>bystrym</i> uczniem',
			subtitle: 'Multidecker — inteligentne narzędzie do nauki z fiszkami.',
			britishEnglish: 'Brytyjski Angielski',
			americanEnglish: 'Amerykański Angielski',
			testimonialsTitle: 'Przyśpiesz swoją naukę',
			testimonialsParagraph1: 'W multideckerze wierzymy, że ludzie mogą się wszystkiego nauczyć.',
			testimonialsParagraph2: 'Z odpowiednim wsparciem, wskazówkami i zachętą - a także z odpowiednimi narzędziami - każdy może nauczyć się absolutnie wszystkiego! Więc Ty też możesz!',
			organizationTitle: 'Bystrzy uczniowie cenią organizację',
			organizationSubtitle: 'Uporządkuj swoją wiedzę, aby poprawić swoją pamięć. Użyj unikalnych czterech rodzajów fiszek multidecker.',
			cloudTitle: 'Bystrzy uczniowie mają otwarty umysł',
			cloudSubtitle: 'Multidecker obejmuje wiele fascynujących tematów. Wypróbuj któryś teraz.',
			bottomSubtitle: 'Dodaj multideckera do swojej rutyny już dziś',
			topic: {
				britishVsAmericanEnglish: 'Brytyjski a Amerykański angielski',
				englishIdioms: 'Idiomy angielskie',
				englishGrammar: 'Grammatyka angielska',
				englishPrepositions: 'Przyimki angielskie',
				englishVocabulary: 'Słownictwo angielskie',
				weather: 'Pogoda',
				money: 'Pieniądze',
				business: 'Biznes',
				shopping: 'Zakupy',
				politics: 'Polityka',
				music: 'Muzyka',
				britishVsAmericanEnglishLanguageAndCulture: 'Brytyjski a Amerykański Angielski — Język i Kultura',
				weatherRainWindSnow: 'Pogoda Desz Wiatr Śnieg — Słownictwo Angielskie',
				moneyTalks: 'Pieniądze — Idiomy Angielskie',
				formalInformal: 'Formalne a Nieformalne - E-maile Biznesowe',
				shoppingEngPol: 'Zakupy — Angielski / Polski',
				politicsEngVocab: 'Polityka — Słownictwo Angielskie',
				doYouKnowThisSong: 'Znasz tą piosenkę?',
				sweetOrSaltyFood: 'Słodkie czy słone jedzenie?',
			},
		},
		forTeachers: {
			title: 'Daj swoim uczniom impuls do nauki',
			subtitle: 'Multidecker — inteligentne narzędzie do nauki z fiszkami.',
			createDecks: 'Stwórz fiszki teraz',
			testimonialsTitle: 'Użyj odpowiedniego narzędzia, aby utrzymać zaangażowanie uczniów',
			testimonialsParagraph1: 'W multideckerze wierzymy, że ludzie mogą się wszystkiego nauczyć.',
			testimonialsParagraph2: 'Z odpowiednim wsparciem, wskazówkami i zachętą - a także z odpowiednimi narzędziami - każdy może nauczyć się absolutnie wszystkiego!',
			organizationTitle: 'Twoi czniowie cenią organizację',
			organizationSubtitle: 'Uporządkuj wiedzę Twoich uczniów i wspomóż ich w zapamiętywaniu.<br/> Użyj unikalnych czterech rodzajów fiszek multidecker.',
			cloudTitle: 'Multidecker obejmuje wiele fascynujących tematów',
			cloudSubtitle: 'Wypróbuj teraz kilka zestawów ze społeczności multideckera, zanim stworzysz swój pierwszy zestaw fiszek.',
			bottomSubtitle: 'Dodaj multidecker do swoich narzędzi nauczania już dziś.',
		},
		header: {
			loginFacebook: 'Zaloguj przez Facebook',
			loginGoogle: 'Zaloguj przez Google',
			loginEmail: 'Zaloguj przez email',
			signUpFacebook: 'Kontynuuj z Facebook',
			signUpGoogle: 'Kontynuj z Google',
			signUpEmail: 'Kontynuj z email',
			logout: 'Wyloguj',
			createAnAccount: 'Stwórz konto',
			signIn: 'Zaloguj się',
			signUp: 'Zerejestruj się',
			email: 'Email',
			password: 'Hasło',
			credentialsDoesntMatch: 'Email i hasło nie pasują do siebie',
			allSignInOptions: 'Powrót do opcji logowania',
			allSignUpOptions: 'Powrót do opcji rejestracji',
			welcomeBack: 'Witaj ponownie',
			extendYourStay: 'Utwórz Swoje Konto',
			signInToGetAccess: 'Zaloguj się, aby uzyskać dostęp do swojego konta.',
			noAccount: 'Nie masz konta?',
			createOne: 'Załóż konto',
			alreadyHaveAnAccount: 'Masz juz konto?',
			firstCreateAnAccount: 'Najpierw załóż konto.',
			signUpForFreeMultideckerAccount: 'Załóż darmowe konto multidecker, aby mieć dostęp do różnych fiszek.',
			signUpForFreeMultideckerAccountShort: 'Załóż darmowe konto multidecker.',
			createYourFirstDeck: 'Stwórz swój pierwszy niesamowity zestaw fiszek!',
			createWithAIHeader: 'Zapisz fiszki wygenerowane przez AI!',
			createWithAISubheader: 'Załóż darmowe konto multidecker.',
			onceYouCreatedYourAccount: 'Po utworzeniu konta będziesz mieć nieograniczony dostęp do multidecker.',
			yesNewsletter: 'Tak, chcę otrzymywać newsletter multidecker!',
			bySigningUpYouAgreeToOur: 'Zakładając konto zgadzasz się z naszą',
			checkYourInbox: 'Sprawdź swoją skrzynkę odbiorczą',
			clickTheLinkToSignIn: 'Kliknij link, który wysłaliśmy na adres {email}, aby potwierdzić logowanie.',
			clickTheLinkToSignUp: 'Kliknij link, który wysłaliśmy na adres {email}, aby dokończyć rejestrację.',
			weDidntRecognizeThatEmail: 'Nie rozpoznaliśmy tego e-maila',
			wouldYouLikeToSignInWithDifferentEmail: 'Czy chcesz zalogować się za pomocą innego adresu e-mail?',
		},
		errors: {
			savingPageFailed: 'Coś poszło nie tak :( Przeładuj stronę.',
		},
		notFound: {
			title: '404 – Nie możemy znaleźć strony, której szukasz.',
			deckHint: 'Ta strona nie istnieje lub jest oznaczona jako prywatna. <br>Zaloguj się lub skontaktuj się z autorem i poproś o upublicznienie.',
			generalHint: 'Wróć na stronę główną',
		},
		notAuthorized: {
			title: '401 – Brak autoryzacji.',
			title70k3n: '401 - Twój link autoryzacyjny wygasł.',
			subtitle: 'Dostęp do strony, do której próbujesz uzyskać dostęp, jest ograniczony.',
			suggestion70k3n: 'Poniżej możesz wygenerować nowy link do logowania lub rejestracji:',
		},
		buttons: {
			cancel: 'Anuluj',
			confirm: 'Potwierdź',
			continue: 'Kontynuuj',
			create: 'Utwórz',
			skip: 'Pomiń',
		},
		dashboard: {
			decks: 'Fiszki:',
			decksByThisAuthor: 'Fiszki tego autora',
			nav: {
				dashboard: 'Strona główna',
				recentlyPlayed: 'Ostatnio grane',
				mostFavourite: 'Najpopularniejsze',
				yours: 'Twoje fiszki',
				others: 'Fiszki innych',
				favourites: 'Twoje ulubione',
				createNew: 'Utwórz nowe fiszki',
				searchPlaceholder: 'Szukaj po nazwie...',
				searchCorrectErrors: 'Popraw błędy!',
			},
			popularSearches: 'Popularne wyszukiwania',
			searchResultsForQuery: 'Wyniki wyszukiwania',
			couldNotFindAnyDeck: 'Nie udało się znaleźć żadnych wyników dla Twojego zapytania ¯\\_(ツ)_/¯',
			perhapsYouWantCreateOne: 'Może chcesz go stworzyć?',
			feelsLikeMakingAnyNewOnes: 'Masz ochotę zrobić jakieś nowe?',
			createNewForm: {
				title: 'Stwórz fiszki',
				whatWouldYouLikeToCreate: 'Co chciałbyś stworzyć?',
				deckName: 'Nazwa zestawu',
				articleName: 'Nazwa artykułu',
				deckPrivate: 'Ustaw fiszki jako prywatne.',
				articlePrivate: 'Ustaw artykuł jako prywatny.',
				deckDescription: 'Talia to zestaw kart, w które będą grać uczniowie. Będą odpowiadać, przesuwając karty na boki.',
				articleDescription: 'Artykuł jest treścią edukacyjną pogrupowaną w sekcje. Możesz również dołączyć fiszki, aby stworzyć go bardziej interaktywny.',
			},
			learning: {
				title: 'Twoje zapisane fiszki',
				empty: {
					title: 'Nie masz zapisanych talii',
					info: {
						prefix: 'Oznacz',
						othersLink: 'innych',
						connector: 'lub',
						yourLink: 'swoje',
						suffix: 'fiszki, ktorych chciałbyś sie nauczyć. Fiszki pojawia się automatycznie',
					},
				},
			},
			recentlyPlayed: {
				title: 'Ostatnio grane fiszki',
				playAnyDecksAndThoseWillAppearHere: 'Zagraj w dowolne fiszki, a te pojawią się tutaj.',
			},
			mostFavourite: {
				title: 'Najpopularniejsze fiszki',
			},
			others: {
				title: 'Fiszki innych użytkownikow',
			},
			favourites: {
				title: 'Twoje ulubione fiszki',
				giveSomeLoveToYourFavourites: 'Podaruj ❤️swoim ulubionym fiszkom, aby mieć je w jednym miejscu.',
			},
			yours: {
				title: 'Fiszki stworzone przez Ciebie',
			},
			seeMore: 'Zobacz więcej',
			noDecksHere: 'Nie ma tu fiszek',
			noCardsHere: 'Nie ma tu kart',
			noQuestionsHere: 'Nie ma tu pytań',
			noMoreDecks: 'Nie ma więcej fiszek',
			loadMore: 'Załaduj więcej',
		},
		stats: {
			lastTimePlayed: 'Ostatni raz grano',
			currRunStreak: 'Aktualna passa',
			never: 'nigdy',
			today: 'dzisiaj',
			yesterday: 'wczoraj',
			day: 'dni | dzień | dni | dni',
			youJoinedMD: 'Dołączenie do multideckera ✨',
			yourActivity: 'Twoja aktywność',
			hideYourActivity: 'Ukryj swoją aktywność',
			showYourActivity: 'Pokaż swoją aktywność',
			youAreLoggedOut: 'Jesteś wylogowany, Twoja aktywność może być niekompletna.',
			signIn: 'Zaloguj się',
			toSeeYourActivity: 'aby zobaczyć swoją pełną aktywność.',
			last7days: 'Ostatnie 7 dni',
			activeDays: 'Aktywne dni',
		},
		deckPlay: {
			howToPlay: 'Jak grać?',
			singleChoiceInstruction: 'Quiz z pytaniami jednokrotnego wyboru. Wybierz odpowiedź, a następnie naciśnij przycisk „Dalej”.',
			multipleChoiceInstruction: 'Quiz z pytaniami wielokrotnego wyboru. Wybierz jedną lub więcej odpowiedzi, a następnie naciśnij przycisk „Dalej”.',
			tapOnTheAnswerOrSwipeEachCardToTheSides: 'Dotknij odpowiedzi lub przesuń kartę na bok. W prawo – jeśli znasz odpowiedź, w lewo – gdy jeszcze nie znasz. Dotknij kartę, aby zobaczyć rewers.',
			tapOnTheAnswersOrSwipeEachCard: 'Dotknij odpowiedzi lub przesuń kartę',
			tapTheCardToSeeTheReverse: 'Dotknij kartę, aby zobaczyć rewers.',
			left: 'w lewo',
			right: 'w prawo',
			up: 'w górę',
			down: 'w dół',
			noCards: '⚠️W tym zestawie nie ma żadnych kart ...',
			check: 'Sprawdź',
			next: 'Dalej',
			playAgain: 'Zagraj Ponownie',
			playNext: 'Zagraj Następny',
			yourResults: 'Twoje wyniki',
			scoreIsPrefix: 'Twój wynik to ',
			scoreIsSuffix: 'na {total}.',
			progress: '{swiped} / {total}',
			correctAnswer: 'Poprawna odpowiedź',
			yourAnswer: 'Twoja odpowiedź',
			cardProgress: 'Postęp',
			frontSide: 'Przód',
			backSide: 'Tył',
			notYet: 'Nie wiem',
			IKnow: 'Wiem',
			correctFeedback: 'Świetnie :)',
			wrongFeedback: 'Ups...',
			playSettings: 'Ustawienia gry',
			displayStickers: 'Wyświetlaj naklejki na kartach',
			results: {
				correctPercent100: 'Wspaniale! Imponujący wynik!',
				correctPercent75: 'Dobra robota! Tak trzymaj!',
				correctPercent60: 'Dobrze się spisałeś! Dobra robota!',
				correctPercent40: 'Cóż, nieźle, ale możesz zrobić lepiej, prawda?',
				correctPercent25: 'Spróbuj zagrać jeszcze raz!',
				correctPercent10: 'To jeszcze nie to... Spróbuj ponownie.',
				correctPercent0: 'Coś poszło nie tak? Może chcesz spróbować ponownie?',
			},
		},
		deckCreate: {
			youAreCreatingDeck: 'Tworzysz zestaw:',
			youAreEditingDeck: 'Edytujesz zestaw:',
			deckType: 'Rodzaj fiszek',
			removeThisDeck: 'Usuń',
			editDeckName: 'Zmień nazwę zestawu',
			makeThisDeckPublic: 'Ustaw jako publiczny',
			makeThisDeckPrivate: 'Ustaw jako prywatny',
			doYouWantToMakeThisDeckPublic: 'Czy chcesz ustawić te fiszki jako publiczne?',
			doYouWantToMakeThisDeckPrivate: 'Czy chcesz ustawić te fiszki jako prywatne?',
			areYouSure: 'Czy jesteś pewien?',
			doYouWantToRemoveThisDeck: 'Czy chcesz usunać ten zestaw?',
			editName: 'Zmień nazwę',
			question: 'Pytanie',
			addAnswer: 'Dodaj odpowiedź',
			nextQuestion: 'Następne pytanie',
			answer: 'Odpowiedź',
			answers: 'Odpowiedzi',
			categories: 'Kategorie',
			cards: 'Karty',
			description: 'Opis',
			basic: 'Czy ja to wiem?',
			deckTypes: 'Rodzaje fiszek',
			questionTypes: 'Rodzaje pytań',
			example: 'Przykład:',
			exampleBasic: 'Gracz przesunie palcem w prawo, aby wskazać, że zna odpowiedź.',
			exampleBasicTitle: 'Ile wiesz o Stanach Zjednoczonych?',
			exampleBasicCardText: 'Ile gwiazdek ma flaga amerykańska?',
			exampleBasicCardText2: 'Jaka jest stolica Stanów Zjednoczonych?',
			exampleBasicCardText3: '👨‍🚀 Kto był pierwszym amerykańskim astronautą, który podróżował w kosmosie?',
			twoCustomAnswers: 'Dwie niestandardowe kategorie',
			customAnswers: 'Niestandardowe Kategorie ( 2, 3 lub 4) ',
			exampleTwoCustomAnswersTitle: 'Owoc czy warzywo?',
			exampleTwoCustomAnswers: 'Gracz przesunie palcem w prawo, aby wskazać, że banan to owoc.',
			exampleTwoCustomAnswersCardText: 'Banan 🍌',
			exampleTwoCustomAnswersCardText2: 'Jabłko 🍏',
			exampleTwoCustomAnswersCardText3: 'Ziemniak 🥔',
			exampleTwoCustomAnswersCardAnswer1: 'Warzywo',
			exampleTwoCustomAnswersCardAnswer2: 'Owoc',
			threeCustomAnswers: 'Trzy niestandardowe kategorie',
			exampleThreeCustomAnswers: 'Gracz przesunie palcem w prawo, aby wskazać, że "blizzard" dotyczy "snow".',
			exampleThreeCustomAnswersCardText: 'blizzard',
			exampleThreeCustomAnswersCardText2: 'slush',
			exampleThreeCustomAnswersCardText3: 'twister',
			exampleThreeCustomAnswersCardAnswer1: '🌬️ wind',
			exampleThreeCustomAnswersCardAnswer2: '🌨️ snow',
			exampleThreeCustomAnswersCardAnswer3: '🌧️ rain',
			exampleSingleChoice: 'Gracz będzie mógł wybrać tylko jedną odpowiedź.',
			exampleMultipleChoice: 'Gracz będzie mógł wybrać jedną lub więcej odpowiedzi.',
			exampleMultipleChoiceQuestion: 'Które z poniższych są liczbami pierwszymi?',
			fourCustomAnswers: 'Cztery niestandardowe kategorie',
			singleChoice: 'Jednokrotnego wyboru',
			multipleChoice: 'Wielokrotnego wyboru',
			exampleFourCustomAnswers: 'Gracz przesunie palcem w prawo, aby wskazać, że luty jest w zimie.',
			exampleFourCustomAnswersCardText1: 'Luty',
			exampleFourCustomAnswersCardText2: 'Styczeń',
			exampleFourCustomAnswersCardText3: 'Lipiec',
			exampleFourCustomAnswersCardText4: 'Kwiecień',
			exampleFourCustomAnswersCardAnswer1: '🌻 Lato',
			exampleFourCustomAnswersCardAnswer2: '❄️Zima',
			exampleFourCustomAnswersCardAnswer3: '🌱 Wiosna',
			exampleFourCustomAnswersCardAnswer4: '🍂 Jesień',
			addCardToThisDeck: 'Dodaj karty do tego zestawu. Wypełnij przód i tył',
			optional: 'opcjonalnie',
			cardFront: 'Przód:',
			cardBack: 'Tył:',
			cardBackPlaceholder: 'Możesz dodać wskazówki lub wyjaśnienia na odwrocie karty',
			addCards: 'Dodaj karty',
			pleaseProvideContentOfTheFrontCard: 'Proszę wprowadź treść karty.',
			chooseTheCorrectAnswer: 'Wybierz poprawną opowiedź',
			chooseAtLeastOneCorrectAnswer: 'Wybierz przynajmniej jedną poprawną opowiedź',
			addAtLeastTwoAnswers: 'Dodaj przynajmniej dwie odpowiedzi',
			selectTheAnswerForThisCard: 'Wybierz kategorię dla tej karty:',
			addCard: 'Dodaj kartę',
			addCategory: 'Dodaj kategorię',
			add: 'Dodaj',
			answerText: 'Tekst kategorii',
			answerOne: 'Kategoria pierwsza',
			answerTwo: 'Kategoria druga',
			answerThree: 'Kategoria trzecia',
			answerFour: 'Kategoria czwarta',
			cardsWillGoHere: 'Karty trafią tutaj, dodasz je w następnym kroku.',
			youNeedAtLeastTwoCategories: 'Potrzebujesz co najmniej dwóch kategorii dla swojej talii.',
			youHaveUnsavedChangesInThisStep: 'Masz niezapisane zmiany w tym kroku.',
			importMultipleCards: 'Importuj karty',
			youHaveNCardsInThisDeck: 'Nie masz kart w tym zestawie | Masz jedną kartę w tym zestawie | Masz {count} karty w tym zestawie | Masz {count} kart w tym zestawie',
			youHaveNQuestionsInThisDeck: 'Nie masz pytań w tym zestawie | Masz jedno pytanie w tym zestawie | Masz {count} pytania w tym zestawie | Masz {count} pytań w tym zestawie',
			howTo: 'W jaki sposób',
			importMultipleHowToOne: 'Przód i tył muszą być oddzielone tabulatorem lub średnikiem',
			importMultipleHowToTwo: 'Tył jest po prawej stronie',
			importMultipleHowToThree: 'Każda linia przedstawia jedną kartę',
			separator: 'Separator',
			tab: 'Tabulator',
			semicolon: 'Średnik',
			cardsWithoutCustomAnswer: 'Karty bez odpowiedzi:',
			flipCardsTo: 'Odwróć karty w:',
			front: 'przód',
			back: 'tył',
			backUppercase: 'Tył',
			hideAnswers: 'Ukryj odpowiedzi',
			showAnswers: 'Pokaż odpowiedzi',
			editCard: 'Edytuj kartę',
			editCategory: 'Edytuj kategorię',
			displayName: 'Nazwa',
			cancel: 'Anuluj',
			save: 'Zapisz',
			saved: 'Zapisano',
			saving: 'Zapisywanie...',
			thereAreNoAnswersForThisDeck: 'Nie ma kategorii dla tego zestawu. Czy zapomniałeś dodać je w poprzednim kroku?',
			pleaseAddAndSelectAnAnswer: 'Dodaj i wybierz kategorię dla karty.',
			addFirstCategoryInfo: 'Dodaj tutaj pierwszą kategorię dla zestawu',
			pleaseSelectAnAnswer: 'Wybierz kategorię dla karty.',
			answerCantBeEmpty: 'Kategoria nie może być pusta.',
			themeImage: 'Ustaw obrazek zestawu',
			openGallery: 'Ustaw obrazek zestawu',
			selectThemeForYourDeck: 'Ustaw obrazek dla swojego zestawu',
			searchForImages: 'Wyszukaj obrazy',
			somethingWentWrong: 'Coś poszło nie tak, spróbuj ponownie.',
			seeMore: 'zobacz więcej',
			setImage: 'Ustaw obrazek',
			setThemeImage: 'Ustaw obrazek zestawu',
			shortDeckDescription: 'Krótki opis zestawu',
			shortDescriptionOfADeck: 'Krótki opis zestawu',
			charactersLeft: 'Pozostałe znaki',
			preview: 'Podgląd',
			no: 'Nie',
			yes: 'Tak',
			finishModalHeader: 'Nieźle! Teraz możesz udostępnić albo załączyć swój zestaw! 😎',
			yourDeckIsPrivate: 'Twój zestaw jest prywatny, tylko Ty masz do niego dostęp.',
			yourDeckIsPublic: 'Twój zestaw jest publiczny.',
			makeItPublicToEmbedOrShare: 'Upublicznij go, aby móc udostępniać go innym lub osadzić na swojej stronie.',
			makeItPublicToShare: 'Upublicznij go, aby móc udostępniać go innym.',
			nowYouCanEmbedOrShare: 'Teraz możesz podzielić się nim z innymi lub osadzić na swojej stronie.',
			makeItPublicToEmbed: 'Upublicznij go, aby móc umieścić go na swojej stronie.',
			nowYouCanEmbed: 'Teraz możesz umieścić go na swojej stronie.',
		},
		deckGenerate: {
			generateCardsWithAI: 'Generuj Karty z AI',
			generateQuestionsWithAI: 'Generuj Pytania z AI',
			createCardsWithEase: 'Twórz fiszki z łatwością',
			createQuizWithEase: 'Twórz quiz z łatwością',
			bannerText: 'Wypróbuj nasze nowe narzędzie do tworzenia fiszek. Wystarczy, że napiszesz, czego potrzebujesz, a nasz generator zasilany AI stworzy dla Ciebie fiszki w ciągu kilku sekund.',
			bannerCTA: 'Wypróbuj generator',
			letsCreateSomeMagicFlashcards: 'Stwórzmy magiczne fiszki!',
			lookingForInspirations: 'Szukasz inspiracji? Zobacz przykłady.',
			tryExamples: 'Zobacz przykłady',
			hideOptionalFields: 'Ukryj pola opcjonalne',
			showOptionalFields: 'Pokaż pola opcjonalne',
			recommended: 'zalecane',
			startWithInspiration: 'Zacznij od przykładowych inspiracji',
			useExampleValues: 'Użyj przykładowych wartości',
			weWillCreateADeckForYou: 'Stworzymy dla Ciebie talie! Wystarczy, że podasz nam kilka szczegółów, a wyniki zobaczysz w ciągu kilku sekund.',
			describeWhatYouWouldLikeToCreate: 'Opisz, co chcesz stworzyć',
			chooseFlashcardsOrQuizType: 'Wybierz rodzaj fiszek lub quizu',
			playDeck: 'Graj',
			howManyCardsWouldYouLikeToGenerate: 'Ile kart chcesz wygenerować?',
			whatIsTheFieldOfStudy: 'Jakia jest dziedzina wiedzy?',
			fieldOfStudy: 'Obszar studiów',
			whatIsTheLevel: 'Jaki jest poziom?',
			clue: 'Wskazówka',
			whatIsTheDeckAbout: 'O czym jest ta talia?',
			describeTheFlashcards: 'Opisz fiszki, ktore chcesz stworzyć.',
			describeTheQuiz: 'Opisz quiz, który chcesz stworzyć.',
			englishGrammarAdverbsOfFrequency: 'Angielska gramatyka - przysłówki częstotliwości',
			whatTypeOfContentFront: 'Jaki rodzaj treści powinien być na przedniej stronie karty?',
			question: 'Pytanie',
			whatTypeOfContentBack: 'Jaki rodzaj treści powinien być na tylnej stronie karty?',
			whatCustomCategories: 'Jakie dodatkowe kategorie powinna mieć talia?',
			placeholderFruitsVegetables: 'Owoce, Warzywa',
			generateFlashcards: 'Generuj fiszki',
			generatedCards: 'Wygenerowane fiszki',
			youCanModifyRemoveOrGenerateMore: 'Możesz modyfikować, usuwać lub generować więcej kart przed zapisaniem ich do talii.',
			generateMoreCards: 'Generuj więcej kart',
			generateMore: 'Generuj więcej',
			more: 'więcej',
			discardExistingCardsAndRefine: 'Odrzuć istniejące karty i ulepsz',
			saveCards: 'Zapisz karty',
			save: 'Zapisz',
			howWouldYouRate: 'Jak oceniasz jakość wyników wygenerowanych przez narzędzie?',
			thankYouForYourRating: 'Świetnie! Dziękujemy za Twoją ocenę. Czy możesz podać jakieś konkretne opinie lub uwagi? Cenimy Twój wkład, pomoże nam to ulepszyć nasze narzędzie.',
			nCardsHadBeenAdded: 'Do twojej talii nie dodano kart | Do twojej talii dodano 1 kartę | Do twojej talii dodano {count} karty | Do twojej talii dodano {count} kart',
			somethingWentWrong: 'Coś poszło nie tak podczas generowania fiszek :( Spróbuj ponownie.',
			aiIsWorkingHard: 'Nasza sztuczna inteligencja pracuje intensywnie, generując twoje fiszki.',
			thisMayTakeUpTo: 'To może zająć do 15 sekund!',
		},
		deckEmbed: {
			embedDeck: 'Osadź fiszki',
			size: 'Rozmiar',
			showCode: 'Pokaż kod',
			copy: 'Kopiuj',
			copied: 'Skopiowano.',
			normal: 'Normalny',
			tall: 'Wysoki',
		},
		stepsButtons: {
			previousStep: 'Wstecz',
			nextStep: 'Dalej',
			finish: 'Zakończ',
			back: 'Wstecz',
			next: 'Dalej',
			done: 'Zakończ',
		},
		userInfo: {
			almostThere: 'To już ostatni krok!',
			finishCreatingYourAccount: 'Dokończ tworzenie konta, aby w pełni korzystać z Multideckera.',
			yourFullName: 'Twoja nazwa',
			yourEmail: 'Twój email',
			weWantToTailorYourExperience: 'Chcemy dostosować Twoje doświadczenie do Twoich potrzeb.',
			howWouldYouDescribeYourself: 'Jak byś siebie opisał/a?',
			iAmATeacher: '👩‍🏫  Jestem nauczycielem/ką',
			iAmAStudent: '👨🏽‍🎓  Jestem studentem/ką',
			selectTopics: 'Wybierz tematy, które są dla Ciebie najważniejsze.',
			continue: 'Kontynuuj',
			english: 'Angielski',
			foreign_languages: 'Języki obce',
			geography: 'Gegrafia',
			mathematics: 'Matematyka',
			biology: 'Biologia',
			entertainment: 'Rozrywka',
			technology: 'Technologia',
			business: 'Biznes',
			science: 'Nauka',
			law: 'Prawo',
			'art_&_culture': 'Sztuka i Kultura',
			sport: 'Sport',
			history: 'Historia',
			others: 'Inne',
		},
		satisfactionFeedback: {
			doYouLikeMultidecker: 'Czy lubisz multidecker?',
			love: 'kocham',
			like: 'lubię',
			neutral: 'nautralny',
			dislike: 'nie lubię',
			hate: 'nienawidzę',
			loveIt: 'kocham to',
			likeIt: 'lubię to',
			dislikeIt: 'nie lubię tego',
			hateIt: 'nienawidzę tego',
			greatPleaseTakeAMoment: 'Świetnie! 😀 Prosimy poświęć chwilę i polub nas na facebooku!',
			thankYouForYourFeedback: 'Dziękujemy za Twoją opinię, dużo dla nas znaczy! 🙏',
			weAreConstantlyTrying: 'Nieustannie staramy się ulepszać multidecker. Proszę, pomóż nam <a href="https://www.surveymonkey.co.uk/r/MD9JHGC" target="_blank">wypełniając formularz</a>.',
			tellUsWhatDoYouThink: 'Powiedz nam, co myślisz o tej stronie',
			whatDoYouThinkOfThis: 'Co o tym sądzisz?',
			whatKindOfFeedback: 'Jaką opinię zgłaszasz?',
			iHaveA: 'Zgłaszam ...',
			bugOrError: 'Błąd',
			complaintOrQuestion: 'Skargę lub pytanie',
			suggestion: 'Sugestię',
			compliment: 'Komplement',
			submit: 'Wyślij',
			maybeLater: 'Może później',
			sorryWhatWentWrong: 'Przepraszamy! Co poszło nie tak?',
			whatCouldBeBetter: 'Co mogłoby być lepiej?',
			whatWouldYouLikeToShare: 'Czym chcesz się z nami podzielić?',
			whatIsWorkingWell: 'Co działa dobrze?',
			thankYouForFeedback: 'Dziękujemy za twoją opinię! 😀',
		},
		surveyInvitation: {
			pennyForYourThoughts: '💰 Grosik za twoje myśli?',
			popoverText1: 'Zwracamy się do kilku naszych najnowszych użytkowników, aby lepiej zrozumieć Twoje wrażenia z Multidecker.',
			popoverText2: '<strong>W ramach podziękowań otrzymasz od nas bon Allegro o wartości 50 zł.</strong>',
			sureWhyNot: 'Jasne, czemu nie?',
			dialogLine1: 'Czy chciałbyś poświęcić 45 minut na pogawędkę w tym lub przyszłym tygodniu?',
			dialogLine2: 'Rozmowa odbędzie się z naszym badaczem użyteczności, Arturem.',
			listItem1: 'Nie musisz niczego przygotowywać ani przynosić.',
			listItem2: 'To nie jest rozmowa sprzedażowa.',
			listItem3: 'Nie będzie pytań podchwytliwych.',
			listItem4: '<strong>Zaraz po rozmowie wyślemy Ci bon Allegro o wartości 50 zł.</strong>',
			dialogLine3: 'W kalendarzu Artura dostępnych jest kilka terminów, które możesz zarezerwować bezpośrednio.',
			dialogLine4: 'Jeśli żadna z tych opcji nie działa, <a href="mailto:adelura@gmail.com" target="_blank">napisz nam maila</a>, podając swoją dostępność, a my coś wymyślimy.',
			dialogLine5: 'Z góry dziękujemy. Każda opinia jest dla nas bezcenna. 😊',
			selectDateAndTime: 'Wybierz datę',
		},
		userProfile: {
			user: 'użytkownik',
			memberSince: 'Użytkownik od',
			thisUserDoesNotHaveAnyDeck: 'Ten użytkownik nie ma jeszcze żadnych zestawów',
		},
	},
};

exports.messages = messages;
