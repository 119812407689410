<template>
	<TextColorsRenderer
		:text="tagName"
	>
		<template v-slot="{ foreground, background }">
			<RouterLink
				:to="{
					name: 'tag',
					params: { id: tagName },
				}"
			>
				<strong
					class="tag"
					:class="{ 'tag--highlighted': highlighted }"
					:style="{
						'background': background,
						'color': foreground,
					}"
				>
					<span
						v-if="isRecommended"
						class="tag__icon"
					>⭐</span>
					<span
						class="tag__text"
					>{{ tagName }}</span>
				</strong>
			</RouterLink>
		</template>
		,
	</TextColorsRenderer>
</template>

<script>

import TextColorsRenderer from '@/components/TextColorsRenderer.vue';

const recommended = new Set([
	'grammar',
	'verbs',
	'vocabulary',
	'irregular verbs',
	'articles',
	'definite articles',
	'indefinite articles',
	'quantifiers',
	'tenses',
	'collocations',
	'prepositions',
]);

export default {
	name: 'Tag',
	components: {
		TextColorsRenderer,
	},
	props: {
		tagName: {
			type: String,
			req: true,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isRecommended() {
			return recommended.has(this.tagName);
		},
	},
};
</script>

<style scoped>
.tag {
	padding: var(--spacing-4) var(--spacing-8);
	border-radius: var(--spacing-64);
	position: relative;
	overflow: hidden;
}

.tag:hover::after {
	border-radius: var(--spacing-64);
	display: block;
	content: '';
	background: rgba(0, 0, 0, 0.05);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.tag__text {
	opacity: 0.7;
	z-index: 1;
	position: relative;
}

.tag__icon {
	filter: drop-shadow(0 0 2px rgba(0, 0, 0, .3));
}

.tag--highlighted .tag__text {
	text-decoration: underline;
}
</style>
