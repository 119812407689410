<template>
	<div>
		<PopOver
			:show="isPopoverOpen"
			position="bottom-right"
		>
			<div class="c-popover-body--one">
				<span class="c-popover-body__close-btn-wrapper c-popover-body__close-btn-wrapper--floating">
					<button
						type="button"
						class="c-popover-body__close-btn"
						@click="dismissPopover"
					>
						<i class="ui close icon c-popover-body__close-btn-icon" />
					</button>
				</span>
				<div style="display: flex; flex-direction: column;">
					<p class="ui medium header">
						{{ $t('surveyInvitation.pennyForYourThoughts') }}
					</p>
					<p v-html="$t('surveyInvitation.popoverText1')" />
					<p v-html="$t('surveyInvitation.popoverText2')" />
					<button
						style="align-self: end;"
						type="button"
						class="c-btn c-btn--cta"
						@click="onPopoverAccepted"
					>
						{{ $t('surveyInvitation.sureWhyNot') }}
					</button>
				</div>
			</div>
		</PopOver>
		<SuiModal
			v-model="isModalOpened"
			size="tiny"
		>
			<i
				class="close icon"
				@click="isModalOpened = false"
			/>
			<SuiModalContent scrolling>
				<p class="ui large header">
					{{ $t('surveyInvitation.pennyForYourThoughts') }}
				</p>
				<TransitionExpand>
					<div v-show="step === 0">
						<p v-html="$t('surveyInvitation.dialogLine1')" />
						<p v-html="$t('surveyInvitation.dialogLine2')" />
						<ul>
							<li v-html="$t('surveyInvitation.listItem1')" />
							<li v-html="$t('surveyInvitation.listItem2')" />
							<li v-html="$t('surveyInvitation.listItem3')" />
							<li v-html="$t('surveyInvitation.listItem4')" />
						</ul>
						<p v-html="$t('surveyInvitation.dialogLine3', { calendarLink })" />
						<p v-html="$t('surveyInvitation.dialogLine4')" />
						<p v-html="$t('surveyInvitation.dialogLine5')" />
						<div style="display: flex; flex-direction: column;">
							<button
								style="align-self: end;"
								type="button"
								class="c-btn c-btn--cta"
								@click="selectMeeting"
							>
								{{ $t('surveyInvitation.selectDateAndTime') }}
							</button>
						</div>
					</div>
				</TransitionExpand>
				<TransitionExpand>
					<div v-show="step === 1">
						<iframe
							style="border-radius: var(--spacing-12)"
							:src="calendarLink"
							width="100%"
							height="600px"
							frameborder="0"
						/>
					</div>
				</TransitionExpand>
			</SuiModalContent>
		</SuiModal>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import { daysBetween } from '@/assets/js/utils/dateTime';
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
	import PopOver from '@/components/PopOver';
	import TransitionExpand from '@/components/TransitionExpand';

	const CALENDAR_LINK = 'https://koalendar.com/e/ux-multidecker';

	export default {
		name: 'SurveyInvitationPopover',
		components: { TransitionExpand, PopOver },
		data() {
			return {
				isPopoverOpen: false,
				isModalOpened: false,
				step: 0,
			};
		},
		computed: {
			...mapState(['user']),
			calendarLink() {
				return `${CALENDAR_LINK}?embed=true&name=${this.user.displayName}&email=${this.user.email}`;
			},
			isEligible() {
				this.$route.name;
				this.$store.getters.userId;
				this.$store.state.user;
				this.$i18n.locale;
				this.$store.getters.userLists;
				this.$store.getters.localStorage.surveyInvitationPopoverLastTimeDisplayed;

				if (!this.$store.getters.isLoggedIn) {
					return [false, 'Fail: Is not logged in'];
				}

				if (this.$route.name === 'deckCreate') {
					return [false, 'Fail: Is on the "deckCreate" page'];
				}

				if (this.$store.getters.userId === '5820e7b8a70ef76112a74bc8') {
					return [false, 'Fail: Is user id'];
				}

				const { metadata } = this.$store.state.user;
				if (metadata && metadata.type === 'student') {
					return [false, 'Fail: Is student'];
				}

				if (this.$i18n.locale !== 'pl') {
					return [false, 'Fail: Locale is not pl'];
				}

				const decks = this.$store.getters.userLists || [];
				if (decks.length === 0) {
					return [false, 'Fail: Did not create any decks'];
				}

				const surveyInvitationPopoverLastTimeDisplayed = this.$store.getters.localStorage.surveyInvitationPopoverLastTimeDisplayed;
				if (!surveyInvitationPopoverLastTimeDisplayed) {
					return [true, 'Pass: Never saw a popover'];
				}

				const daysSinceModalDisplayed = daysBetween(Date.now(), surveyInvitationPopoverLastTimeDisplayed);

				const sawPopoverMoreThan3DaysAgo = daysSinceModalDisplayed > 3;

				if (sawPopoverMoreThan3DaysAgo) {
					return [true, 'Pass: Saw popover more than three days ago'];
				}

				return [false, 'Fail: Saw popover less than three days ago'];
			},
		},
		watch: {
			isPopoverOpen(newVal) {
				if (newVal === true) {
					logAnalyticsEvent(...events.surveyInvitationPopoverDisplay);
					this.$store.dispatch('setLocalStorage', { key: 'surveyInvitationPopoverLastTimeDisplayed', value: Date.now() });
				}
			},
			isEligible(newVal, oldVal) {
				const [eligible] = newVal;
				const [wasEligible] = oldVal;
				if (eligible === true && wasEligible === false) {
					setTimeout(() => {
						this.isPopoverOpen = true;
					}, 1000);
				}
			},
		},
		async mounted() {
			await this.$store.dispatch('fetchUserLists');
			const [eligible, reason] = this.isEligible;

			logAnalyticsEvent(
				...events.surveyInvitationPopoverEvaluateEligibility,
				reason,
			);

			if (eligible) {
				setTimeout(() => {
					this.isPopoverOpen = true;
				}, 1000);
			}
		},
		methods: {
			dismissPopover() {
				this.isPopoverOpen = false;
				logAnalyticsEvent(
					...events.surveyInvitationPopoverDismiss,
				);
			},
			onPopoverAccepted() {
				this.isPopoverOpen = false;
				this.isModalOpened = true;
				logAnalyticsEvent(
					...events.surveyInvitationPopoverAccept,
				);
			},
			selectMeeting() {
				this.step = 1;
				logAnalyticsEvent(
					...events.surveyInvitationPopoverSelectMeeting,
				);
			},
		},
	};
</script>
<style scoped>
	p, ol, ul, li {
		font-size: 16px;
	}
</style>
