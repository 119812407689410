const PRODUCTION = {
	INDEFINITE_ARTICLES: '5efc9a59739ccf03fda9a717',
};

const STAGING = {
	INDEFINITE_ARTICLES: '61633e1c0cda300edc4510c8',
};

export const decksOptInSuggestionFeature = new Set([
	STAGING.INDEFINITE_ARTICLES,
	PRODUCTION.INDEFINITE_ARTICLES,
]);
