/* globals __ENV__ */
import Vue from 'vue';
import VueRouter from 'vue-router';
import * as Sentry from '@sentry/vue';
import Vuex from 'vuex';
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import veeValidateLocalePL from 'vee-validate/dist/locale/pl';
import veeValidateLocaleEN from 'vee-validate/dist/locale/en';
import * as modal from 'semantic-ui-vue/dist/commonjs/modules/Modal';
import VueLazyload from 'vue-lazyload';

import * as checkbox from 'semantic-ui-vue/dist/commonjs/modules/Checkbox';

import VueI18n from 'vue-i18n';
import axios from 'axios';
import { featureToggles } from '@/store/featureToggles';
import store from '@/store/listsStorage';
import ClickedOutside from '@/assets/js/directives/ClickedOutside';
import Focus from '@/assets/js/directives/Focus';
import { messages } from '@/assets/js/i18n/translations';
import App from '@/assets/js/App.vue';

// Does this wrongly assume that dashboard is always first page to load?
import Yours from '@/pages/dashboard/yours.vue';
import Others from '@/pages/dashboard/others.vue';
import Favourites from '@/pages/dashboard/favourites.vue';
import Search from '@/pages/dashboard/search.vue';
import RecentlyPlayed from '@/pages/dashboard/recently-played.vue';
import MostFavourite from '@/pages/dashboard/most-favourite.vue';
import DeckResultList from '@/pages/list/_id/play/results.vue';
import { setTitle } from '@/assets/js/utils/meta';
import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
import { initExperiments } from '@/assets/js/utils/experiments';
import { initListeners } from '@/store/matchMedia/utils';
import { seoToDeckId } from '@/assets/js/dataServices/utils';
import { decksOptInSuggestionFeature } from '@/components/deckPlay/utils';

Object.values(modal).forEach(Comp => Vue.component(Comp.name, Comp));
Object.values(checkbox).forEach(Comp => Vue.component(Comp.name, Comp));

Vue.use(ClickedOutside);
Vue.use(Focus);

Vue.use(Vuex);
Vue.use(VueRouter);

Vue.use(VeeValidate, {
	dictionary: {
		en: veeValidateLocaleEN,
		pl: veeValidateLocalePL,
	},
});
Vue.use(VueI18n);
Vue.use(VueLazyload);

Vue.config.productionTip = false;
Vue.config.errorHandler = (err) => {
	logAnalyticsEvent(...events.errorThrown, `${window.location.href} ${err.stack.toString()}`);
};

if (['production', 'staging'].includes(__ENV__)) {
	Sentry.init({
		Vue,
		dsn: 'https://d8b8a4f5abf65f2e63c37e851312ec9e@o4506445438058496.ingest.sentry.io/4506445440548864',
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [
					'production', /^https:\/\/multidecker\.com/,
				],
			}),
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
	});
}

let i18n;

const NotFound404 = () => import(/* webpackChunkName: "notFound404" */ '../../pages/not-found-404');
const NotAuthorized401 = () => import(/* webpackChunkName: "notAuthorized401" */ '../../pages/not-authorized-401');
const Showcase = () => import(/* webpackChunkName: "showcase" */ '../../pages/index');
const ForTeachers = () => import(/* webpackChunkName: "forTeachers" */ '../../pages/for-teachers');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../../pages/dashboard/index');
const DeckDetails = () => import(/* webpackChunkName: "deckDetails" */ '../../pages/list/_id/index');
const DecksWithTags = () => import(/* webpackChunkName: "decksWithTags" */ '../../pages/tag/_id/index');
const CourseDetails = () => import(/* webpackChunkName: "courseDetails" */ '../../pages/course/_id/index');
const DeckPlay = () => import(/* webpackChunkName: "deckPlay" */ '../../pages/list/_id/play/index');
const DeckCreate = () => import(/* webpackChunkName: "deckEdit" */ '../../pages/list/_id/create');
const UserProfile = () => import(/* webpackChunkName: "userProfile" */ '../../pages/user/_id/');
const AdminStats = () => import(/* webpackChunkName: "adminStats" */ '../../pages/admin-stats');
const TagsList = () => import(/* webpackChunkName: "tagsList" */ '../../pages/tags-list.vue');

const AdminEmailScheduler = () => import(/* webpackChunkName: "adminEmailScheduler" */ '../../pages/admin/email-scheduler');

const AdminConversations = () => import(/* webpackChunkName: "adminConversations" */ '../../pages/admin/conversations');
const DragAndDrop = () => import(/* webpackChunkName: "dragAndDrop" */ '../../pages/drag-and-drop');
const Features = () => import(/* webpackChunkName: "features" */ '../../pages/features');
const PrivacyPolicy = () => import(/* webpackChunkName: "privacyPolicy" */ '../../pages/privacy-policy');
const DataDeletion = () => import(/* webpackChunkName: "dataDeletion" */ '../../pages/data-deletion');
const FlashcardsAIGenerator = () => import(/* webpackChunkName: "flashcardsAIGenerator" */ '../../pages/flashcards-ai-generator');

const Unsubscribe = () => import(/* webpackChunkName: "unsubscribe" */ '../../pages/unsubscribe');

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/service-worker.js').then((registration) => {
			console.log('SW registered: ', registration);
		}).catch((registrationError) => {
			console.log('SW registration failed: ', registrationError);
		});
	});
}

// import '../main.styl';

// @TODO: Rename `allowedForNonUser` to `requiresAuth` and flip logic.
const router = new VueRouter({
	mode: 'history',
	linkExactActiveClass: 'active',
	routes: [
		{
			name: 'notFound404',
			path: '/404-not-found',
			component: NotFound404,
			meta: {
				full: true,
				headerModifiers: [
					'--with-secondary',
				],
			},
		},
		{
			name: 'notAuthorized401',
			path: '/401-not-authorized',
			component: NotAuthorized401,
			meta: {
				full: true,
				headerModifiers: [
					'--with-secondary',
				],
			},
		},
		{
			name: 'showcase',
			path: '/',
			component: Showcase,
			meta: {
				redirectToWhenUser: '/dashboard',
				showHeader: true,
				showSideMenu: false,
				headerModifiers: [
					'--with-secondary',
					'--landing',
				],
			},
		},
		{
			name: 'forTeachers',
			path: '/for-teachers',
			component: ForTeachers,
			meta: {
				showHeader: true,
				showSideMenu: false,
				headerModifiers: [
					'--with-secondary',
					'--landing',
				],
			},
		},
		{
			name: 'unsubscribe',
			path: '/unsubscribe',
			component: Unsubscribe,
			meta: {
				allowedForNonUser: true,
				full: true,
				headerModifiers: [
					'--with-secondary',
				],
			},
		},
		{
			name: 'dashboard',
			path: '/dashboard',
			component: Dashboard,
			meta: {
				allowedForNonUser: true,
				headerModifiers: [
					'--with-secondary',
				],
			},
			children: [
				{
					name: 'yours',
					path: 'yours',
					component: Yours,
					meta: {
						allowedForNonUser: false,
					},
				},
				{
					name: 'others',
					path: 'others',
					component: Others,
					meta: {
						allowedForNonUser: false,
					},
				},
				{
					name: 'favourites',
					path: 'favourites',
					component: Favourites,
					meta: {
						allowedForNonUser: false,
					},
				},
				{
					name: 'search',
					path: 'search',
					component: Search,
				},
				{
					name: 'tag',
					path: 'tag/:id',
					component: DecksWithTags,
				},
				{
					name: 'recentlyPlayed',
					path: 'recently-played',
					component: RecentlyPlayed,
					meta: {
						allowedForNonUser: false,
					},
				},
				{
					name: 'mostFavourite',
					path: 'most-favourite',
					component: MostFavourite,
					meta: {
						title: 'mostPopularFlashcardsTitle',
						allowedForNonUser: true,
						headerModifiers: [
							'--with-secondary',
						],
					},
				},
			],
		},
		{
			name: 'courseDetails',
			path: '/course/:id',
			component: CourseDetails,
			meta: {
				hasDynamicMeta: true,
				allowedForNonUser: true,
			},
			props: true,
		},
		{
			name: 'listDetails',
			path: '/list/:id',
			component: DeckDetails,
			meta: {
				hasDynamicMeta: true,
				allowedForNonUser: true,
				headerModifiers: [
					'--with-secondary',
				],
			},
			props: true,
		},
		{
			name: 'listPlay',
			path: '/list/:id/play',
			component: DeckPlay,
			meta: {
				hasDynamicMeta: true,
				unselectable: true,
				allowedForNonUser: true,
				showFooter: false,
			},
			children: [
				{
					name: 'results',
					path: 'results',
					component: DeckResultList,
					meta: {
						hasDynamicMeta: true,
						showFooter: false,
					},
				},
			],
		},
		{
			name: 'deckEdit',
			path: '/list/:id/edit',
			component: DeckCreate,
			meta: {
				allowedForNonUser: false,
				showFooter: false,
			},
		},
		{
			name: 'deckCreate',
			path: '/list/:id/create',
			component: DeckCreate,
			meta: {
				allowedForNonUser: false,
				showFooter: false,
			},
		},
		{
			name: 'deckGenerate',
			path: '/deck/generate',
			component: DeckCreate,
			meta: {
				allowedForNonUser: true,
				showFooter: false,
			},
		},
		{
			name: 'userProfile',
			path: '/user/:id',
			component: UserProfile,
			meta: {
				allowedForNonUser: true,
				showFooter: true,
			},
		},
		{
			name: 'privacyPolicy',
			path: '/privacy-policy',
			component: PrivacyPolicy,
			meta: {
				title: 'privacyPolicyTitle',
			},
		},
		{
			name: 'dataDeletion',
			path: '/data-deletion',
			component: DataDeletion,
			meta: {
				title: 'dataDeletionTitle',
			},
		},
		{
			name: 'tagsList',
			path: '/tags',
			component: TagsList,
		},
		{
			name: 'adminStats',
			path: '/admin-stats',
			component: AdminStats,
			meta: {
				allowedForNonUser: false,
			},
		},
		{
			name: 'adminEmailScheduler',
			path: '/admin/email-scheduler',
			component: AdminEmailScheduler,
			meta: {
				allowedForNonUser: false,
			},
		},
		{
			name: 'adminConversations',
			path: '/admin/conversations',
			component: AdminConversations,
			meta: {
				allowedForNonUser: false,
			},
		},
		{
			name: 'dragAndDrop',
			path: '/d&d',
			component: DragAndDrop,
			meta: {
				allowedForNonUser: false,
			},
		},
		{
			name: 'features',
			path: '/features',
			component: Features,
		},
		{
			name: 'britishAmerican',
			path: '/flashcards-ai-generator',
			component: FlashcardsAIGenerator,
			meta: {
				title: 'flashcardsAIGenerator',
				headerModifiers: [
					'--no-background',
					'--zero-height',
				],
			},
		},
		{
			path: '*',
			meta: {
				full: true,
			},
			component: NotFound404,
		},
	],
	scrollBehavior(to, _, savedPosition) {
		const routesWithScrollReset = [
			'listDetails',
			'deckCreate',
			'deckEdit',
			'adminStats',
			'privacyPolicy',
			'listPlay',
			'userProfile',
		];

		if (routesWithScrollReset.includes(to.name)) {
			return { x: 0, y: 0 };
		}

		if (savedPosition) {
			return savedPosition;
		}

		return false;
	},
});

router.beforeEach((to, from, next) => {
	const { isLoggedIn } = store.getters;
	const { redirectToWhenUser } = to.meta;
	const allowedForNonUser = !to.matched.some(record => record.meta.allowedForNonUser === false);

	if (to.query && to.query.embedded === 'true') {
		store.dispatch('embedded');
	}

	if (to.query && to.query.configuration) {
		store.dispatch('configuration', to.query.configuration);
	}

	const { theme = {} } = store.getters.configuration;

	for (let key in theme) {
		document.body.style.setProperty(key, theme[key]);
	}

	let redirectUrl;
	if (allowedForNonUser === false && !isLoggedIn) {
		redirectUrl = '/dashboard';
	} else if (redirectToWhenUser && isLoggedIn) {
		redirectUrl = redirectToWhenUser;
	}

	next(redirectUrl);
});

router.beforeEach((to, from, next) => {
	if (to.name === 'results' && from.name === null) {
		next(to.path.replace('/results', ''));
	} else if (to.name === 'results' && Object.keys(to.params).length === 1) {
		next(to.path.replace('/play/results', ''));
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.name === 'listPlay') {
		const deckId = seoToDeckId(to.params.id);
		const hasSuggestions = decksOptInSuggestionFeature.has(deckId);
		const isFeatureEnabled = store.getters.feature('nextDeckSuggestion');

		if (hasSuggestions && !isFeatureEnabled) {
			store.dispatch('setLocalStorage', {
				key: 'nextDeckSuggestion',
				value: true,
			});
		}
	}

	next();
});

router.beforeEach((to, from, next) => {
	store.dispatch('loadingRoute', { loading: true });
	next();
});

router.afterEach(() => {
	store.dispatch('loadingRoute', { loading: false });
	store.dispatch('hideOffsetMenu');

	setTimeout(async () => {
		await Promise.all([
			Dashboard(),
			DeckDetails(),
			DeckPlay(),
		]);
		await Promise.all([
			DeckCreate(),
		]);
	}, 2000);
});

// Source: https://alligator.io/vuejs/vue-router-modify-head/
router.beforeEach((to, from, next) => {
	if (to.meta && to.meta.hasDynamicMeta) {
		next();
		return;
	}

	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

	// If a route with a title was found, set the document (page) title to that value.
	setTitle(nearestWithTitle ? i18n.t(nearestWithTitle.meta.title) : i18n.t('title'));

	next();
});

router.afterEach((to) => {
	if (window.gtag) {
		gtag('event', 'page_view', {
			page_location: location.origin + to.fullPath,
			page_path: to.fullPath,
		});
	}
});

// - [ ] Translate page to polish.
// - [ ] Write some e2e tests.
axios.interceptors.response.use(res => res, (error) => {
	try {
		logAnalyticsEvent(...events.requestErrorThrown, JSON.stringify({
			method: error.config.method,
			reqUrl: error.config.url,
			status: error.response.status,
			location: document.location.href,
			isLoggedIn: store.getters.isLoggedIn,
		}));
		// eslint-disable-next-line no-empty
	} catch (e) {}

	if (error.response.status === 404 && error.config.method === 'get') {
		router.push({
			name: 'notFound404',
			query: {
				location: document.location.href,
			},
		});
	}

	return Promise.reject(error);
});

require('../semantic.less');

// Just to cache the user
(async function main() {
	let locale;
	try {
		const supportedLanguages = Object.keys(messages);
		// First take from localStorage otherwise from navigator
		const language = localStorage.getItem('preferredLanguage') || navigator.language.split('-')[0];

		locale = supportedLanguages.includes(language) ? language : 'en';
	} catch (e) {
		locale = 'en';
	}

	i18n = new VueI18n({
		locale,
		messages,
	});

	Validator.localize(locale);

	const user = await store.dispatch('userResolve');
	const { isLoggedIn } = store.getters;

	// Assign user to guesses for each session.
	if (isLoggedIn && sessionStorage) {
		try {
			const decksPlayed = JSON.parse(sessionStorage.getItem('decksPlayed') || '[]');
			const decksGuesses = decksPlayed.map((deckId) => {
				const guesses = JSON.parse(sessionStorage.getItem(`/lists/${deckId}/guesses`) || '[]');
				return { list: deckId, guesses };
			}).filter(({ guesses }) => guesses.length);

			// Saving guesses from the session storage into the database for each deck.
			window.promises = new Map();
			const all = Promise.all(decksGuesses.map(({ list, guesses }) => {
				const url = `/lists/${list}/guesses`;
				const promise = axios.post(url, {
					guesses,
				}).then(() => {
					sessionStorage.removeItem(url);
				});
				window.promises.set(list, promise);

				return promise;
			}));

			window.promises.set('all', all);

			all.then(() => {
				sessionStorage.removeItem('decksPlayed');
			});
		} catch (e) {
			logAnalyticsEvent(...events.semanticErrorThrown, `Saving guesses on startup failed ${e}`);
		}
	}

	if (isLoggedIn && localStorage) {
		try {
			const aiGeneratorLanding = JSON.parse(localStorage.getItem('aiGeneratorLanding') || '{}');

			if (aiGeneratorLanding.generatedDeck) {
				const { generatedDeck } = aiGeneratorLanding;

				await axios.post('/lists', {
					name: 'Deck generated with AI',
					private: true,
					words: generatedDeck.words,
					categories: generatedDeck.categories,
					categoryType: generatedDeck.categoryType,
				});

				localStorage.removeItem('aiGeneratorLanding');
			}
		} catch (e) {
			logAnalyticsEvent(...events.semanticErrorThrown, `Saving AI generate deck failed ${e}`);
			console.error(e);
		}
	}

	if (window.gtag && isLoggedIn) {
		gtag('set', { user_id: user._id });
	}

	if (window.location.hash == '#_=_') {
		window.location.hash = '';
	}

	const app = new Vue({
		i18n,
		router,
		store,
		beforeCreate() {
			initListeners(this.$store);

			this.$store.commit('initLocalStorage');

			try {
				const urlParams = new URLSearchParams(window.location.search);
				const features = featureToggles.map(({ key, defaultValue }) => (urlParams.has(key) || defaultValue === '1') && {
					key,
					value: urlParams.get(key) === '1' || defaultValue === '1',
				}).filter(Boolean);

				features.forEach((feature) => {
					this.$store.dispatch('setLocalStorage', feature);
				});

				initExperiments(this.$store);
			} catch (err) {
				logAnalyticsEvent(...events.errorThrown, err.stack.toString());
			}
		},
		render: h => h(App),
	});
	app.$mount('#app');
}());
