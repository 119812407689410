import { nameRegExp } from '@/assets/js/utils/veevalidateRegExp';

export function isFormValid(fields) {
	return Object.keys(fields).every(key => fields[key] && fields[key].valid);
}

export function isFormDirty(fields) {
	return Object.keys(fields).some(key => fields[key] && fields[key].dirty);
}

export function isFormValidated(fields) {
	return Object.keys(fields).some(key => fields[key] && fields[key].validated);
}

export function isField(fields, selector, prop) {
	let [scope, field] = selector.split('.');
	scope = fields[`$${scope}`];
	return scope ? scope[field][prop] : false;
}

export const deckNameValidation = {
	rules: {
		required: true,
		regex: nameRegExp,
		max: 80,
	},
	localize: {
		en: {
			attributes: {
				deckName: 'deck name',
			},
			custom: {
				deckName: {
					regex: 'Only alphanumeric characters, space, "_" and "-" are allowed. Maximum length is 80 characters.',
				},
			},
		},
		pl: {
			attributes: {
				deckName: 'nazwa decka',
			},
			custom: {
				deckName: {
					regex: 'Dozwolone są tylko znaki alfanumeryczne, spacja, "_" i "-". Maksymalna długość to 80 znaków.',
				},
			},
		},
	},
};

export const userDisplayNameValidation = {
	rules: {
		required: true,
		regex: nameRegExp,
		max: 50,
	},
	localize: {
		en: {
			attributes: {
				displayName: 'name',
			},
			custom: {
				displayName: {
					regex: 'Only alphanumeric characters, space, "_" and "-" are allowed.',
				},
			},
		},
		pl: {
			attributes: {
				displayName: 'nazwa',
			},
			custom: {
				displayName: {
					regex: 'Dozwolone są tylko znaki alfanumeryczne, spacja, "_" i "-".',
				},
			},
		},
	},
};
