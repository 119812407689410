<template>
	<form
		novalidate
		name="signEmailForm"
		data-vv-scope="signEmailForm"
		class="sign-via-email"
		@submit.prevent="signViaMagicEmail"
	>
		<label
			class="ui header sign-via-email__header"
			for="email-input"
		>
			{{ $t('header.email') }}
		</label>
		<div
			class="ui input"
			:class="{
				error: errors.has('signEmailForm.email') && fields.$signEmailForm.email.touched
			}"
		>
			<input
				id="email-input"
				v-model="emailInputValue"
				v-focus
				v-validate="'required|email'"
				required
				type="email"
				placeholder="sophie@example.com"
				name="email"
			>
		</div>
		<TransitionExpand>
			<div
				v-if="errors.has('signEmailForm.email') && fields.$signEmailForm.email.touched"
				class="sign-via-email__submit-error"
			>
				{{ errors.first('signEmailForm.email') }}
			</div>
		</TransitionExpand>
		<button
			type="submit"
			class="c-btn c-btn--cta sign-via-email__submit-button"
			:class="{ 'c-btn--loading': signViaEmailBusy }"
		>
			<span>{{ $t(isSignIn ? 'header.signIn' : 'header.signUp') }}</span>
		</button>
		<button
			type="button"
			class="sign-via-email__options-button"
			@click="backToSignOptions"
		>
			<i class="ui chevron left icon sign-via-email__options-button-icon" />
			<span class="sign-via-email__options-button-text">
				{{ $t(isSignIn ? 'header.allSignInOptions' : 'header.allSignUpOptions') }}
			</span>
		</button>
	</form>
</template>

<script>
	import axios from 'axios';
	import TransitionExpand from '@/components/TransitionExpand';

	export default {
		name: 'SignViaEmail',
		components: { TransitionExpand },
		props: {
			redirect: {
				type: String,
			},
			newsletter: {
				type: Boolean,
				required: true,
			},
			signType: {
				type: String,
				required: true,
				validator: val => ['sign-up', 'sign-in'].includes(val),
			},
			backToSignOptions: {
				type: Function,
				required: true,
			},
		},
		data: () => ({
			emailInputValue: '',
			signViaEmailBusy: '',
		}),
		computed: {
			isSignIn() {
				return this.signType === 'sign-in';
			},
		},
		methods: {
			async signViaMagicEmail() {
				if (this.errors.has('signEmailForm.email') || this.emailInputValue.length === 0) {
					return;
				}

				const state = {
					intention: this.signType,
					newsletter: this.newsletter,
				};

				if (this.redirect) {
					state.redirect = this.redirect;
				}

				this.signViaEmailBusy = true;
				const res = await axios.post('/auth/login/magic', {
					destination: this.emailInputValue,
					state,
				});

				this.signViaEmailBusy = false;

				this.$emit('sign-magic-response', {
					res,
					destination: this.emailInputValue,
					intention: this.signType,
				});
			},
		},
	};
</script>

<style scoped>
	.sign-via-email {
		display: flex;
		flex-direction: column;
		max-width: 360px;
		margin: 0 auto var(--spacing-16) auto;
	}

	.sign-via-email__header.sign-via-email__header {
		margin: var(--spacing-16) 0 var(--spacing-4) 0;
		text-align: left;
	}

	.sign-via-email__submit-button {
		margin-top: var(--spacing-24);
	}

	.sign-via-email__options-button {
		all: unset;
		align-self: flex-end;
		margin: var(--spacing-8) 0;
	}

	.sign-via-email__options-button-icon {
		margin: 0;
	}

	.sign-via-email__options-button-text {
		font-weight: bold;
	}

	.sign-via-email__options-button:hover,
	.sign-via-email__options-button:active {
		cursor: pointer;
		text-decoration: underline;
	}

	.sign-via-email__submit-error {
		color: var(--red);
		text-align: left;
		margin-top: var(--spacing-8);
	}
</style>
