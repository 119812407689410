<template>
	<div>
		<div v-if="searchAISuggestionsFeature">
			<h2>AI Suggested Flashcards</h2>
			<ul
				class="c-deck-info-cards-grid"
				style="padding: 0;"
			>
				<li
					v-for="(deck, index) in (streamedJSON.decks || [])"
					:key="index"
					class="c-deck-info-card"
				>
					<div class="c-deck-info-card__section">
						<div style="display: flex; justify-content: space-between; flex-direction: column; flex-grow: 1;">
							<span class="c-deck-info-card__title"><strong>{{ deck.title }}</strong></span>
							<div style="display: flex; flex-direction: column;">
								<p>{{ deck.description }}</p>
								<p>{{ deck.fieldOfStudy }}</p>
								<button
									style="align-self: end;"
									type="button"
									class="c-btn c-btn--cta c-btn--small"
								>
									Generate Deck
								</button>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<ul>
				<li
					v-for="suggestion in streamedJSON.suggestions"
					:key="suggestion"
				>
					{{ suggestion }}
				</li>
			</ul>
			<ul>
				<li
					v-for="(value, key) in streamedJSON.dataCollectedSoFar"
					:key="key"
				>
					{{ key }}: {{ value != null ? value : 'null' }}
				</li>
			</ul>
		</div>


		<h3 class="ui header">
			{{ $t('dashboard.searchResultsForQuery') }}: <q><strong>{{ query }}</strong></q>
		</h3>

		<div
			class="ui centered inline loader inverted medium"
			:class="{ active: !this.ready }"
		/>
		<div
			v-if="this.ready"
			v-show="listsBySearch.length === 0"
			class="ui info message"
		>
			<div class="header">
				{{ $t('dashboard.couldNotFindAnyDeck') }}
			</div>
			<p>{{ $t('dashboard.perhapsYouWantCreateOne') }}</p>
		</div>

		<div class="c-deck-info-cards-grid">
			<ListCard
				v-for="list in listsBySearch"
				:key="list._id"
				:list="list"
			/>
		</div>
	</div>
</template>
<script>
	// @TODO: [ ] Deck search doesn't not show deck which are private but created by author - author should be able to find them.
	import ListCard from '@/components/listCard/ListCard';
	import { fetchStream } from '@/assets/js/utils/fetchStream';
	import { mapGetters } from 'vuex';

	export default {
		name: 'Search',
		components: { ListCard },
		data() {
			return {
				ready: false,
				streamedJSON: {},
			};
		},
		computed: {
			...mapGetters(['feature']),
			searchAISuggestionsFeature() {
				return this.feature('searchAISuggestions');
			},
			query() {
				return this.$route.query.query;
			},
			listsBySearch() {
				if (!this.query) {
					return [];
				}

				return this.$store.getters.queryLists(this.query);
			},
		},
		async created() {
			if (this.searchAISuggestionsFeature) {
				const params = new URLSearchParams({
					query: this.query,
					amount: 6,
				});

				await fetchStream({ url: `/generator/create-deck-suggestions?${params}` }, ({ fixedJSON }) => {
					this.streamedJSON = fixedJSON;
				});
			}

			this.ready = false;
			if (!this.$store.getters.queryContainsResults(this.query)) {
				await this.$store.dispatch('searchDecks', this.query);
			}
			this.ready = true;
		},
	};
</script>
