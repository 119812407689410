<template>
	<div class="c-create-account-teaser c-hero inverted-colors">
		<div class="ui basic center aligned segment">
			<div class="h3 c-hero__title c-create-account-teaser__title">
				{{ $t(headerText) }}
			</div>
			<div>
				<button
					type="button"
					class="c-btn c-btn--cta"
					@click="onSignUpClick && onSignUpClick()"
				>
					{{ $t(buttonText) }}
				</button><br>

				<span v-if="showAlreadySignedUpSection">
					{{ $t('header.alreadyHaveAnAccount') }}
					<button
						type="button"
						class="c-btn c-btn--tertiary c-btn--small c-btn--inverted c-create-account-teaser__sign-in-button"
						@click="onSignInClick && onSignInClick()"
					>
						{{ $t('header.signIn') }}
					</button>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CreateAccountTeaser',
		props: {
			onSignInClick: {
				type: Function,
				default: () => () => {},
			},
			onSignUpClick: {
				type: Function,
				default: () => () => {},
			},
			headerText: {
				type: String,
				default: 'trackYourResults',
			},
			buttonText: {
				type: String,
				default: 'header.createAnAccount',
			},
			showAlreadySignedUpSection: {
				type: Boolean,
				default: true,
			},
		},
	};
</script>

<style scoped>
	.c-create-account-teaser.c-create-account-teaser {
		background: var(--app-hero-gradient);
		border-radius: var(--spacing-16);
		margin: var(--spacing-16) auto;
		color: white;
		max-width: 500px;
		padding: var(--spacing-32);
	}

	.c-create-account-teaser__title {
		margin-top: var(--spacing-16);
		margin-bottom: var(--spacing-24);
		line-height: 1.4em;
	}

	.c-create-account-teaser__sign-in-button {
		margin-left: calc(var(--spacing-12) * -1);
	}
</style>
