<template>
	<DecksContainer
		:decks="decks"
		:title="$t('whyDontYouTrySomeMoreDecks')"
		:fetch-decks="fetchDecks"
		:results-amount="resultsAmount"
		:more-link="''"
		style="--decks-container-heading-border-bottom: none"
		@card-clicked="onCardClicked"
	/>
</template>
<script>
	import DecksContainer from '@/components/dashboard/DecksContainer';
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';

	export default {
		name: 'SimilarDecks',
		components: {
			DecksContainer,
		},
		props: {
			resultsAmount: {
				type: Number,
				default: null,
			},
		},
		computed: {
			decks() {
				if (typeof this.resultsAmount === 'number') {
					return this.$store.getters.mostFavouriteLists.slice(0, this.resultsAmount);
				}
				return this.$store.getters.mostFavouriteLists;
			},
		},
		methods: {
			onCardClicked(e) {
				logAnalyticsEvent(...events.suggestedDeckCardClick, e.deckId);
			},
			fetchDecks() {
				return this.$store.dispatch('fetchMostFavouriteLists');
			},
		},
	};
</script>
