<template>
	<div
		class="c-app"
		:class="{ 'c-app--full': appFull }"
		:style="{ userSelect: (unselectable ? 'none' : null) }"
	>
		<Drimmer v-if="feature('recordingFeatures')" />
		<CustomCursor v-if="feature('recordingFeatures')" />
		<Dimmer
			:active="!ready || isOffsetMenuActive"
			:blur="blurDimmer"
			@click.native="hideOffsetMenu"
		>
			<div
				v-if="!ready"
				class="ui active centered loader text"
				:class="{ inverted: !blurDimmer }"
			>
				Loading
			</div>
		</Dimmer>
		<Header
			v-if="showHeader"
			:is-menu-active="isOffsetMenuActive"
			:is-logged-in="isLoggedIn"
			:on-menu-button-clicked="showOffsetMenu"
			:on-logout-request="logout"
			:show-menu="showSideMenu"
			:back-link="backLink"
			:modifiers="headerModifiers"
			:on-flag-click="() => languageModalOpened = true"
			:deck="deck"
		/>
		<OffsetMenu
			:is-menu-active="isOffsetMenuActive"
			:on-language-click="onLanguageClick"
		/>
		<PopOver
			v-clicked-outside="hideLanguagePopover"
			:show="languagePopoverOpened"
			position="bottom"
			style="z-index: 1000"
		>
			<div class="c-popover-body--one">
				<LanguagesRadio @language-changed="languagePopoverOpened = false" />
			</div>
		</PopOver>
		<div class="main c-app__content">
			<div>
				<!-- This is for displaying information when saving page failed -->
				<PopOver
					:show="showErrorToast"
				>
					<div class="ui container">
						<div class="ui error message">
							<!-- @TODO: Make message more meaningful -->
							{{ $t("errors.savingPageFailed") }}
						</div>
					</div>
				</PopOver>

				<!-- Toast message -->
				<PopOver
					:show="showToastMessage"
					position="bottom-left"
				>
					<div
						class="c-popover-body--one"
					>
						<div style="display: flex">
							<i
								class="check circle icon green"
								style="font-size: 22px; margin-right: var(--spacing-8);"
							/>
							<span style="font-size: 16px;">{{ toastMessage }}</span>
						</div>
					</div>
				</PopOver>
				<RouterView />
			</div>
		</div>
		<Footer v-if="showFooter" />
		<SignModal
			:modal-type="signModalType"
			:modal-redirect="signModalRedirect"
		/>
		<UserInfoModal v-if="$route.name" />
		<SurveyInvitationPopover v-if="feature('surveyInvitation')" />
		<SuiModal
			v-model="languageModalOpened"
			size="tiny"
		>
			<ModalCloseButton v-model="languageModalOpened" />
			<SuiModalHeader>{{ $t("language") }}</SuiModalHeader>
			<SuiModalContent>
				<LanguagesRadio @language-changed="languageModalOpened = false" />
			</SuiModalContent>
		</SuiModal>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { seoToDeckId } from '@/assets/js/dataServices/utils';
	import SurveyInvitationPopover from '@/components/surveyInvitation/SurveyInvitationPopover';
	import Header from '@/components/header/Header.vue';
	import OffsetMenu from '@/components/offsetMenu/OffsetMenu.vue';
	import Dimmer from '@/components/Dimmer.vue';
	import SignModal from '@/components/signModal/SignModal.vue';
	import UserInfoModal from '@/components/userInfoModal/UserInfoModal.vue';
	import Footer from '@/components/footer/Footer.vue';
	import PopOver from '@/components/PopOver.vue';
	import LanguagesRadio from '@/components/language/LanguagesRadio.vue';
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
	import CustomCursor from '@/components/recordingFeatures/CustomCursor';
	import Drimmer from '@/components/recordingFeatures/Drimmer';
  import ModalCloseButton from '@/components/ModalCloseButton.vue';

	const onBeforeUnload = e => e.returnValue = 'Nooo';

	export default {
		components: {
			ModalCloseButton,
			Drimmer,
			CustomCursor,
			SurveyInvitationPopover,
			LanguagesRadio,
			Footer,
			Dimmer,
			Header,
			OffsetMenu,
			SignModal,
			UserInfoModal,
			PopOver,
		},
		data() {
			return {
				showToastMessage: false,
				toastMessageTimeoutId: null,
				showErrorToast: false,
				errorToastTimeoutId: null,
				languagePopoverOpened: false,
				languageModalOpened: false,
			};
		},
		computed: {
			...mapGetters(['feature']),
			deck() {
				if (this.$route.name !== 'listPlay') {
					return {};
				}
				const id = seoToDeckId(this.$route.params.id);
				const deck = this.$store.state.allLists[id];

				return deck || {};
			},
			isLoggedIn() {
				return this.$store.getters.isLoggedIn;
			},
			isOffsetMenuActive() {
				return this.$store.state.ui.isOffsetMenuActive;
			},
			ready() {
				return !this.$store.state.loadingRoute.loading;
			},
			user() {
				return this.$store.state.user;
			},
			blurDimmer() {
				return this.$store.getters.configuration.blurDimmer;
			},
			showHeader() {
				if (this.$store.getters.configuration.showHeader === false) {
					return false;
				}

				return this.$route.meta.showHeader !== false;
			},
			showFooter() {
				return this.$route.meta.showFooter !== false;
			},
			unselectable() {
				return this.$route.meta.unselectable;
			},
			showSideMenu() {
				return this.$route.meta.showSideMenu !== false;
			},
			appFull() {
				return this.$route.meta.full === true;
			},
			headerModifiers() {
				return this.$route.meta.headerModifiers;
			},
			backLink() {
				if (this.$route.name === 'listPlay') {
					return {
						name: 'listDetails',
					};
				}
				if (this.$route.name === 'listDetails' || this.$route.name === 'privacyPolicy') {
					return {
						name: 'dashboard',
					};
				}
				if (this.$route.name === 'results') {
					return {
						name: 'listDetails',
					};
				}
				return undefined;
			},
			serverErrorsAmount() {
				return this.$store.state.serverErrors.length;
			},
			toastMessage() {
				return this.$store.state.toastMessage;
			},
			nonGetRequestsAmount() {
				return this.$store.state.nonGetRequests.length;
			},
			areRequestsPending() {
				return this.nonGetRequestsAmount > 0;
			},
			signModalType() {
				return this.$store.state.ui.signModalType;
			},
			signModalRedirect() {
				return this.$store.state.ui.signModalRedirect;
			},
		},
		watch: {
			toastMessage(newVal, oldVal) {
				if (newVal !== oldVal) {
					clearTimeout(this.toastMessageTimeoutId);
					this.showToastMessage = true;

					this.toastMessageTimeoutId = setTimeout(() => {
						this.showToastMessage = false;
					}, 2 * 1000);
				}
			},
			showErrorToast(newVal) {
				if (newVal === true) {
					logAnalyticsEvent(...events.genericErrorPopoverDisplay);
				}
			},
			serverErrorsAmount(newVal, oldVal) {
				if (newVal !== oldVal) {
					clearTimeout(this.errorToastTimeoutId);
					this.showErrorToast = true;

					this.errorToastTimeoutId = setTimeout(() => {
						this.showErrorToast = false;
					}, 2 * 1000);
				}
			},
			areRequestsPending(newVal) {
				if (newVal === true) {
					window.addEventListener('beforeunload', onBeforeUnload);
				} else {
					window.removeEventListener('beforeunload', onBeforeUnload);
				}
			},
		},
		mounted() {
			document.addEventListener('keydown', (evt) => {
				if (evt.key === 'Escape') {
					this.$store.dispatch('hideOffsetMenu');
				}
			});
		},
		methods: {
			hideOffsetMenu() {
				this.$store.dispatch('hideOffsetMenu');
			},
			showOffsetMenu() {
				this.$store.dispatch('showOffsetMenu');
			},
			logout() {
				this.$store.dispatch('logout');
				this.$router.push('/');
			},
			onLanguageClick() {
				if (this.languagePopoverOpened === true) {
					this.languagePopoverOpened = false;
					return;
				}

				setTimeout(() => {
					this.languagePopoverOpened = true;
				}, 10);
				this.languagePopoverOpened = true;
			},
			hideLanguagePopover() {
				this.languagePopoverOpened = false;
			},
		},
	};
</script>

<style scoped>
	.c-app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		min-height: 100svh;
	}

	.c-app--full {
		overflow: hidden;
	}

	.c-app__content {
		width: 100%;
		flex: 1;
		margin: auto;
		position: relative;
	}

	.c-app--full .c-app__content {
		flex: initial;
	}
</style>
