<script>
	export default {
		name: 'TextReplacer',
		props: {
			replace: Boolean,
			injected: String,
			content: String,
		},
		render() {
			let resultText = this.content;

			if (this.replace && this.injected !== null) {
				// eslint-disable-next-line no-useless-escape
				resultText = this.content.replace(/(\_|\.){4,}/g, this.injected);
			}

			return this._v(resultText);
		},
	};
</script>
