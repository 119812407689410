<template>
	<FocusLock>
		<SuiModal
			:open="opened"
			@changed="(opened) => $emit('change', opened)"
		>
			<ModalCloseButton
				:modal-shown="opened"
				@input="(opened) => $emit('change', opened)"
			/>
			<SuiModalHeader>Manage Deck Metadata</SuiModalHeader>
			<SuiModalContent
				style="color: black;"
				scrolling
			>
				<AnswersRadio
					v-model="model"
					compact
					:options="models"
					style="margin-bottom: var(--spacing-12);"
				/>
				<button
					class="c-btn c-btn--cta c-btn--small"
					:class="{ 'c-btn--loading': generatingMetadata}"
					style="margin-bottom: var(--spacing-24);"
					@click="generateMetadata"
				>
					Generate Metadata
				</button>
				<form
					action=""
					class="ui form"
					@submit.prevent="saveMetadata"
				>
					<div class="field">
						<div style="display:flex; gap: var(--spacing-8);">
							<button
								v-for="f in ['english language']"
								type="button"
								class="c-btn c-btn--small"
								@click="fieldOfStudy = f"
							>
								{{ f }}
							</button>
						</div>
						<label>
							Field of Study
							<input
								v-model="fieldOfStudy"
								type="text"
							>
						</label>
					</div>
					<div class="field">
						<div style="display:flex; gap: var(--spacing-8);">
							<button
								v-for="l in ['a1', 'a2', 'b1', 'b2', 'c1', 'c2']"
								type="button"
								class="c-btn c-btn--small"
								@click="level = l"
							>
								{{ l }}
							</button>
						</div>
						<label>
							Level
							<input
								v-model="level"
								type="text"
							>
						</label>
					</div>
					<div class="field">
						<label>
							Languages
							<input
								v-model="languages"
								type="text"
							>
						</label>
					</div>
					<div class="field">
						<label>
							Language Front
							<input
								v-model="languageFront"
								type="text"
							>
						</label>
					</div>
					<div class="field">
						<label>
							Language Back
							<input
								v-model="languageBack"
								type="text"
							>
						</label>
					</div>
					<div class="field">
						<label>
							Tags
							<div class="tags-section">
								<Tag
									v-for="tag in tagsArray"
									:key="tag"
									:tag-name="tag"
								/>
							</div>
							<input
								v-model="tags"
								type="text"
							>
						</label>
					</div>
					<div class="field">
						<label>
							Tags (GPT-4o)
							<div class="tags-section">
								<Tag
									v-for="tag in tagsGPT40Array"
									:key="tag"
									:tag-name="tag"
									@click.prevent.stop.native.capture="toggleTag(tag)"
								/>
							</div>
						</label>
					</div>
					<div class="field">
						<label>
							Tags (GPT-3.5)
							<div class="tags-section">
								<Tag
									v-for="tag in tagsGPT3Array"
									:key="tag"
									:tag-name="tag"
									@click.prevent.stop.native.capture="toggleTag(tag)"
								/>
							</div>
						</label>
					</div>
					<div clas="field">
						<button
							type="submit"
							class="c-btn c-btn--cta c-btn--small"
							:class="{ 'c-btn--loading': savingMetadata}"
						>
							Save
						</button>
					</div>
				</form>
			</SuiModalContent>
		</SuiModal>
	</FocusLock>
</template>

<script>
import Modal from 'vue-semantic-modal2/src/VueSemanticModal';
import FocusLock from 'vue-focus-lock';
import axios from 'axios';
import ModalCloseButton from '@/components/ModalCloseButton.vue';
import Tag from '@/components/Tag.vue';
import AnswersRadio from '@/components/deckPlay/AnswersRadio.vue';

// 'verbs ,, grammar  ' => ['verbs', 'grammar']
const commaSeparatedStringToArray = str => str.split(',').map(s => s.trim()).filter(Boolean);

export default {
	name: 'ManageDeckMetadataModal',
	components: {
		AnswersRadio,
		Tag,
		ModalCloseButton,
		Modal,
		FocusLock,
	},
	model: {
		prop: 'opened',
		event: 'change',
	},
	props: {
		opened: {
			default: false,
		},
		deck: {
			type: Object,
			default: undefined,
		},
	},
	data() {
		const models = [
			{ key: 'GPT3', name: 'GPT-3.5' },
			{ key: 'GPT4o', name: 'GPT-4o' },
		];

		return {
			model: 'GPT3',
			models,
			generatingMetadata: false,
			savingMetadata: false,

			fieldOfStudy: this.deck.gen_fieldOfStudy || '',
			level: this.deck.gen_level || '',
			languages: (this.deck.gen_languages || []).join(', '),
			languageFront: this.deck.gen_languageFront || '',
			languageBack: this.deck.gen_languageBack || '',
			tags: (this.deck.gen_tags || []).join(', '),

			GPT3: {
				tags: '',
			},
			GPT4o: {
				tags: '',
			},
		};
	},
	computed: {
		tagsArray() {
			return commaSeparatedStringToArray(this.tags);
		},
		tagsGPT3Array() {
			return commaSeparatedStringToArray(this.GPT3.tags);
		},
		tagsGPT40Array() {
			return commaSeparatedStringToArray(this.GPT4o.tags);
		},
	},
	methods: {
		toggleTag(tag) {
			this.tags = this.tagsArray.includes(tag)
				? this.tags.replace(tag, '')
				: `${this.tags}, ${tag}`;
		},
		async saveMetadata() {
			this.savingMetadata = true;

			const changes = {
				gen_fieldOfStudy: this.fieldOfStudy,
				gen_level: this.level,
				gen_languages: this.languages.split(',').map(l => l.trim()).filter(Boolean),
				gen_tags: this.tagsArray,
			};

			if (this.languageFront) {
				changes.gen_languageFront = this.languageFront;
			}

			if (this.languageBack) {
				changes.gen_languageBack = this.languageBack;
			}

			await this.$store.dispatch('patchDeck', {
				deckId: this.deck._id,
				changes,
				isGen: true,
			});

			this.savingMetadata = false;
		},
		async generateMetadata() {
			const model = this.model;
			this.generatingMetadata = true;
			const { data } = await axios.get(`/generator/generate-metadata/${this.deck._id}`, {
				params: { model },
			});

			this.fieldOfStudy = data.content.fieldOfStudy.toLowerCase();
			this.tags = data.content.tags.join(', ').toLowerCase();
			this.level = data.content.level.toLowerCase();
			this.languages = data.content.languages.join(', ').toLowerCase();
			this.languageFront = (data.content.languageFront || '').toLowerCase();
			this.languageBack = (data.content.languageBack || '').toLowerCase();

			this[model].tags = this.tags.slice();

			this.generatingMetadata = false;
		},
	},
};
</script>
<style scoped>
.tags-section {
	display: flex;
	gap: var(--spacing-8);
	margin: var(--spacing-8) 0;
	flex-wrap: wrap;
}
</style>
