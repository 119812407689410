// { name: 'banana', x: 30, y: 24, cardId: '12345', }

const getDefaultState = () => ({
	stickers: JSON.parse(localStorage.getItem('stickers') || '[]'),
});

export default () => ({
	namespaced: true,
	state: getDefaultState(),
	getters: {
		getStickers(state) {
			state.stickers;
			return cardId => (state.stickers || []).filter(sticker => sticker.cardId === cardId);
		},
	},
	mutations: {
		addSticker(state, data) {
			state.stickers.push(data);
			localStorage.setItem('stickers', JSON.stringify(state.stickers));
		},
	},

	actions: {
		addSticker({ commit }, payload) {
			commit('addSticker', payload);
		},
	},
});
