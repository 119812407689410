var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-deck-player-info",
      class: {
        "c-deck-player-info--inverted": _vm.inverted,
        "c-deck-player-info--with-progress": !!_vm.progress,
      },
      style: _vm.rootStyles,
    },
    [
      _c("div", { staticClass: "c-deck-player-info__content" }, [
        _vm.image
          ? _c("div", {
              staticClass: "c-deck-player-info__image",
              style: { "background-image": "url('" + _vm.image + "')" },
            })
          : _c(
              "div",
              {
                staticClass:
                  "c-deck-player-info__image c-deck-player-info__image--placeholder",
              },
              [
                _c("JustLogo", {
                  staticClass: "c-deck-player-info__placeholder-logo",
                }),
              ],
              1
            ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "c-deck-player-info__text-container" },
          [
            _vm.linkable
              ? _c(
                  "RouterLink",
                  {
                    staticClass: "c-deck-player-info__name",
                    attrs: {
                      to: {
                        name: "listDetails",
                        params: { id: _vm.seoDeckId },
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.deck.name) + "\n\t\t\t")]
                )
              : _c("span", { staticClass: "c-deck-player-info__name" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.deck.name) + "\n\t\t\t"),
                ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.linkable
              ? _c(
                  "RouterLink",
                  {
                    staticClass: "c-deck-player-info__author",
                    attrs: {
                      to: {
                        name: "userProfile",
                        params: { id: _vm.seoUserId },
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.deckAuthor.displayName) +
                        "\n\t\t\t"
                    ),
                  ]
                )
              : _c("span", { staticClass: "c-deck-player-info__author" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.deckAuthor.displayName) +
                      "\n\t\t\t"
                  ),
                ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm.playNextButtons
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "c-btn c-btn--cta c-btn--small",
                  staticStyle: { "white-space": "nowrap" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("play-next-deck", {
                        deckId: _vm.deck._id,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("deckPlay.playNext")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.progress
        ? _c("div", { staticClass: "c-deck-player-info__progress" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }