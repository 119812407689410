var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "c-user-profile" },
    [
      _c(
        "RouterLink",
        {
          staticClass: "link",
          attrs: {
            to: {
              name: "userProfile",
              params: {
                id: _vm.deckIdToSeo(
                  _vm.list.author.displayName,
                  _vm.list.author._id
                ),
              },
            },
          },
        },
        [
          _c("span", { staticClass: "c-user-profile__img-container" }, [
            _c("i", { staticClass: "user icon" }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.list.author.displayName))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }