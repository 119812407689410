var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DecksContainer", {
    staticStyle: { "--decks-container-heading-border-bottom": "none" },
    attrs: {
      decks: _vm.decks,
      title: _vm.$t("whyDontYouTrySomeMoreDecks"),
      "fetch-decks": _vm.fetchDecks,
      "results-amount": _vm.resultsAmount,
      "more-link": "",
    },
    on: { "card-clicked": _vm.onCardClicked },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }