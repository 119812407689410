<template>
	<div
		class="c-answer-radio"
		:class="{
			'c-answer-radio--compact': compact,
			'c-answer-radio--inline': inline,
			'c-answer-radio--darker': darker
		}"
		role="radiogroup"
	>
		<div
			v-for="option in options"
			:key="option.key"
			style="display: flex"
		>
			<div
				ref="radio"
				data-qa="answers-radio"
				:aria-checked="answer ? answer === option.key : value === option.key ? 'true' : 'false'"
				:tabindex="answer ? answer === option.key : value === option.key ? '0' : '-1'"
				class="c-answer-radio__radio"
				:class="{
					'c-answer-radio__radio--checked': answer ? answer === option.key : value === option.key,
					'c-answer-radio__radio--valid': (typeof value !== 'undefined') && answer && (answer !== value && value === option.key) || (answer === value && value === option.key),
					'c-answer-radio__radio--invalid': (typeof value !== 'undefined') && answer && (answer !== value && answer === option.key),
				}"
				role="radio"
				@click="change(option.key)"
				@keyup.right.prevent.stop="nextElement"
				@keyup.down.prevent.stop="nextElement"
				@keyup.up.prevent.stop="prevElement"
				@keyup.left.prevent.stop="prevElement"
			>
				<div class="c-answer-radio__radio-pointer-container">
					<div
						:class="{ 'c-pointer--active' : answer ? answer === option.key : value === option.key }"
						class="c-pointer"
					/>
				</div>
				<p>
					{{ option.nameLocalized ? $t(option.nameLocalized) : option.name }}
				</p>
			</div>
			<slot
				:name="option.key"
				:category="option"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AnswersRadio',
		model: {
			prop: 'value',
			event: 'change',
		},
		props: {
			compact: {
				type: Boolean,
				default: false,
			},
			inline: {
				type: Boolean,
				default: false,
			},
			darker: {
				type: Boolean,
				default: false,
			},
			options: {
				type: Array,
			},
			value: {
				type: String,
			},
			answer: {
				type: String,
			},
		},
		computed: {
			keys() {
				return this.options.map(({ key }) => key);
			},
		},
		methods: {
			change(value) {
				this.$emit('change', value);
			},
			nextElement() {
				const index = this.keys.indexOf(this.value);
				const isLast = index + 1 === this.keys.length;
				const nextIndex = isLast ? 0 : index + 1;

				this.$refs.radio[nextIndex].focus();
				this.change(this.keys[nextIndex]);
			},
			prevElement() {
				const index = this.keys.indexOf(this.value);
				const isFirst = index === 0;
				const nextIndex = isFirst ? this.options.length - 1 : index - 1;

				this.$refs.radio[nextIndex].focus();
				this.change(this.keys[nextIndex]);
			},
		},
	};
</script>
