import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=fd1bb9de&scoped=true"
import script from "./UserProfile.vue?vue&type=script&lang=js"
export * from "./UserProfile.vue?vue&type=script&lang=js"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=fd1bb9de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd1bb9de",
  null
  
)

export default component.exports