<template>
	<div
		class="c-answer-radio"
		:class="{ 'c-answer-radio--compact': compact }"
	>
		<label
			v-for="option in options"
			:key="option.key"
			data-qa="answers-checkbox"
			class="c-answer-radio__radio"
			:class="{
				'c-answer-radio__radio--valid': validAnswers && validAnswers.includes(option.key) && localValue.includes(option.key),
				'c-answer-radio__radio--invalid': validAnswers && ((validAnswers.includes(option.key) && !localValue.includes(option.key)) || (!validAnswers.includes(option.key) && localValue.includes(option.key))),
			}"
		>
			<span
				class="c-answer-radio__radio-pointer-container"
				style="position: relative;"
			>
				<input
					v-model="localValue"
					class="checkbox"
					type="checkbox"
					name="interest"
					:disabled="disabled"
					:value="option.key"
					:checked="localValue.includes(option.key)"
				>
				<span
					class="fake-checkbox"
					:class="{ 'fake-checkbox--active': localValue.includes(option.key) }"
				/>
			</span>
			<p>
				{{ option.name }}
			</p>
		</label>
	</div>
</template>

<script>
	export default {
		name: 'AnswersCheckboxes',
		model: {
			prop: 'value',
			event: 'change',
		},
		props: {
			compact: {
				type: Boolean,
				default: false,
			},
			options: {
				type: Array,
			},
			value: {
				type: Array,
			},
			validAnswers: {
				type: Array,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				localValue: [],
			};
		},
		computed: {
			keys() {
				return this.options.map(({ key }) => key);
			},
		},
		watch: {
			value(newVal) {
				if (Array.isArray(newVal)) {
					this.localValue = newVal;
				} else {
					this.localValue = [];
				}
			},
			localValue() {
				this.$emit('change', this.localValue);
			},
		},
		mounted() {
			this.localValue = this.value || [];
		},
	};
</script>
<style scoped lang="scss">
	.checkbox {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
</style>
