// https://stackoverflow.com/a/42389266/974351

const Plugin = {};

Plugin.install = (Vue) => {
	Vue.directive('clicked-outside', {
		bind(el, binding, vnode) {
			el.clickOutsideEvent = (event) => {
				// here I check that click was outside the el and his children
				if (!(el === event.target || el.contains(event.target))) {
					// and if it did, call method provided in attribute value
					const callback = vnode.context[binding.expression];
					callback && callback(event);
				}
			};
			document.body.addEventListener('click', el.clickOutsideEvent);
		},
		unbind(el) {
			document.body.removeEventListener('click', el.clickOutsideEvent);
		},
	});
};

export default Plugin;
