<template>
	<DecksContainer
		:decks="localDecks"
		:title="$t('dashboard.favourites.title')"
		:fetch-decks="fetchDecks"
		:results-amount="resultsAmount"
		:more-link="resultsAmount ? 'favourites' : ''"
	>
		<template slot="noDecksMessage">
			{{ $t('dashboard.favourites.giveSomeLoveToYourFavourites') }}
		</template>
	</DecksContainer>
</template>
<script>
	import DecksContainer from '@/components/dashboard/DecksContainer';

	export default {
		name: 'Favourites',
		components: { DecksContainer },
		props: {
			resultsAmount: {
				type: Number,
				default: null,
			},
			decks: {
				type: [Array, undefined],
				default: undefined,
			},
		},
		computed: {
			localDecks() {
				this.$store.getters.starredLists;
				this.resultsAmount;

				if (Array.isArray(this.decks)) {
					return this.decks;
				}

				let starredListsIds = Object.keys(this.$store.getters.starredLists);

				if (typeof this.resultsAmount === 'number') {
					starredListsIds = starredListsIds.slice(0, this.resultsAmount);
				}

				return starredListsIds.map(id => this.$store.getters.starredLists[id]);
			},
		},
		methods: {
			fetchDecks() {
				return this.$store.dispatch('fetchStarredLists');
			},
		},
	};
</script>
