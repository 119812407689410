<template>
	<button
		:title="$t(deck.private ? 'deckCreate.makeThisDeckPublic' : 'deckCreate.makeThisDeckPrivate')"
		class="c-btn c-btn--icon c-btn--small"
		:class="{
			'c-btn--white': white,
			'c-btn--secondary': secondary,
			'c-btn--inverted': inverted,
			'c-btn--cta': cta,
		}"
		@click.prevent="onClick"
	>
		<i
			:class="{
				lock: !deck.private,
				unlock: deck.private
			}"
			class="c-btn__icon icon"
		/>
		<span class="c-btn__icon-text">
			{{ $t(deck.private ? 'deckCreate.makeThisDeckPublic' : 'deckCreate.makeThisDeckPrivate') }}
		</span>
	</button>
</template>

<script>
	export default {
		name: 'TogglePrivateDeckButton',
		props: {
			deck: {
				type: Object,
			},
			onClick: {
				type: Function,
				default: () => () => {},
			},
			white: {
				type: Boolean,
				default: true,
			},
			secondary: {
				type: Boolean,
				default: true,
			},
			inverted: {
				type: Boolean,
				default: true,
			},
			cta: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
