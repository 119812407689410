<template>
	<div
		ref="deckResults"
		class="c-deck-results"
		:class="{ 'c-deck-results--modal-look': modalLook }"
	>
		<div class="c-hero c-hero--center-aligned inverted-colors">
			<div
				class="ui container"
			>
				<div>
					<div
						v-if="initialStreak !== streak"
						class="c-deck-results__heading c-deck-results__heading--variant"
					>
						<Carousel
							:per-page="1"
							:mouse-drag="true"
							:autoplay="true"
							:autoplay-hover-pause="true"
							:autoplay-timeout="3700"
							pagination-color="#bdbdbd"
							pagination-active-color="#ffffff"
						>
							<Slide>
								<ResultsHeader
									:fff="fff"
									:ggg="ggg"
									:known-cards="knownCards"
									:question-cards-number="questionCardsNumber"
								/>
							</Slide>
							<Slide>
								<div class="c-deck-results__heading-streak">
									<div class="ui statistics small inverted">
										<div
											class="statistic"
											data-qa="deck-results-heading-streak"
										>
											<div class="label">
												{{ $t('stats.currRunStreak') }}
											</div>
											<div class="value">
												<span>
													<Roller
														v-if="hhh"
														class="roller-inline"
														:text="String(streak)"
														:is-number-format="true"
														:transition="1"
													/>
													{{ $tc('stats.day', streak, { amount: streak }) }}</span>
											</div>
										</div>
									</div>
								</div>
							</Slide>
						</Carousel>
					</div>
					<div
						v-else
						class="c-deck-results__heading"
					>
						<ResultsHeader
							:fff="fff"
							:ggg="ggg"
							:known-cards="knownCards"
							:question-cards-number="questionCardsNumber"
						/>
					</div>
				</div>
				<div
					class="c-hero__btn-container c-hero__btn-container--centered"
				>
					<RouterLink
						:title="$t('play')"
						class="c-btn c-btn--icon"
						:class="{
							'c-btn--cta': !nextDeckSuggestionFeature,
							'c-btn--white': nextDeckSuggestionFeature,
						}"
						data-qa="play-again-btn"
						:to="{ name: 'listPlay',
							params: { id: seoDeckId }}"
						@click.native="onPlayAgainLinkClick"
					>
						<i class="c-btn__icon game icon" />
						<span class="c-btn__icon-text">
							{{ $t('deckPlay.playAgain') }}
						</span>
					</RouterLink>
					<ShareDeck
						:deck="deck"
						:context="labels.resultsModal"
					/>
					<button
						type="button"
						class="c-btn c-btn--white c-btn--just-icon"
						@click="toggleFavourites"
					>
						<AddToFavourites
							:list="list"
							:outline="true"
						/>
					</button>
				</div>
			</div>
		</div>
		<div
			class="ui container c-deck-details__content"
		>
			<ResultsCards
				:answers="answers"
				:deck="deck"
				:cards="questionCards"
				:disable-controls="{ answersVisibility: true }"
				:display-progress-difference="true"
			/>

			<!--			<CreateAccountTeaser-->
			<!--				v-if="!isLoggedIn && showRegisterSection"-->
			<!--				ref="createAccountTeaser"-->
			<!--				:on-sign-up-click="onSignUpClick"-->
			<!--				:on-sign-in-click="onSignInClick"-->
			<!--				:header-text="knowledgeProgress ? 'trackYourResults2' : 'trackYourResults'"-->
			<!--			/>-->
			<CreateAccountTeaser
				v-if="!isLoggedIn && showRegisterSection"
				ref="createAccountTeaser"
				:on-sign-up-click="() => onSignUpClick('signUpAction')"
				:on-sign-in-click="onSignInClick"
				:header-text="'youTooCanCreateDeck'"
				:button-text="'createDeckNow'"
				:show-already-signed-up-section="false"
			/>
			<div
				v-if="shouldDisplay"
				ref="feedbackForm"
				class="c-feedback"
				data-qa="do-you-like-md-section"
			>
				<p class="c-feedback__title ui header">
					{{ $t('satisfactionFeedback.doYouLikeMultidecker') }}
				</p>
				<FeedbackAnswers :on-feedback="feedback" />
			</div>

			<div class="c-deck-results__decks-teaser">
				<SimilarDecks2
					v-if="isAdmin"
					:results-amount="12"
					:deck="deck"
				/>
				<SimilarDecks :results-amount="12" />
			</div>
			<div class="c-deck-results__feedback">
				<Feedback :context="context.resultsPage" />
			</div>
			<PopOver
				:show="feedbackPopOverOpened"
				position="bottom"
			>
				<div class="c-popover-body--one">
					<span class="c-popover-body__close-btn-wrapper c-popover-body__close-btn-wrapper--floating">
						<button
							type="button"
							class="c-popover-body__close-btn"
							@click="feedbackPopOverOpened = false"
						>
							<i class="ui close icon c-popover-body__close-btn-icon" />
						</button>
					</span>
					<div v-if="feedbackType === 'like'">
						<p class="ui medium header">
							{{ $t('satisfactionFeedback.greatPleaseTakeAMoment') }}
						</p>
						<iframe
							src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Ffacebook.com%2Fmultidecker&width=450&layout=standard&action=like&size=large&share=false&height=35&appId=1152850601417405"
							height="35"
							style="border:none;overflow:hidden;width:auto;"
							scrolling="no"
							frameborder="0"
							allowTransparency="true"
							allow="encrypted-media"
						/>
					</div>
					<div v-else>
						<p class="ui medium header">
							{{ $t('satisfactionFeedback.thankYouForYourFeedback') }}
						</p>
						<p v-html="$t('satisfactionFeedback.weAreConstantlyTrying')" />
					</div>
				</div>
			</PopOver>
			<div
				v-if="nextDeckSuggestionFeature"
				style="--movement-distance: 80px"
			>
				<Transition name="appear">
					<div
						v-if="suggestedNextDeck && zzz"
						class="c-deck-results__suggested-deck-container"
					>
						<div class="ui container">
							<DeckPlayerInfo
								class="c-deck-results__suggested-deck-player"
								inverted
								play-next-buttons
								:deck="suggestedNextDeck"
								@play-next-deck="onPlayNextButtonClick"
							/>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	</div>
</template>

<script>
import Roller from 'vue-roller';
import { Carousel, Slide } from 'vue-carousel/src';
import { mapGetters } from 'vuex';
import { deckIdToSeo, seoToDeckId } from '@/assets/js/dataServices/utils';
import PopOver from '@/components/PopOver';
import SimilarDecks from '@/pages/dashboard/similar-decks';
import {
	ctaLocations, events, labels, logAnalyticsEvent,
} from '@/assets/js/utils/analytics';
import AddToFavourites from '@/components/AddToFavourites';
import { toggleFavourites } from '@/components/AddToFavourites';
import { flashcardsSuffix, setTitle } from '@/assets/js/utils/meta';
import { daysBetween } from '@/assets/js/utils/dateTime';
import { intersectionHandlerOnce } from '@/assets/js/utils/dom';
import CreateAccountTeaser from '@/components/deckResults/CreateAccountTeaser';
import ResultsHeader from '@/components/deckResults/ResultsHeader';
import Feedback from '@/components/feedback/Feedback';
import { context } from '@/components/feedback/Feedback';
import FeedbackAnswers from '@/components/feedback/FeedbackAnswers';
import ShareDeck from '@/components/ShareDeck';
import ResultsCards from '@/components/deckResults/ResultsCards';
import DeckPlayerInfo from '@/components/deckPlay/DeckPlayerInfo.vue';
import SimilarDecks2 from '@/pages/dashboard/similar-decks2.vue';

export default {
	name: 'DeckResultList',
	components: {
		SimilarDecks2,
		DeckPlayerInfo,
		ResultsCards,
		Carousel,
		Slide,
		ShareDeck,
		FeedbackAnswers,
		Feedback,
		CreateAccountTeaser,
		AddToFavourites,
		SimilarDecks,
		PopOver,
		Roller,
		ResultsHeader,
	},
	data() {
		return {
			fff: false,
			ggg: false,
			hhh: false,
			zzz: false,
			labels,
			context,
			resultMessages: [
				[1, this.$t('deckPlay.results.correctPercent100')],
				[0.75, this.$t('deckPlay.results.correctPercent75')],
				[0.6, this.$t('deckPlay.results.correctPercent60')],
				[0.4, this.$t('deckPlay.results.correctPercent40')],
				[0.25, this.$t('deckPlay.results.correctPercent25')],
				[0.1, this.$t('deckPlay.results.correctPercent10')],
				[0, this.$t('deckPlay.results.correctPercent0')],
			],
			feedbackPopOverOpened: false,
			feedbackType: '',
			shouldDisplay: false,
		};
	},
	computed: {
		...mapGetters('playStats', [
			'streak',
		]),
		...mapGetters(['feature', 'isAdmin']),
		...mapGetters('answers', ['sessionAnswers']),
		nextDeckSuggestionFeature() {
			return this.feature('nextDeckSuggestion');
		},
		answers() {
			return this.sessionAnswers(this.$parent.sessionId);
		},
		initialStreak() {
			return this.$parent.initialStreak;
		},
		seoDeckId() {
			return deckIdToSeo(this.deck.name, this.deck._id);
		},
		user() {
			return this.$store.state.user;
		},
		listId() {
			return seoToDeckId(this.$route.params.id);
		},
		deck() {
			return this.$store.getters.getDeck(this.listId) || {};
		},
		suggestedNextDeck() {
			return this.$store.getters.suggestedNextDeckByDeckId(this.deck._id);
		},
		list() {
			return this.$store.getters.getDeck(this.listId) || {};
		},
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		},
		isOwner() {
			return this.deck.author._id === this.user._id;
		},
		questionCards() {
			return this.$parent.cards.filter(card => !card.dummy);
		},
		knownCards() {
			return this.$route.params.knownCards;
		},
		questionCardsNumber() {
			return this.$route.params.questionCardsNumber;
		},
		modalLook() {
			return this.$store.getters.configuration.resultsModalLook;
		},
		showRegisterSection() {
			return this.$store.getters.configuration.resultsShowRegisterSection;
		},
		shouldDisplaySatisfactionFeedbackForm() {
			const formSubmitted = this.$store.getters.localStorage.satisfactionFeedbackFormSubmitted;
			const formLastTimeDisplayed = this.$store.getters.localStorage.satisfactionFeedbackFormLastTimeDisplayed;
			const neverDisplayed = formLastTimeDisplayed === null;
			const daysSinceModalDisplayed = neverDisplayed ? Infinity : daysBetween(Date.now(), formLastTimeDisplayed);
			const randomTrue = Math.random() > 0.4; // true with probability 60%.
			const knowMoreThan80 = (this.knownCards / this.questionCardsNumber) >= 0.8; // swiped more than 80% cards correct.

			if (formSubmitted) {
				return false;
			}

			return (neverDisplayed || daysSinceModalDisplayed > 7) && (randomTrue || knowMoreThan80);
		},
		knowledgeProgress() {
			return this.$store.getters.feature('knowledgeProgress');
		},
	},
	watch: {
		feedbackType() {
			const dr = this.$refs.deckResults;
			if (!dr) {
				return;
			}

			// Open PopOver
			this.feedbackPopOverOpened = true;
			const initialScrollTop = dr.scrollTop;

			const scrollHandler = () => {
				const diff = Math.abs(dr.scrollTop - initialScrollTop);
				if (diff >= 150) {
					// Hiding when user scrolled page for more than 150px
					this.feedbackPopOverOpened = false;
					dr.removeEventListener('scroll', scrollHandler);
				}
			};

			dr.addEventListener('scroll', scrollHandler);
		},
	},
	created() {
		setTitle(this.list.name, flashcardsSuffix[this.$i18n.locale]);

		this.shouldDisplay = this.shouldDisplaySatisfactionFeedbackForm;

		setTimeout(() => {
			this.fff = true;

			setTimeout(() => {
				this.ggg = true;
			}, 1400);

			setTimeout(() => {
				this.zzz = true;
			}, 1900);
		}, 400);

		setTimeout(() => {
			this.hhh = true;
		}, 3700);
	},
	mounted() {
		intersectionHandlerOnce(this.$refs.feedbackForm, () => {
			this.$store.dispatch('setLocalStorage', {
				key: 'satisfactionFeedbackFormLastTimeDisplayed',
				value: Date.now(),
			});
			logAnalyticsEvent(...events.satisfactionFeedbackFormDisplay);
		});

		if (this.$refs.createAccountTeaser) {
			intersectionHandlerOnce(this.$refs.createAccountTeaser.$el, () => {
				logAnalyticsEvent(...events.createAccountTeaserDisplay);
			});
		}
	},
	methods: {
		onPlayAgainLinkClick() {
			logAnalyticsEvent(...events.playAgainButtonClick);
		},
		onPlayNextButtonClick({ deckId }) {
			logAnalyticsEvent(...events.playNextDeckButtonClick);
			this.$parent.$emit('play-next-deck', { deckId });
		},
		toggleFavourites() {
			toggleFavourites(this.user._id, this.$store, this.list);
		},
		onSignUpClick(modalType = 'signUp') {
			this.$store.dispatch('changeModalType', modalType);
			logAnalyticsEvent(...events.signUpModalDisplay, ctaLocations.createAccountTeaser);
		},
		onSignInClick() {
			this.$store.dispatch('changeModalType', 'signIn');
			logAnalyticsEvent(...events.signInModalDisplay, ctaLocations.createAccountTeaser);
		},
		feedback(type) {
			// If feedback given already, then do nothing.
			if (this.feedbackType) {
				return;
			}

			this.$store.dispatch('setLocalStorage', { key: 'satisfactionFeedbackFormSubmitted', value: true });
			logAnalyticsEvent(...events.satisfactionFeedbackFormSubmit, type);
			this.feedbackType = type;
		},
	},
};
</script>

<style lang="scss">
	.c-deck-results {
		background: hsl(36, 33%, 97%);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 6;
		overflow: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		&--modal-look {
			max-width: 980px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 16px;
			width: 100%;
			z-index: 1000;
			box-shadow: 0 0 12px 0 rgba(32, 33, 36, .28);
			top: 32px;
			bottom: 32px;
		}

		&__heading {
			max-width: 500px;
			text-align: center;
			margin: 0 auto !important;
			padding-top: 48px !important;
			padding-bottom: 24px !important;
		}

		&__heading--variant {
			padding-bottom: 0 !important;
		}

		&__heading-streak {
			display: flex; justify-content: center;
		}

		&__decks-teaser {
			margin-top: var(--spacing-48);
		}

		&__feedback {
			margin-bottom: var(--spacing-48);
		}

		&__suggested-deck-container {
			position: fixed;
			bottom: var(--spacing-8);
			left: 0;
			right: 0;
			z-index: 1;
		}

		&__suggested-deck-player {
			margin: 0 auto;
			max-width: 600px;
		}
	}

	.c-feedback {
		margin: var(--spacing-64) 0;
	}

	.c-feedback__title.c-feedback__title {
		text-align: center;
		margin-bottom: 0;
	}

	.roller-inline {
		display: inline;
	}

	.VueCarousel-dot-container {
		margin-top: 0 !important;
	}

	.VueCarousel-dot {
		margin-top: 0 !important;
	}
</style>
