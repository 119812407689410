// TODO: This is imported from swing but should be imported from swing-pull135 ?
// or even better const { Direction } = VueSwing;
import { Direction } from 'swing';
import { categories } from '@/assets/js/lists/categories';

export function getNumberOfCategories(deck) {
	if (deck.categoryType === categories.DEFAULT) {
		return 2;
	}

	// @TODO: This is ugly hack for deck where each card have it's own category,
	// so we are checking only first card assuming, that all card have the same amount of categories
	const firstCard = deck.words[0];

	return (
		deck.categories && deck.categories.length
		|| firstCard && firstCard.categories && firstCard.categories.length
		|| 0
	);
}

export function isChoiceCategoryType(categoryType) {
	return [categories.SINGLE_CHOICE, categories.MULTIPLE_CHOICE].includes(categoryType);
}

export function getDefaultInstruction(deck, $t) {
	if (deck.categoryType === categories.DEFAULT) {
		return $t('deckPlay.tapOnTheAnswerOrSwipeEachCardToTheSides');
	}

	if (deck.categoryType === categories.CUSTOM) {
		const allowedDirections = getAllowedDirections(deck);
		const directionsTextKeys = allowedDirections.map((key, index) => [
			'deckPlay.left',
			'deckPlay.right',
			'deckPlay.up',
			'deckPlay.down',
		][index]);
		const directionsText = directionsTextKeys.map($t).join('/');
		return `${$t('deckPlay.tapOnTheAnswersOrSwipeEachCard')} ${directionsText}. ${$t('deckPlay.tapTheCardToSeeTheReverse')}`;
	}

	if (deck.categoryType === categories.SINGLE_CHOICE) {
		return $t('deckPlay.singleChoiceInstruction');
	}

	if (deck.categoryType === categories.MULTIPLE_CHOICE) {
		return $t('deckPlay.multipleChoiceInstruction');
	}
}

function getAllowedDirections(deck) {
	let allowedDirections = [
		Direction.LEFT,
		Direction.RIGHT,
	];

	const numberOfCategories = getNumberOfCategories(deck);
	if (numberOfCategories >= 3) {
		allowedDirections.push(Direction.UP);
	}

	if (numberOfCategories == 4) {
		allowedDirections.push(Direction.DOWN);
	}

	return allowedDirections;
}
