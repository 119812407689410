var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h2",
    { staticClass: "h3 c-hero__title", staticStyle: { "min-height": "105px" } },
    [
      _c("Transition", { attrs: { name: "t-fade" } }, [
        _vm.fff
          ? _c(
              "span",
              {
                staticStyle: { display: "inline-block" },
                attrs: { "data-qa": "deck-results-heading-one" },
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.$t("deckPlay.scoreIsPrefix")) +
                    "\n\t\t\t"
                ),
                _c("Roller", {
                  staticClass: "roller-inline",
                  attrs: {
                    text: String(_vm.knownCards),
                    "is-number-format": true,
                    transition: 1,
                  },
                }),
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.$t("deckPlay.scoreIsSuffix", {
                        total: _vm.questionCardsNumber,
                      })
                    ) +
                    "\n\t\t"
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("Transition", { attrs: { name: "t-fade" } }, [
        _vm.ggg
          ? _c(
              "span",
              {
                staticStyle: { display: "inline-block" },
                attrs: { "data-qa": "deck-results-heading-two" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.getResultMessage(
                      _vm.knownCards / _vm.questionCardsNumber
                    )
                  )
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }