var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "swing-card",
      class: {
        "swing-card--invalid": _vm.invalid,
        "swing-card--compact-fonts": _vm.compactFonts,
      },
      on: { click: _vm.toggleVisibility },
    },
    [
      _c("div", { staticClass: "swing-card__aside" }, [_vm._t("aside")], 2),
      _vm._v(" "),
      _c(
        "SwingCardShell",
        {
          attrs: {
            "card-id": _vm.word._id,
            "local-visible-side": _vm.localVisibleSide,
            progress: _vm.progress,
            "previous-progress": _vm.previousProgress,
            "display-progress": _vm.displayProgress,
            "delay-animation": _vm.delayAnimation,
            "display-stickers": _vm.displayStickers,
          },
          on: {
            "sticker-drop": function ($event) {
              return _vm.$emit("sticker-drop")
            },
          },
        },
        [
          _vm.backAvailable && _vm.localVisibleSide === _vm.SIDES.FRONT
            ? _c("Notch", { staticClass: "swing-card__notch-front" }, [
                _c("span", [_c("i", { staticClass: "grey reply icon" })]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.backAvailable && _vm.localVisibleSide === _vm.SIDES.BACK
            ? _c("Notch", { staticClass: "swing-card__notch-back" }, [
                _c("span", [
                  _c("i", {
                    staticClass: "share icon",
                    staticStyle: { color: "white" },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("notch"),
          _vm._v(" "),
          _c("template", { slot: "default" }, [_vm._t("default")], 2),
          _vm._v(" "),
          _c("template", { slot: "centered" }, [
            _vm.localVisibleSide === _vm.SIDES.FRONT
              ? _c(
                  "div",
                  {
                    staticClass: "swing-card__front",
                    class: {
                      "swing-card__front--long-text": _vm.isFrontLongText,
                    },
                  },
                  [
                    _c(
                      "p",
                      [
                        _c("TextReplacer", {
                          attrs: {
                            content: _vm.front,
                            replace: _vm.replaceContent,
                            injected: _vm.contentToBeReplaced,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.localVisibleSide === _vm.SIDES.BACK
              ? _c(
                  "div",
                  {
                    staticClass: "swing-card__back",
                    class: {
                      "swing-card__back--long-text": _vm.isBackLongText,
                    },
                  },
                  [_c("p", [_vm._v(_vm._s(_vm.back))])]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }