<template>
	<DecksContainer
		:decks="localDecks"
		:title="$t('dashboard.recentlyPlayed.title')"
		:fetch-decks="fetchDecks"
		:request-more-decks="requestMoreDecks"
		:results-amount="resultsAmount"
		:more-link="resultsAmount ? 'recentlyPlayed' : ''"
	>
		<template slot="noDecksMessage">
			{{ $t('dashboard.recentlyPlayed.playAnyDecksAndThoseWillAppearHere') }}
		</template>
	</DecksContainer>
</template>
<script>
	import DecksContainer from '@/components/dashboard/DecksContainer';

	export default {
		name: 'Yours',
		components: {
			DecksContainer,
		},
		props: {
			resultsAmount: {
				type: Number,
				default: null,
			},
			decks: {
				type: [Array, undefined],
				default: undefined,
			},
		},
		computed: {
			localDecks() {
				this.$store.getters.recentlyPlayedLists;
				this.resultsAmount;

				if (this.decks) {
					return this.decks;
				}

				if (typeof this.resultsAmount === 'number') {
					return this.$store.getters.recentlyPlayedLists.slice(0, this.resultsAmount);
				}

				return this.$store.getters.recentlyPlayedLists;
			},
			requestMoreDecks() {
				if (typeof this.resultsAmount === 'number') {
					return;
				}
				return () => this.$store.dispatch('loadMoreRecentlyPlayedLists');
			},
		},
		methods: {
			fetchDecks() {
				return this.$store.dispatch('fetchRecentlyPlayedLists');
			},
		},
	};
</script>
