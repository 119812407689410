var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "settings-container",
      class: {
        "settings-container--knowledge-progress": _vm.knowledgeProgress,
      },
    },
    [
      _vm.toggleShowAnswers && _vm.disableControls.answersVisibility !== true
        ? _c(
            "div",
            {
              staticClass: "control-with-title",
              class: { "control-with-title--inline": _vm.inline },
              attrs: { "data-qa": "control--answers-visibility" },
            },
            [
              _c("div", { staticClass: "control-with-title__title" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.$t("answersVisibility")) + ":\n\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control-with-title__control" }, [
                _c("div", { staticClass: "ui icon mini buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: _vm.showAnswers },
                      attrs: { type: "button", title: "Show cards progress" },
                      on: {
                        click: function ($event) {
                          return _vm._toggleShowAnswers(true)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "eye icon" }),
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("show")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: !_vm.showAnswers },
                      attrs: { type: "button", title: "Hide cards progress" },
                      on: {
                        click: function ($event) {
                          return _vm._toggleShowAnswers(false)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "eye slash icon" }),
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("hide")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.resultDisplayType && _vm.disableControls.displayAs !== true
        ? _c(
            "div",
            {
              staticClass: "control-with-title",
              class: { "control-with-title--inline": _vm.inline },
              attrs: { "data-qa": "control--display-as" },
            },
            [
              _c("div", { staticClass: "control-with-title__title" }, [
                _vm._v("\n\t\t\t" + _vm._s(_vm.$t("displayAs")) + ":\n\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control-with-title__control" }, [
                _c("div", { staticClass: "ui icon mini buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: {
                        grey:
                          _vm.resultDisplayType ===
                          _vm.resultDisplayTypes.CARDS,
                      },
                      attrs: { "data-qa": "control--display-as-cards-btn" },
                      on: {
                        click: function ($event) {
                          return _vm._changeResultDisplayType(
                            _vm.resultDisplayTypes.CARDS
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "th icon" }),
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("cards")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: {
                        grey:
                          _vm.resultDisplayType ===
                          _vm.resultDisplayTypes.TABLE,
                      },
                      attrs: { "data-qa": "control--display-as-table-btn" },
                      on: {
                        click: function ($event) {
                          return _vm._changeResultDisplayType(
                            _vm.resultDisplayTypes.TABLE
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "th list icon" }),
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("table")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.disableControls.flipAllCardsTo !== true
        ? _c(
            "div",
            {
              staticClass: "control-with-title",
              class: { "control-with-title--inline": _vm.inline },
              attrs: { "data-qa": "control--flip-all-cards-to" },
            },
            [
              _c("div", { staticClass: "control-with-title__title" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.$t("flipAllCardsTo")) + ":\n\t\t"
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "control-with-title__control" },
                [
                  _c("FlipCardsButtons", {
                    attrs: { "visible-side": _vm.visibleCardsSide },
                    on: { "visible-side": _vm._updateVisibleCardsSide },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.knowledgeProgress && _vm.disableControls.progressVisibility !== true
        ? _c(
            "div",
            {
              staticClass: "control-with-title",
              class: { "control-with-title--inline": _vm.inline },
              attrs: { "data-qa": "control--progress-visibility" },
            },
            [
              _c("div", { staticClass: "control-with-title__title" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.$t("progressVisibility")) + ":\n\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control-with-title__control" }, [
                _c("div", { staticClass: "ui icon mini buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: _vm.progressVisible },
                      attrs: { type: "button", title: "Show cards progress" },
                      on: {
                        click: function ($event) {
                          return _vm._toggleProgressVisible(true)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "eye icon" }),
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("show")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: !_vm.progressVisible },
                      attrs: { type: "button", title: "Hide cards progress" },
                      on: {
                        click: function ($event) {
                          return _vm._toggleProgressVisible(false)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "eye slash icon" }),
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("hide")) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.knowledgeProgress && _vm.disableControls.sortCardsBy !== true
        ? _c(
            "div",
            {
              staticClass: "control-with-title",
              class: { "control-with-title--inline": _vm.inline },
              attrs: { "data-qa": "control--sort-cards-by" },
            },
            [
              _c("div", { staticClass: "control-with-title__title" }, [
                _vm._v("\n\t\t\t" + _vm._s(_vm.$t("sortCardsBy")) + ":\n\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control-with-title__control" }, [
                _c("div", { staticClass: "ui icon mini buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: _vm.sortBy === "no_sorting" },
                      on: {
                        click: function ($event) {
                          return _vm._updateSortBy("no_sorting")
                        },
                      },
                    },
                    [_c("i", { staticClass: "sort icon" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: _vm.sortBy === "better_known_first" },
                      on: {
                        click: function ($event) {
                          return _vm._updateSortBy("better_known_first")
                        },
                      },
                    },
                    [_c("i", { staticClass: "sort amount down icon" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui button",
                      class: { grey: _vm.sortBy === "less_known_first" },
                      on: {
                        click: function ($event) {
                          return _vm._updateSortBy("less_known_first")
                        },
                      },
                    },
                    [_c("i", { staticClass: "sort amount up icon" })]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }