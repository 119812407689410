var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "button",
        {
          staticClass: "c-btn c-share-deck__button",
          class: {
            "c-btn--white": _vm.white,
            "c-btn--secondary": _vm.secondary,
            "c-btn--just-icon": !_vm.withText,
            "c-btn--icon": _vm.withText,
          },
          attrs: { type: "button" },
          on: { click: _vm.share },
        },
        [
          _c("i", { staticClass: "c-btn__icon share alternate icon" }),
          _vm._v(" "),
          _vm.withText
            ? _c("span", { staticClass: "c-btn__icon-text" }, [
                _vm._v(_vm._s(_vm.$t("share"))),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "SuiModal",
        {
          attrs: { size: "tiny" },
          model: {
            value: _vm.makeDeckPublicModalVisible,
            callback: function ($$v) {
              _vm.makeDeckPublicModalVisible = $$v
            },
            expression: "makeDeckPublicModalVisible",
          },
        },
        [
          _c("i", {
            staticClass: "close icon",
            staticStyle: { color: "white" },
            on: {
              click: function ($event) {
                _vm.makeDeckPublicModalVisible = false
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "c-modal-body" }, [
            _c("div", { staticClass: "c-modal-body__hero" }, [
              _c("h2", { staticClass: "ui large header inverted" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.$t("deckCreate.yourDeckIsPrivate")) +
                    "\n\t\t\t\t"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-modal-body__content" }, [
              _c("span", { staticClass: "ui small header" }, [
                _vm._v(_vm._s(_vm.$t("deckCreate.makeItPublicToShare"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-modal-body__footer" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "c-btn c-btn--tertiary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.makeDeckPublicModalVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.$t("buttons.cancel")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { width: "var(--spacing-8)" } }),
                  _vm._v(" "),
                  _c("TogglePrivateDeckButton", {
                    attrs: {
                      deck: _vm.deck,
                      cta: true,
                      white: false,
                      secondary: false,
                      inverted: false,
                      "on-click": _vm.togglePrivate,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "SuiModal",
        {
          attrs: { size: "standart" },
          model: {
            value: _vm.shareModalVisible,
            callback: function ($$v) {
              _vm.shareModalVisible = $$v
            },
            expression: "shareModalVisible",
          },
        },
        [
          _c("i", {
            staticClass: "close icon",
            staticStyle: { color: "white" },
            on: {
              click: function ($event) {
                _vm.shareModalVisible = false
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-modal-body" },
            [
              _c("FocusLock", [
                _c("div", { staticClass: "c-modal-body__hero" }, [
                  _c("h2", { staticClass: "ui large header inverted" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.$t("share")) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "c-modal-body__content" }, [
                  _c("div", { staticClass: "bookmark-container" }, [
                    _c("div", { staticClass: "bookmark-content" }, [
                      _c("div", { staticClass: "bookmark-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "bookmark-description" }, [
                        _vm._v(_vm._s(_vm.description)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "bookmark-metadata" }, [
                        _vm._v("multidecker.com"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bookmark-thumbnail" }, [
                      _c("img", {
                        staticClass: "bookmark-thumbnail-image",
                        attrs: { src: _vm.image, alt: "" },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "link-sharing" }, [
                    _c(
                      "span",
                      {
                        staticClass: "ui small header",
                        staticStyle: { color: "var(--shark)", opacity: "0.8" },
                      },
                      [_vm._v(_vm._s(_vm.$t("linkForSharing")))]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("input", {
                        staticStyle: {
                          background: "none",
                          border: "none",
                          "border-bottom": "1px solid var(--grey-16)",
                          "font-size": "16px",
                          "flex-grow": "1",
                          outline: "none",
                        },
                        attrs: {
                          onClick: "this.select();",
                          type: "text",
                          readonly: "",
                        },
                        domProps: { value: _vm.url },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "c-btn c-btn--tertiary",
                          staticStyle: { color: "var(--active)" },
                          attrs: { type: "button" },
                          on: { click: _vm.copyLink },
                        },
                        [_vm._v(_vm._s(_vm.$t("copyLink")))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "container-type": "inline-size" } },
                    [
                      _c("div", { staticClass: "share-buttons-container" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "c-btn c-btn--icon c-btn--facebook share-buttons-container__button",
                            on: { click: _vm.shareFacebook },
                          },
                          [
                            _c("i", {
                              staticClass: "c-btn__icon facebook icon",
                            }),
                            _vm._v(
                              "  \n\t\t\t\t\t\t\t\tFacebook\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "c-btn c-btn--icon c-btn--twitter share-buttons-container__button",
                            on: { click: _vm.shareTwitter },
                          },
                          [
                            _c("i", {
                              staticClass: "c-btn__icon twitter icon",
                            }),
                            _vm._v(
                              "  \n\t\t\t\t\t\t\t\tTwitter\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "c-btn c-btn--icon c-btn--whatsapp share-buttons-container__button",
                            attrs: { "data-action": "share/whatsapp/share" },
                            on: { click: _vm.shareWhatsApp },
                          },
                          [
                            _c("i", { staticClass: "whatsapp icon" }),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\tWhatsApp\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.shareAvailable
                          ? _c(
                              "button",
                              {
                                staticClass: "c-btn",
                                class: {
                                  "share-buttons-container__button":
                                    !_vm.minWidth558px,
                                  "c-btn--just-icon": _vm.minWidth558px,
                                  "c-btn--icon": !_vm.minWidth558px,
                                },
                                attrs: { type: "button" },
                                on: { click: _vm.shareOther },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "c-btn__icon ellipsis horizontal icon",
                                }),
                                _vm._v(" "),
                                !_vm.minWidth558px
                                  ? _c("span", [
                                      _vm._v(
                                        "   " + _vm._s(_vm.$t("moreOptions"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }