<template>
	<i
		class="heart icon c-btn__icon"
		:class="{ outline: !isFavourites }"
		:style="color ? { color } : { color: 'var(--orange-red)' }"
		@click.prevent.stop="toggleFavourites"
	/>
</template>

<script>
	import { ctaLocations, events, logAnalyticsEvent } from '@/assets/js/utils/analytics';

	export const toggleFavourites = (userId, $store, list) => {
		if (!userId) {
			$store.dispatch('changeModalType', 'signUpAction');
			logAnalyticsEvent(...events.signUpModalDisplay, ctaLocations.registeredUserAction);
			return;
		}

		$store.dispatch('toggleStar', { listId: list._id });
	};

	export default {
		props: [
			'list',
			'color',
		],
		computed: {
			userId() {
				return this.$store.getters.userId;
			},
			isFavourites() {
				return this.list.stars.indexOf(this.userId) !== -1;
			},
		},
		methods: {
			toggleFavourites() {
				if (!this.isFavourites) {
					logAnalyticsEvent(...events.addToFavouritesButtonClick);
				}

				toggleFavourites(this.userId, this.$store, this.list);
			},
		},
	};
</script>
