/* globals STATIC_FILES_BASE_URL, STATIC_FILES_IMG_PATH */

const getTitle = (title, suffix = '') => `${title} ${suffix}`.trim();

const setTitle = (title, suffix = '') => {
	document.title = getTitle(title, suffix);
};

const flashcardsSuffix = {
	en: 'flashcards | multidecker',
	pl: 'fiszki | multidecker',
};

// Copied from assets/js/i18n/translations.js
const defaultDeckInstruction = {
	en: 'Multidecker Smart Flashcards speeds up the learning process. The only flashcard app that organizes your knowledge. Try them out and see how smart they are.',
	pl: 'Inteligentne fiszki multidecker przyspieszają proces uczenia się. Jedyna aplikacja z kartami, która porządkuje Twoją wiedzę. Wypróbuj je i zobacz, jakie są inteligentne.',
};

const getDeckTitle = (deck, language = 'en') => getTitle(deck.name, flashcardsSuffix[language]);
const getDeckDescription = (deck, language = 'en') => deck.instruction || defaultDeckInstruction[language];

const getDeckImage = (deck) => {
	if (deck && deck.image) {
		return `${STATIC_FILES_BASE_URL}${STATIC_FILES_IMG_PATH}/${deck.image.file.filename}`;
	}

	return null;
};

module.exports = {
	getTitle,
	setTitle,
	flashcardsSuffix,
	getDeckDescription,
	getDeckTitle,
	getDeckImage,
};
