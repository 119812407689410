var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-app--full": _vm.appFull },
      style: { userSelect: _vm.unselectable ? "none" : null },
    },
    [
      _vm.feature("recordingFeatures") ? _c("Drimmer") : _vm._e(),
      _vm._v(" "),
      _vm.feature("recordingFeatures") ? _c("CustomCursor") : _vm._e(),
      _vm._v(" "),
      _c(
        "Dimmer",
        {
          attrs: {
            active: !_vm.ready || _vm.isOffsetMenuActive,
            blur: _vm.blurDimmer,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.hideOffsetMenu.apply(null, arguments)
            },
          },
        },
        [
          !_vm.ready
            ? _c(
                "div",
                {
                  staticClass: "ui active centered loader text",
                  class: { inverted: !_vm.blurDimmer },
                },
                [_vm._v("\n\t\t\tLoading\n\t\t")]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showHeader
        ? _c("Header", {
            attrs: {
              "is-menu-active": _vm.isOffsetMenuActive,
              "is-logged-in": _vm.isLoggedIn,
              "on-menu-button-clicked": _vm.showOffsetMenu,
              "on-logout-request": _vm.logout,
              "show-menu": _vm.showSideMenu,
              "back-link": _vm.backLink,
              modifiers: _vm.headerModifiers,
              "on-flag-click": function () {
                return (_vm.languageModalOpened = true)
              },
              deck: _vm.deck,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("OffsetMenu", {
        attrs: {
          "is-menu-active": _vm.isOffsetMenuActive,
          "on-language-click": _vm.onLanguageClick,
        },
      }),
      _vm._v(" "),
      _c(
        "PopOver",
        {
          directives: [
            {
              name: "clicked-outside",
              rawName: "v-clicked-outside",
              value: _vm.hideLanguagePopover,
              expression: "hideLanguagePopover",
            },
          ],
          staticStyle: { "z-index": "1000" },
          attrs: { show: _vm.languagePopoverOpened, position: "bottom" },
        },
        [
          _c(
            "div",
            { staticClass: "c-popover-body--one" },
            [
              _c("LanguagesRadio", {
                on: {
                  "language-changed": function ($event) {
                    _vm.languagePopoverOpened = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "main c-app__content" }, [
        _c(
          "div",
          [
            _c("PopOver", { attrs: { show: _vm.showErrorToast } }, [
              _c("div", { staticClass: "ui container" }, [
                _c("div", { staticClass: "ui error message" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(_vm.$t("errors.savingPageFailed")) +
                      "\n\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "PopOver",
              {
                attrs: { show: _vm.showToastMessage, position: "bottom-left" },
              },
              [
                _c("div", { staticClass: "c-popover-body--one" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("i", {
                      staticClass: "check circle icon green",
                      staticStyle: {
                        "font-size": "22px",
                        "margin-right": "var(--spacing-8)",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(_vm._s(_vm.toastMessage)),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("RouterView"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showFooter ? _c("Footer") : _vm._e(),
      _vm._v(" "),
      _c("SignModal", {
        attrs: {
          "modal-type": _vm.signModalType,
          "modal-redirect": _vm.signModalRedirect,
        },
      }),
      _vm._v(" "),
      _vm.$route.name ? _c("UserInfoModal") : _vm._e(),
      _vm._v(" "),
      _vm.feature("surveyInvitation")
        ? _c("SurveyInvitationPopover")
        : _vm._e(),
      _vm._v(" "),
      _c(
        "SuiModal",
        {
          attrs: { size: "tiny" },
          model: {
            value: _vm.languageModalOpened,
            callback: function ($$v) {
              _vm.languageModalOpened = $$v
            },
            expression: "languageModalOpened",
          },
        },
        [
          _c("ModalCloseButton", {
            model: {
              value: _vm.languageModalOpened,
              callback: function ($$v) {
                _vm.languageModalOpened = $$v
              },
              expression: "languageModalOpened",
            },
          }),
          _vm._v(" "),
          _c("SuiModalHeader", [_vm._v(_vm._s(_vm.$t("language")))]),
          _vm._v(" "),
          _c(
            "SuiModalContent",
            [
              _c("LanguagesRadio", {
                on: {
                  "language-changed": function ($event) {
                    _vm.languageModalOpened = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }