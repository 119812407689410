var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      ref: "container",
      staticClass: "c-topics",
      class: {
        "c-topics--big": _vm.big,
        "c-topics--inline": _vm.inline,
      },
    },
    _vm._l(_vm.topics, function (topic) {
      return _c(
        "li",
        {
          key: topic.key,
          ref: "topic",
          refInFor: true,
          on: {
            click: function (e) {
              return _vm.onClick(topic, e)
            },
          },
        },
        [
          _c(
            topic.href ? "a" : "span",
            {
              tag: "span",
              staticClass: "c-topics__topic",
              class: {
                "c-topics__topic--active":
                  topic.key === _vm.activeKey || _vm.active.includes(topic.key),
              },
              attrs: { href: topic.href, target: topic.href ? "_blank" : "" },
            },
            [
              !topic.href && _vm.displayCheck !== false
                ? _c("span", { staticClass: "c-topics__topic-check" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "c-topics__topic-checkmark",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z",
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "c-topics__emoji" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(("" + (topic.emoji || "")).trim()) +
                    "\n\t\t\t"
                ),
              ]),
              _vm._v("\n\t\t\t \n\t\t\t"),
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      ("" + (topic.text || _vm.$t(topic.textKey))).trim()
                    ) +
                    "\n\t\t\t"
                ),
              ]),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }