var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-answer-radio",
      class: {
        "c-answer-radio--compact": _vm.compact,
        "c-answer-radio--inline": _vm.inline,
        "c-answer-radio--darker": _vm.darker,
      },
      attrs: { role: "radiogroup" },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "div",
        { key: option.key, staticStyle: { display: "flex" } },
        [
          _c(
            "div",
            {
              ref: "radio",
              refInFor: true,
              staticClass: "c-answer-radio__radio",
              class: {
                "c-answer-radio__radio--checked": _vm.answer
                  ? _vm.answer === option.key
                  : _vm.value === option.key,
                "c-answer-radio__radio--valid":
                  (typeof _vm.value !== "undefined" &&
                    _vm.answer &&
                    _vm.answer !== _vm.value &&
                    _vm.value === option.key) ||
                  (_vm.answer === _vm.value && _vm.value === option.key),
                "c-answer-radio__radio--invalid":
                  typeof _vm.value !== "undefined" &&
                  _vm.answer &&
                  _vm.answer !== _vm.value &&
                  _vm.answer === option.key,
              },
              attrs: {
                "data-qa": "answers-radio",
                "aria-checked": _vm.answer
                  ? _vm.answer === option.key
                  : _vm.value === option.key
                  ? "true"
                  : "false",
                tabindex: _vm.answer
                  ? _vm.answer === option.key
                  : _vm.value === option.key
                  ? "0"
                  : "-1",
                role: "radio",
              },
              on: {
                click: function ($event) {
                  return _vm.change(option.key)
                },
                keyup: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "right", 39, $event.key, [
                        "Right",
                        "ArrowRight",
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 2) {
                      return null
                    }
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.nextElement.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.nextElement.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.prevElement.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft",
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 0) {
                      return null
                    }
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.prevElement.apply(null, arguments)
                  },
                ],
              },
            },
            [
              _c(
                "div",
                { staticClass: "c-answer-radio__radio-pointer-container" },
                [
                  _c("div", {
                    staticClass: "c-pointer",
                    class: {
                      "c-pointer--active": _vm.answer
                        ? _vm.answer === option.key
                        : _vm.value === option.key,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      option.nameLocalized
                        ? _vm.$t(option.nameLocalized)
                        : option.name
                    ) +
                    "\n\t\t\t"
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._t(option.key, null, { category: option }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }