<template>
	<div
		class="c-header-container"
		:class="{
			'c-header--zero-height': modifiers.includes('--zero-height'),
		}"
	>
		<div
			class="c-header"
			:class="{
				'c-header--with-secondary':
					modifiers.includes('--with-secondary') && !isLoggedIn,
				'c-header--no-background': noBackground,
				'c-header--fixed': modifiers.includes('--fixed'),
				'c-header--absolute': modifiers.includes('--absolute'),
				'c-header--landing': isLanding,
				'c-header--in-iframe': inIframe,
			}"
		>
			<div class="ui container">
				<header
					v-if="inIframe"
					class="c-header__grid"
				>
					<h1 class="h4 c-header__iframe-header">
						<RouterLink
							class="c-header__iframe-header-link"
							:to="{ name: 'listDetails', params: { id: seoDeckId }}"
						>
							{{ deck && deck.name }}
						</RouterLink>
					</h1>
					<div class="c-header__logo">
						<a
							style="padding: 0;"
							class="c-btn c-btn--tertiary c-btn--inverted"
							:href="deckPlayUrl"
							target="_blank"
						>
							<JustLogo class="c-landing-logo" />
						</a>
					</div>
				</header>
				<header
					v-else
					class="c-header__grid"
				>
					<RouterLink
						v-if="backLink"
						class="c-header__back-btn"
						:to="backLink"
					>
						<i class="c-header__back-btn-icon icon chevron left" />
					</RouterLink>
					<RouterLink
						class="c-header__logo"
						:to="{ name: 'dashboard' }"
					>
						<Logo class="c-landing-logo" />
					</RouterLink>
					<a
						v-if="showMenu"
						aria-label="Menu"
						class="c-header__menu"
						href
						@click.prevent="onMenuButtonClicked"
					>
						☰
					</a>
					<span class="c-header__dashboard-link">
						<RouterLink
							data-qa="header-dashboard-button"
							class="c-btn c-btn--tertiary c-btn--inverted c-btn--small"
							:to="{ name: 'dashboard' }"
						>
							{{ $t('dashboard.nav.dashboard') }}
						</RouterLink>
					</span>
					<nav
						v-if="!isLoggedIn"
						class="c-header__secondary"
					>
						<div class="c-header__language">
							<button
								type="button"
								class="c-button-language"
								@click="onFlagClick"
							>
								<img
									:alt="activeLocale.text"
									:src="activeLocale.flagUrl"
									class="c-button-language__flag"
								>
								<i class="ui icon chevron down c-button-language__icon" />
							</button>
							<span class="u-divider" />
						</div>
						<LoginButtons :analytics-label="analyticsLabel" />
					</nav>
					<div
						v-if="isLoggedIn"
						class="c-header__secondary"
					>
						<div class="c-header__language">
							<button
								type="button"
								class="c-button-language"
								@click="onFlagClick"
							>
								<img
									:alt="activeLocale.text"
									:src="activeLocale.flagUrl"
									class="c-button-language__flag"
								>
								<i class="ui icon chevron down c-button-language__icon" />
							</button>
							<span class="u-divider" />
						</div>
						<a
							class="c-btn c-btn--tertiary c-btn--inverted c-btn--small"
							href="/auth/logout"
							data-qa="header-logout-button"
							@click.prevent="onLogoutRequest"
						>
							{{ $t("header.logout") }}
						</a>
					</div>
				</header>
			</div>
		</div>
	</div>
</template>

<script>
	import { ctaLocations } from '@/assets/js/utils/analytics';
	import { locales } from '@/assets/js/i18n/configuration';
	import { mapState } from 'vuex';
	import JustLogo from '@/components/JustLogo';
	import Logo from '@/components/Logo';
	import { deckIdToSeo } from '@/assets/js/dataServices/utils';
	import ShareDeck from '@/components/ShareDeck';
	import LoginButtons from '@/components/buttons/LoginButtons';

	export default {
		name: 'Header',
		components: {
			LoginButtons,
			ShareDeck,
			JustLogo,
			Logo,
		},
		props: {
			isMenuActive: Boolean,
			onMenuButtonClicked: Function,
			onLogoutRequest: Function,
			isLoggedIn: Boolean,
			showMenu: Boolean,
			backLink: Object,
			deck: {
				type: Object,
				default: () => ({}),
			},
			modifiers: {
				type: Array,
				default: () => [],
			},
			onFlagClick: {
				type: Function,
				default: () => () => {},
			},
		},
		computed: {
			...mapState(['inIframe']),
			isLanding() {
				return this.modifiers.includes('--landing');
			},
			analyticsLabel() {
				return ctaLocations[this.isLanding ? 'landingHeader' : 'header'];
			},
			activeLocale() {
				return locales.find(l => l.code === this.activeLocaleCode);
			},
			activeLocaleCode() {
				return this.$i18n.locale;
			},
			deckPlayUrl() {
				if (!this.deck) {
					return '';
				}
				const deckId = deckIdToSeo(this.deck.name, this.deck._id);
				return location.origin + this.$router.resolve({ name: 'listPlay', params: { id: deckId } }).href;
			},
			seoDeckId() {
				if (!this.deck) {
					return '';
				}
				return deckIdToSeo(this.deck.name, this.deck._id);
			},
			noBackground() {
				const { headerNoBackgroundEnforced } = this.$store.state;

				return this.modifiers.includes('--no-background') && headerNoBackgroundEnforced !== false;
			},
		},
	};
</script>
<style scoped>
	.c-header__iframe-header {
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 16px;
	}

	.c-header__iframe-header-link {
		color: var(--pure-white);
	}

	.c-button-language {
		background: none;
		border: none;
		color: white;
		display: flex;
		align-items: center;
		padding: var(--spacing-8) var(--spacing-12);
		cursor: pointer;
	}

	.c-button-language__flag {
		height: 18px;
		position: relative;
		margin-right: var(--spacing-8);
		margin-top: -5px;
		margin-bottom: -5px;
	}

	.c-button-language__icon {
		margin: 0;
	}
</style>
