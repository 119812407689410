import { medias } from '@/store/matchMedia/medias';

const getDefaultState = () => ({
	[medias.minWidth558px]: false,
	[medias.minWidth800px]: false,
	[medias.minWidth992px]: false,
	[medias.minWidth340px]: false,
});

export default () => ({
	namespaced: true,
	state: getDefaultState(),
	getters: {
		minWidth340px(state) {
			return state[medias.minWidth340px];
		},
		minWidth558px(state) {
			return state[medias.minWidth558px];
		},
		minWidth800px(state) {
			return state[medias.minWidth800px];
		},
		minWidth992px(state) {
			return state[medias.minWidth992px];
		},
	},
	mutations: {
		setMatchMedia(state, { media, matches }) {
			state[media] = matches;
		},
	},

	actions: {
		setMatchMedia({ commit }, payload) {
			commit('setMatchMedia', payload);
		},
	},
});
