var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-header-container",
      class: {
        "c-header--zero-height": _vm.modifiers.includes("--zero-height"),
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "c-header",
          class: {
            "c-header--with-secondary":
              _vm.modifiers.includes("--with-secondary") && !_vm.isLoggedIn,
            "c-header--no-background": _vm.noBackground,
            "c-header--fixed": _vm.modifiers.includes("--fixed"),
            "c-header--absolute": _vm.modifiers.includes("--absolute"),
            "c-header--landing": _vm.isLanding,
            "c-header--in-iframe": _vm.inIframe,
          },
        },
        [
          _c("div", { staticClass: "ui container" }, [
            _vm.inIframe
              ? _c("header", { staticClass: "c-header__grid" }, [
                  _c(
                    "h1",
                    { staticClass: "h4 c-header__iframe-header" },
                    [
                      _c(
                        "RouterLink",
                        {
                          staticClass: "c-header__iframe-header-link",
                          attrs: {
                            to: {
                              name: "listDetails",
                              params: { id: _vm.seoDeckId },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.deck && _vm.deck.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-header__logo" }, [
                    _c(
                      "a",
                      {
                        staticClass: "c-btn c-btn--tertiary c-btn--inverted",
                        staticStyle: { padding: "0" },
                        attrs: { href: _vm.deckPlayUrl, target: "_blank" },
                      },
                      [_c("JustLogo", { staticClass: "c-landing-logo" })],
                      1
                    ),
                  ]),
                ])
              : _c(
                  "header",
                  { staticClass: "c-header__grid" },
                  [
                    _vm.backLink
                      ? _c(
                          "RouterLink",
                          {
                            staticClass: "c-header__back-btn",
                            attrs: { to: _vm.backLink },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "c-header__back-btn-icon icon chevron left",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "RouterLink",
                      {
                        staticClass: "c-header__logo",
                        attrs: { to: { name: "dashboard" } },
                      },
                      [_c("Logo", { staticClass: "c-landing-logo" })],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showMenu
                      ? _c(
                          "a",
                          {
                            staticClass: "c-header__menu",
                            attrs: { "aria-label": "Menu", href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onMenuButtonClicked.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\t☰\n\t\t\t\t")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "c-header__dashboard-link" },
                      [
                        _c(
                          "RouterLink",
                          {
                            staticClass:
                              "c-btn c-btn--tertiary c-btn--inverted c-btn--small",
                            attrs: {
                              "data-qa": "header-dashboard-button",
                              to: { name: "dashboard" },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.$t("dashboard.nav.dashboard")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isLoggedIn
                      ? _c(
                          "nav",
                          { staticClass: "c-header__secondary" },
                          [
                            _c("div", { staticClass: "c-header__language" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "c-button-language",
                                  attrs: { type: "button" },
                                  on: { click: _vm.onFlagClick },
                                },
                                [
                                  _c("img", {
                                    staticClass: "c-button-language__flag",
                                    attrs: {
                                      alt: _vm.activeLocale.text,
                                      src: _vm.activeLocale.flagUrl,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "ui icon chevron down c-button-language__icon",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "u-divider" }),
                            ]),
                            _vm._v(" "),
                            _c("LoginButtons", {
                              attrs: { "analytics-label": _vm.analyticsLabel },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isLoggedIn
                      ? _c("div", { staticClass: "c-header__secondary" }, [
                          _c("div", { staticClass: "c-header__language" }, [
                            _c(
                              "button",
                              {
                                staticClass: "c-button-language",
                                attrs: { type: "button" },
                                on: { click: _vm.onFlagClick },
                              },
                              [
                                _c("img", {
                                  staticClass: "c-button-language__flag",
                                  attrs: {
                                    alt: _vm.activeLocale.text,
                                    src: _vm.activeLocale.flagUrl,
                                  },
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "ui icon chevron down c-button-language__icon",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "u-divider" }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "c-btn c-btn--tertiary c-btn--inverted c-btn--small",
                              attrs: {
                                href: "/auth/logout",
                                "data-qa": "header-logout-button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onLogoutRequest.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.$t("header.logout")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }