export const MS_IN_A_DAY = 8.64e7;

export const formatDate = date => ([
	date.getFullYear(),
	(`0${date.getMonth() + 1}`).slice(-2),
	(`0${date.getDate()}`).slice(-2),
].join('-'));

export function normalize(array) {
	return array.map(({ _id, count }) => ({ _id, date: epochInDays(_id), count }));
}

export function epochInDays(dateString) {
	return Math.floor(new Date(dateString) / MS_IN_A_DAY);
}

export function epochIntoDateString(epoch) {
	return formatDate(new Date(epoch * MS_IN_A_DAY));
}

export function streak(items) {
	const map = new Map();

	let nearestDay = 0;
	items.forEach(({ date, count }) => {
		nearestDay = Math.max(nearestDay, date);
		return map.set(date, count);
	});

	let streak = 0;
	while (nearestDay--) {
		streak++;

		if (!map.has(nearestDay)) {
			break;
		}
	}

	return streak;
}

export function isInStreak(nearest, today) {
	return nearest + 1 >= today;
}
