var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-answer-radio",
      class: { "c-answer-radio--compact": _vm.compact },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "label",
        {
          key: option.key,
          staticClass: "c-answer-radio__radio",
          class: {
            "c-answer-radio__radio--valid":
              _vm.validAnswers &&
              _vm.validAnswers.includes(option.key) &&
              _vm.localValue.includes(option.key),
            "c-answer-radio__radio--invalid":
              _vm.validAnswers &&
              ((_vm.validAnswers.includes(option.key) &&
                !_vm.localValue.includes(option.key)) ||
                (!_vm.validAnswers.includes(option.key) &&
                  _vm.localValue.includes(option.key))),
          },
          attrs: { "data-qa": "answers-checkbox" },
        },
        [
          _c(
            "span",
            {
              staticClass: "c-answer-radio__radio-pointer-container",
              staticStyle: { position: "relative" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localValue,
                    expression: "localValue",
                  },
                ],
                staticClass: "checkbox",
                attrs: {
                  type: "checkbox",
                  name: "interest",
                  disabled: _vm.disabled,
                },
                domProps: {
                  value: option.key,
                  checked: _vm.localValue.includes(option.key),
                  checked: Array.isArray(_vm.localValue)
                    ? _vm._i(_vm.localValue, option.key) > -1
                    : _vm.localValue,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.localValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = option.key,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.localValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.localValue = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "fake-checkbox",
                class: {
                  "fake-checkbox--active": _vm.localValue.includes(option.key),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("p", [_vm._v("\n\t\t\t" + _vm._s(option.name) + "\n\t\t")]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }