import groupBy from 'lodash/groupBy';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'; // @TODO: Create an instance instead
import sortBy from 'lodash/sortBy';
import ObjectID from 'bson-objectid';
import * as Sentry from '@sentry/vue';
import { featureToggles } from '@/store/featureToggles';
import { getLearnListsFromServer, getOthersListsFromServer, getUserListsFromServer } from '@/assets/js/lists/listService';
import { seoToDeckId } from '@/assets/js/dataServices/utils';
import { calculateKnownFactor } from '@/assets/js/dataServices/knowledgeFactor';
import dateFromObjectId from '@/assets/js/dataServices/dateFromObjectId';
import { categories } from '@/assets/js/lists/categories';
import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
import playStats from '@/store/playStats';
import answers from '@/store/answers';
import stickers from '@/store/stickers';
import deckGenerator from '@/store/deckGenerator';
import matchMedia from '@/store/matchMedia';

Vue.use(Vuex);

function normalizeArrayOfDecks(decks) {
	const result = {};

	decks.forEach((deck) => {
		deck.categories = deck.categories || [];
		result[deck._id] = deck;
	});

	return result;
}

function filterDecks(state, predicate) {
	const result = {};

	for (const id in state.allLists) {
		const list = state.allLists[id];

		if (predicate(list)) {
			result[id] = list;
		}
	}

	return result;
}

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

const store = new Vuex.Store({
	state: {
		language: 'en',
		inIframe: inIframe(),
		ui: {
			isOffsetMenuActive: false,
			signModalType: '',
			signModalRedirect: '',
			configuration: 'default',
		},
		headerNoBackgroundEnforced: undefined,
		localStorage: {},
		user: {},
		listsBySearch: {},
		listsByUserId: {},
		similarDecksByDeckId: {},
		decksByTag: {},
		allLists: {},
		learnListsIds: [],
		userListsIds: [],
		othersListsIds: [],
		recentlyPlayedListsIds: [],
		mostFavouriteListsIds: [],
		serverErrors: [],
		toastMessage: '',
		nonGetRequests: [],
		loadingRoute: { loading: false },
		listsRequestCached: {
			user: false,
			others: false,
			lear: false,
			starred: false,
			recentlyPlayed: false,
			mostFavourite: false,
		},
		doneAnswers: {},
	},

	getters: {
		feature: state => key => state.localStorage[key] === true || state.localStorage[key] === 'true',
		configuration: (state) => {
			const configurations = {
				default: {
					noInstruction: false,
					showHeader: true,
					resultsModalLook: false,
					resultsShowRegisterSection: true,
					blurDimmer: false,
					scrollTopTopVisible: true,
					setTitle: true,
				},
				1: {
					noInstruction: true,
					showHeader: false,
					resultsModalLook: true,
					resultsShowRegisterSection: false,
					blurDimmer: true,
					theme: {
						'--main-background': 'white',
					},
					scrollTopTopVisible: true,
					setTitle: false,
				},
				2: {
					noInstruction: false,
					showHeader: false,
					resultsModalLook: true,
					resultsShowRegisterSection: false,
					blurDimmer: true,
					theme: {
						'--main-background': 'white',
					},
					scrollTopTopVisible: true,
					setTitle: false,
				},
			};

			return configurations[state.ui.configuration] || configurations.default;
		},
		isLoggedIn: (_, getters) => !!getters.userId,
		isAdmin: state => (state.user ? state.user.role === 'admin' : false),
		userId: state => (state.user ? state.user._id : null),
		userLists: state => state.userListsIds.map(id => state.allLists[id]),
		othersLists: state => state.othersListsIds.map(id => state.allLists[id]),
		learnLists: state => state.learnListsIds.map(id => state.allLists[id]),
		recentlyPlayedLists: state => state.recentlyPlayedListsIds.map(id => state.allLists[id]),
		mostFavouriteLists: state => state.mostFavouriteListsIds.map(id => state.allLists[id]),
		starredLists: (state, getters) => filterDecks(state, deck => deck.stars.includes(getters.userId)),
		queryContainsResults: state => query => query in state.listsBySearch,
		queryLists(state) {
			return (query) => {
				const ids = state.listsBySearch[query] || [];

				return ids.map(id => state.allLists[id]);
			};
		},
		listsByUserId(state) {
			return (userId) => {
				const ids = state.listsByUserId[userId] || [];

				return ids.map(id => state.allLists[id]);
			};
		},
		similarDecksByDeckId(state) {
			return (deckId) => {
				const ids = state.similarDecksByDeckId[deckId] || [];

				return ids.map(id => state.allLists[id]);
			};
		},
		suggestedNextDeckByDeckId(state, getters) {
			return (deckId) => {
				const similarDecks = getters.similarDecksByDeckId(deckId) || [];
				const fiveRecentlyPlayedListsIds = (state.recentlyPlayedListsIds || []).slice(0, 5);

				const notRecentlyPlayerSimilarDeck = similarDecks.find(({ _id }) => !fiveRecentlyPlayedListsIds.includes(_id));

				if (notRecentlyPlayerSimilarDeck) {
					return notRecentlyPlayerSimilarDeck;
				}

				// TODO: this could return first not played similar deck (J) from previously played deck instead of [C]
				// [N] - played deck
				// (M) - suggested deck
				//  A        B        C        D    <-- play sequence
				// --------------------------
				// [B]      [C]      [D]      [C]   <-- first suggested deck
				//  E        F       (J)
				//  F        H        K
				// G         I        L
				return similarDecks[0]; // [C]
			};
		},
		decksByTag(state) {
			return (deckId) => {
				const ids = state.decksByTag[deckId] || [];

				return ids.map(id => state.allLists[id]);
			};
		},
		getDeck: state => id => state.allLists[id],
		doneAnswers: state => state.doneAnswers,
		localStorage: state => state.localStorage,
	},
	mutations: {
		initLocalStorage(state) {
			const availableKeys = [
				'userInfoModalLastTimeDisplayed',
				'surveyInvitationPopoverLastTimeDisplayed',
				'aiGeneratorPromoBannerDisplayed',
				'satisfactionFeedbackFormLastTimeDisplayed',
				'satisfactionFeedbackFormSubmitted',
				'preferredLanguage',
				'dismissedAIGeneratorBanner',
				'aiGeneratorLanding',
				...featureToggles.map(({ key }) => key),
			];

			availableKeys.forEach((key) => {
				Vue.set(state.localStorage, key, localStorage.getItem(key));
			});
		},

		setLocalStorage(state, { key, value }) {
			Vue.set(state.localStorage, key, value);
		},

		configuration(state, id) {
			state.ui.configuration = id;
		},

		markCache(state, type) {
			state.listsRequestCached[type] = true;
		},

		enforceHeaderNoBackground(state, value) {
			state.headerNoBackgroundEnforced = value;
		},

		storeLists(state, payload) {
			for (const id in payload) {
				if (!(id in state.allLists)) {
					const deck = payload[id];

					deck.stars = deck.stars || [];
					if (deck.categoryType === 'default') {
						deck.categoryType = categories.DEFAULT;
					} else if (deck.categoryType === 'custom') {
						deck.categoryType = categories.CUSTOM;
					} else if (deck.categoryType === 'word_custom') {
						deck.categoryType = categories.WORD_CUSTOM;
					} else if (deck.categoryType === 'single_choice') {
						deck.categoryType = categories.SINGLE_CHOICE;
					} else if (deck.categoryType === 'multiple_choice') {
						deck.categoryType = categories.MULTIPLE_CHOICE;
					} else {
						console.warn('Invalid category type');
						deck.categoryType = categories.DEFAULT;
					}

					Vue.set(state.allLists, id, deck);
				}
			}
		},

		loadingRoute(state, payload) {
			state.loadingRoute = payload;
		},

		setLearnListsIds(state, ids) {
			state.learnListsIds = ids;
		},

		setRecentlyPlayedListsIds(state, ids) {
			state.recentlyPlayedListsIds = ids;
		},

		setMostFavouriteListsIds(state, ids) {
			state.mostFavouriteListsIds = ids;
		},

		setUserListsIds(state, ids) {
			state.userListsIds = ids.slice();
		},

		appendUserListsIds(state, ids) {
			state.userListsIds = state.userListsIds.concat(ids);
		},

		appendDecksByUserId(state, { decksIds, userId }) {
			const currentIds = state.listsByUserId[userId];

			Vue.set(state.listsByUserId, userId, currentIds.concat(decksIds));
		},

		prependUserListsIds(state, ids) {
			state.userListsIds = ids.slice().concat(state.userListsIds);
		},

		prependRecentlyPlayedListsId(state, id) {
			const currIndex = state.recentlyPlayedListsIds.indexOf(id);

			const list = state.recentlyPlayedListsIds.slice();
			// Deck deck currently exist in an array we have to remove first and then add at the beginning.
			if (currIndex !== -1) {
				list.splice(currIndex, 1);
			}

			list.unshift(id);

			state.recentlyPlayedListsIds = list;
		},

		setOthersListsIds(state, ids) {
			state.othersListsIds = ids.slice();
		},

		appendOthersListsIds(state, ids) {
			state.othersListsIds = state.othersListsIds.concat(ids);
		},

		appendMostFavouriteListsIds(state, ids) {
			state.mostFavouriteListsIds = state.mostFavouriteListsIds.concat(ids);
		},

		appendRecentlyPlayedListsIds(state, ids) {
			state.recentlyPlayedListsIds = state.recentlyPlayedListsIds.concat(ids);
		},

		setUser(state, user) {
			state.user = user;
		},

		submitUserInfo(state, { key, value }) {
			const metadata = (state.user && state.user.metadata) || {};
			Vue.set(state.user, 'metadata', metadata);
			Vue.set(state.user.metadata, key, value);
		},

		updateUserDisplayName(state, displayName) {
			Vue.set(state.user, 'displayName', displayName);
		},

		logout(state) {
			state.user = {};
		},

		setLists(state, lists) {
			state.lists = lists;
		},

		removeDeck(state, { deckId }) {
			Vue.delete(state.allLists, deckId);
			const userDeckIndex = state.userListsIds.indexOf(deckId);
			if (userDeckIndex !== -1) {
				state.userListsIds.splice(userDeckIndex, 1);
			}
			const recentlyPlayedDeckIndex = state.recentlyPlayedListsIds.indexOf(deckId);
			if (recentlyPlayedDeckIndex !== -1) {
				state.recentlyPlayedListsIds.splice(recentlyPlayedDeckIndex, 1);
			}
			const mostFavouriteDeckIndex = state.mostFavouriteListsIds.indexOf(deckId);
			if (mostFavouriteDeckIndex !== -1) {
				state.mostFavouriteListsIds.splice(mostFavouriteDeckIndex, 1);
			}
		},

		setGuesses(state, { guesses, listId }) {
			const deck = state.allLists[listId];

			deck.words.forEach((word) => {
				Vue.set(word, 'guesses', guesses[word._id] || []);
				Vue.set(word, 'knownNumber', calculateKnownFactor(word.guesses));
			});
		},

		submitCardGuess(state, {
			guess, deckId, isLoggedIn, cardId,
		}) {
			const deck = state.allLists[deckId];
			const card = deck.words.find(card => card._id === guess.word);

			if (!card) {
				Sentry.captureException('Could not find card in a deck', {
					level: 'error',
					extra: {
						deckId,
						wordsLength: deck.words.length,
						word: guess.word,
						isLoggedIn,
						cardId,
						cardIdType: typeof cardId,
						wordType: typeof guess.word,
					},
				});
			}

			if (!card.guesses) {
				Vue.set(card, 'guesses', []);
			}

			card.guesses.unshift(guess);
			card.knownNumber = calculateKnownFactor(card.guesses);
		},

		toggleStar(state, { list, hasStarred }) {
			const userId = state.user._id;

			if (hasStarred) {
				list.stars.push(userId);
			} else {
				const index = list.stars.indexOf(userId);
				list.stars.splice(index, 1);
			}
		},

		addLearnList(state, listId) {
			state.learnListsIds.push(listId);
		},

		removeLearnList(state, listId) {
			const index = state.learnListsIds.indexOf(listId);
			state.learnListsIds.splice(index, 1);
		},

		addDecksSearchResult(state, { decksIds, query }) {
			if (state.listsBySearch[query]) {
				return;
			}

			Vue.set(state.listsBySearch, query, decksIds);
		},

		addDecksByUserId(state, { decksIds, userId }) {
			if (state.listsByUserId[userId]) {
				return;
			}

			Vue.set(state.listsByUserId, userId, decksIds);
		},

		addSimilarDecksByDeckId(state, { decksIds, deckId }) {
			if (state.similarDecksByDeckId[deckId]) {
				return;
			}

			Vue.set(state.similarDecksByDeckId, deckId, decksIds);
		},

		addDecksByTag(state, { decksIds, tagName }) {
			if (state.decksByTag[tagName]) {
				return;
			}

			Vue.set(state.decksByTag, tagName, decksIds);
		},

		removeSection(state, { courseId, sectionId }) {
			const deck = state.allLists[courseId];

			const index = deck.sections.findIndex(s => s._id === sectionId);

			deck.sections.splice(index, 1);
		},

		addCourseSection(state, { courseId, section, index }) {
			const deck = state.allLists[courseId];

			deck.sections.splice(index, 0, section);
		},

		changeSectionPosition(state, { courseId, sectionId, newIndex }) {
			function arrayMove(arr, fromIndex, toIndex) {
				const element = arr[fromIndex];
				arr.splice(fromIndex, 1);
				arr.splice(toIndex, 0, element);
			}

			const sections = state.allLists[courseId].sections;
			const oldIndex = sections.findIndex(section => section._id == sectionId);

			arrayMove(sections, oldIndex, newIndex);
		},

		changeSectionTitle(state, { courseId, sectionId, title }) {
			const sections = state.allLists[courseId].sections;
			const section = sections.find(section => section._id == sectionId);

			section.title = title;
		},

		addDeckCard(state, { deckId, card }) {
			const deck = state.allLists[deckId];

			card.guesses = [];
			card.knownNumber = calculateKnownFactor(card.guesses);

			deck.words.push(card);
		},

		removeDeckCard(state, { deckId, cardId }) {
			const deck = state.allLists[deckId];
			const cardIndex = deck.words.findIndex(card => card._id === cardId);

			deck.words.splice(cardIndex, 1);
		},

		saveDeckName(state, { deckId, deckName }) {
			const deck = state.allLists[deckId];

			deck.name = deckName;
		},

		saveCategory(state, { deckId, category }) {
			const deck = state.allLists[deckId];

			deck.categoryType = category;
		},

		addCategory(state, { deckId, category }) {
			const deck = state.allLists[deckId];
			deck.categories.push(category);
		},

		addCategories(state, { deckId, categories }) {
			const deck = state.allLists[deckId];
			deck.categories.push(...categories);
		},

		saveCardEdit(state, {
			deckId,
			cardId,
			front,
			back,
			validAnswer,
			validAnswers,
			question,
			answers,
		}) {
			const deck = state.allLists[deckId];
			const card = deck.words.find(card => card._id === cardId);

			if (typeof front === 'string' || typeof back == 'string') {
				card.word = front;
				card.translation = back;
			}

			if (validAnswer || validAnswers || question || answers) {
				card.validAnswer = validAnswer;
				card.validAnswers = validAnswers;
				card.question = question;
				card.answers = answers;
			}
		},

		removeCategory(state, { deckId, categoryId }) {
			const deck = state.allLists[deckId];
			const categoryIndex = deck.categories.findIndex(category => category._id === categoryId);

			deck.categories.splice(categoryIndex, 1);

			deck.words.forEach((word) => {
				if (word.category === categoryId) {
					Vue.delete(word, 'category');
				}
			});
		},
		changeCategoryName(state, { deckId, categoryId, name }) {
			const deck = state.allLists[deckId];
			const category = deck.categories.find(category => category._id === categoryId);

			// @TODO: Check if this works, as it was dry coding.
			category.displayName = name;
		},
		changeCardCategory(state, { deckId, cardId, categoryId }) {
			const deck = state.allLists[deckId];
			const card = deck.words.find(card => card._id === cardId);

			Vue.set(card, 'category', categoryId);
		},

		patchDeck(state, { deckId, changes }) {
			const deck = state.allLists[deckId];

			for (const [key, value] of Object.entries(changes)) {
				Vue.set(deck, key, value);
			}
		},

		setDeckImage(state, { deckId, image }) {
			const deck = state.allLists[deckId];

			Vue.set(deck, 'image', image);
		},

		toggleDeckPrivate(state, { deckId }) {
			const deck = state.allLists[deckId];

			deck.private = !deck.private;
		},

		setToastMessage(state, { message }) {
			state.toastMessage = message;
		},

		registerServerError(state, { error }) {
			state.serverErrors.push(error);
		},
		registerNonGetRequest(state, { config }) {
			state.nonGetRequests.push(config);
		},
		resolveNonGetRequest(state, { config }) {
			const index = state.nonGetRequests.findIndex(req => req === config);

			state.nonGetRequests.splice(index, 1);
		},
		hideOffsetMenu(state) {
			state.ui.isOffsetMenuActive = false;
		},
		showOffsetMenu(state) {
			state.ui.isOffsetMenuActive = true;
		},
		changeModalType(state, payload) {
			const { modalType, redirect } = typeof payload === 'string' ? { modalType: payload } : payload;

			state.ui.signModalType = modalType;
			state.ui.signModalRedirect = redirect;
		},
		addAnswerToDone(state, answer) {
			state.doneAnswers = Object.assign(state.doneAnswers, answer);
		},
	},
	actions: {
		setLocalStorage({ commit }, payload) {
			const { key, value } = payload;

			localStorage.setItem(key, value);
			commit('setLocalStorage', payload);
		},

		configuration({ commit }, id) {
			commit('configuration', id);
		},

		addAnswerToDone({ commit }, answer) {
			commit('addAnswerToDone', answer);
		},

		loadingRoute({ commit }, payload) {
			commit('loadingRoute', payload);
		},

		submitUserInfo({ commit }, payload) {
			const { key, value } = payload;

			axios.patch('/auth/user', {
				metadata: {
					[key]: value,
				},
			});
			commit('submitUserInfo', payload);
		},

		async updateUserDisplayName({ commit }, displayName) {
			await axios.patch('/auth/user', { displayName });
			commit('updateUserDisplayName', displayName);
		},

		async userResolve({ commit, state, getters }) {
			const { isLoggedIn } = getters;
			let { user } = state;

			if (isLoggedIn) {
				return user;
			}

			if ('__USER__' in window) {
				user = window.__USER__;
				delete window.__USER__;
			} else {
				const response = await axios.get('/auth/user');
				user = response.data;
			}
			commit('setUser', user);

			return user;
		},

		logout({ commit, dispatch }) {
			axios.get('/auth/logout');

			dispatch('playStats/resetState');

			commit('logout');
		},

		async loadUserProfile(_, { userId }) {
			const { data } = await axios.get(`/users/${userId}`);

			return data;
		},

		async fetchSpecificUserLists({ commit }, { userId }) {
			let decks = await getUserListsFromServer(userId);
			decks = normalizeArrayOfDecks(decks);

			commit('storeLists', decks);
			commit('addDecksByUserId', { decksIds: Object.keys(decks), userId });
		},

		async loadMoreSpecificUserLists({ commit, state }, { userId }) {
			let decks = await getUserListsFromServer(userId, undefined, state.listsByUserId[userId].length);

			const userListsIds = decks.map(({ _id }) => _id);

			decks = normalizeArrayOfDecks(decks);

			commit('storeLists', decks);
			commit('appendDecksByUserId', { decksIds: userListsIds, userId });
		},

		async fetchUserLists({ commit, dispatch }) {
			if (this.state.listsRequestCached.user) {
				return;
			}
			commit('markCache', 'user');

			const user = await dispatch('userResolve');
			let lists = await getUserListsFromServer(user._id, user);

			const userListsIds = lists.map(list => list._id);
			lists = normalizeArrayOfDecks(lists);

			commit('storeLists', lists);
			commit('setUserListsIds', userListsIds);
		},

		async loadMoreUserLists({ commit, dispatch, state }) {
			const user = await dispatch('userResolve');
			let lists = await getUserListsFromServer(user._id, user, state.userListsIds.length);

			const userListsIds = lists.map(list => list._id);

			lists = normalizeArrayOfDecks(lists);

			commit('storeLists', lists);
			commit('appendUserListsIds', userListsIds);
		},

		async fetchOthersLists({ commit }) {
			if (this.state.listsRequestCached.others) {
				return;
			}
			commit('markCache', 'others');

			let lists = await getOthersListsFromServer();

			const othersListsIds = lists.map(list => list._id);
			lists = normalizeArrayOfDecks(lists);

			commit('storeLists', lists);
			commit('setOthersListsIds', othersListsIds);
		},

		async loadMoreOthersLists({ commit, state }) {
			let lists = await getOthersListsFromServer(state.othersListsIds.length);

			const userListsIds = lists.map(list => list._id);

			lists = normalizeArrayOfDecks(lists);

			commit('storeLists', lists);
			commit('appendOthersListsIds', userListsIds);
		},

		async loadMoreRecentlyPlayedLists({ commit, state }) {
			const res = await axios.get('/lists/recently-played', {
				params: {
					limit: 12,
					skip: state.recentlyPlayedListsIds.length || 0,
				},
			});
			let lists = res.data;

			const listsIds = lists.map(list => list._id);

			lists = normalizeArrayOfDecks(lists);

			commit('storeLists', lists);
			commit('appendRecentlyPlayedListsIds', listsIds);
		},

		async loadMoreMostFavouriteLists({ commit, state }) {
			const res = await axios.get('/lists/most-favourite', {
				params: {
					limit: 12,
					skip: state.mostFavouriteListsIds.length || 0,
				},
			});
			let lists = res.data;

			const listsIds = lists.map(list => list._id);

			lists = normalizeArrayOfDecks(lists);

			commit('storeLists', lists);
			commit('appendMostFavouriteListsIds', listsIds);
		},

		async fetchLearnLists({ commit }) {
			if (this.state.listsRequestCached.learn) {
				return;
			}
			commit('markCache', 'learn');

			const lists = normalizeArrayOfDecks(await getLearnListsFromServer());

			commit('setLearnListsIds', Object.keys(lists));
			commit('storeLists', lists);
		},

		async fetchRecentlyPlayedLists({ commit }) {
			if (this.state.listsRequestCached.recentlyPlayed) {
				return;
			}
			commit('markCache', 'recentlyPlayed');

			// Wait for all guesses to be send to the server before fetching recently played
			try {
				const allPromises = window.promises && window.promises.get('all');
				if (allPromises) {
					await allPromises;
				}
			} catch (e) {
				logAnalyticsEvent(...events.semanticErrorThrown, 'Failed to fetch all guesses promise');
			}
			const res = await axios.get('/lists/recently-played');
			const lists = normalizeArrayOfDecks(res.data);

			commit('setRecentlyPlayedListsIds', Object.keys(lists));
			commit('storeLists', lists);
		},

		async fetchSimilarDecks({ commit, getters }, { deckId }) {
			if (this.state.similarDecksByDeckId[deckId]) {
				return getters.similarDecksByDeckId(deckId);
			}

			const res = await axios.get(`/lists/similar/${deckId}`);

			const decks = normalizeArrayOfDecks(res.data);

			commit('storeLists', decks);
			commit('addSimilarDecksByDeckId', { decksIds: Object.keys(decks), deckId });

			return decks;
		},

		async fetchDecksByTag({ commit, getters }, { tagName }) {
			if (this.state.decksByTag[tagName]) {
				return getters.decksByTag(tagName);
			}

				const res = await axios.get(`/lists/tag/${tagName}`);

			const decks = normalizeArrayOfDecks(res.data);

			commit('storeLists', decks);
			commit('addDecksByTag', { decksIds: Object.keys(decks), tagName });

			return decks;
		},

		async fetchMostFavouriteLists({ commit }) {
			if (this.state.listsRequestCached.mostFavourite) {
				return;
			}
			commit('markCache', 'mostFavourite');

			const res = await axios.get('/lists/most-favourite', {
				params: {
					limit: 12,
					skip: 0,
				},
			});
			const lists = normalizeArrayOfDecks(res.data);

			commit('setMostFavouriteListsIds', Object.keys(lists));
			commit('storeLists', lists);
		},

		async fetchStarredLists({ commit }) {
			if (this.state.listsRequestCached.starred) {
				return;
			}
			commit('markCache', 'starred');

			const res = await axios.get('/lists/starred');
			const lists = normalizeArrayOfDecks(res.data);

			commit('storeLists', lists);
		},

		enforceHeaderNoBackground({ commit }, value) {
			commit('enforceHeaderNoBackground', value);
		},

		async fetchLists({ commit }, listIds) {
			const query = listIds.map((n, index) => `ids[${index}]=${n}`).join('&');
			const { data } = await axios.get(`/lists/?${query}`);

			const lists = normalizeArrayOfDecks(data);
			commit('storeLists', lists);
		},

		async fetchList({ commit }, listId) {
			listId = seoToDeckId(listId);

			let data;
			if (window.__DECK__ && window.__DECK__._id === listId) {
				data = window.__DECK__;
				delete window.__DECK__;
			} else {
				const res = await axios.get(`/lists/${listId}`);
				data = res.data;
			}

			const decks = normalizeArrayOfDecks([data]);

			commit('storeLists', decks);
		},

		async fetchGuesses({ commit, getters }, listId) {
			listId = seoToDeckId(listId);
			// Wait for global promise to PUSH guesses for that deck, and then start fetching.
			try {
				const promise = window.promises && window.promises.get(listId);
				if (promise) {
					await promise;
				}
			} catch (e) {
				logAnalyticsEvent(...events.semanticErrorThrown, `Failed to fetch guesses promise for deck: ${listId}`);
			}

			const url = `/lists/${listId}/guesses`;
			let guesses;
			// get it from the session storage
			if (getters.isLoggedIn) {
				guesses = (await axios.get(url)).data;
			} else if (sessionStorage) {
				guesses = JSON.parse(sessionStorage.getItem(url) || '[]');
			}

			guesses.forEach((guess) => {
				guess.created = dateFromObjectId(guess._id);
			});

			sortBy(guesses, guess => guess.created.getTime());

			guesses.reverse();

			guesses = groupBy(guesses, 'word');

			commit('setGuesses', { guesses, listId });

			return guesses;
		},

		async toggleStar({ commit }, { listId }) {
			const userId = this.state.user._id;
			const list = this.state.allLists[listId];
			const hasUserStarred = list.stars.indexOf(userId) !== -1;

			if (hasUserStarred) {
				axios.delete(`/lists/${list._id}/star`);
			} else {
				axios.post(`/lists/${list._id}/star`);
			}

			commit('toggleStar', { list, userId, hasStarred: !hasUserStarred });
		},

		async saveDeckName({ commit }, { deckId, deckName }) {
			const url = `/lists/${deckId}`;

			axios.patch(url, { name: deckName });

			commit('saveDeckName', { deckId, deckName });
		},

		async saveCategory({ commit }, { deckId, category }) {
			const categoriesMap = new Map([
				[categories.DEFAULT, 'default'],
				[categories.CUSTOM, 'custom'],
				[categories.SINGLE_CHOICE, 'single_choice'],
				[categories.MULTIPLE_CHOICE, 'multiple_choice'],
			]);

			const categoryType = categoriesMap.get(category);

			axios.patch(`/lists/${deckId}`, { categoryType });

			commit('saveCategory', { deckId, category });
		},

		async addCategories({ commit }, { deckId, categories }) {
			const url = `/lists/${deckId}/categories`;
			await axios.post(url, { categories });

			commit('addCategories', { deckId, categories });
		},

		async addCategory({ commit }, { deckId, categoryName }) {
			const url = `/lists/${deckId}/categories`;
			const res = await axios.post(url, {
				displayName: categoryName,
			});
			const category = res.data;

			commit('addCategory', { deckId, category });
		},

		async changeCardCategory({ commit }, { deckId, cardId, categoryId }) {
			axios.patch(`/lists/${deckId}/word/${cardId}`, {
				category: categoryId,
			});

			commit('changeCardCategory', { deckId, cardId, categoryId });
		},

		async saveCardEdit({ commit }, {
			deckId,
			cardId,
			front,
			back,
			// Custom answers
			category,
			// Single choice or Multiple choice
			validAnswer,
			validAnswers,
			question,
			answers,
		}) {
			const payload = {};

			if (typeof front === 'string' || typeof back === 'string') {
				payload.word = front;
				payload.translation = back;
			}

			if (validAnswer || validAnswers || question || answers) {
				payload.validAnswer = validAnswer;
				payload.validAnswers = validAnswers;
				payload.question = question;
				payload.answers = answers;
			}

			if (category) {
				payload.category = category;
			}

			axios.patch(`/lists/${deckId}/word/${cardId}`, payload);

			commit('saveCardEdit', {
				deckId,
				cardId,
				front,
				back,
				validAnswer,
				validAnswers,
				question,
				answers,
			});
			if (category) {
				commit('changeCardCategory', { deckId, cardId, categoryId: category });
			}
		},

		async removeCategory({ commit }, { deckId, categoryId }) {
			await axios.delete(`/lists/${deckId}/categories/${categoryId}`);

			commit('removeCategory', { deckId, categoryId });
		},

		async changeCategoryName({ commit }, { deckId, categoryId, name }) {
			axios.patch(
				`/lists/${deckId}/categories/${categoryId}`,
				{
					displayName: name,
				},
			);

			commit('changeCategoryName', { deckId, categoryId, name });
		},

		async toggleDeckPrivate({ commit }, { deckId }) {
			const deck = this.state.allLists[deckId];

			axios.patch(
				`/lists/${deckId}`,
				{ private: !deck.private },
			);
			commit('toggleDeckPrivate', { deckId });
		},

		async removeDeck({ commit }, { deckId }) {
			await axios.delete(`/lists/${deckId}`);

			// @TODO: Check if this works, as it was dry coding.
			commit('removeDeck', { deckId });
		},

		async addLearnList({ commit }, listId) {
			// @TODO: Check if this works, as it was dry coding.
			await axios.post('/lists/learn', { listId });

			commit('addLearnList', listId);
		},

		async removeLearnList({ commit }, listId) {
			// @TODO: Check if this works, as it was dry coding.
			await axios.delete(`/lists/learn/${listId}`);

			commit('removeLearnList', listId);
		},

		async submitCardGuess(
			{ commit, state },
			{
				deckId,
				cardId,
				known,
				sessionId,
				isLoggedIn,
			},
		) {
			const url = `/lists/${deckId}/guesses`;
			const deck = state.allLists[deckId];
			const numberOfWords = deck.words.length;
			const payloadGuess = {
				word: cardId,
				known,
				numberOfWords,
				sessionId,
			};
			let guess;
			if (isLoggedIn) {
				const payload = {
					guesses: [payloadGuess],
				};
				const { data } = await axios.post(url, payload);
				guess = data[0];
			} else {
				guess = {
					...payloadGuess,
					_id: String(ObjectID()),
				};

				if (sessionStorage) {
					const guesses = JSON.parse(sessionStorage.getItem(url) || '[]');
					guesses.push(guess);
					sessionStorage.setItem(url, JSON.stringify(guesses));
				}
			}

			guess.created = dateFromObjectId(guess._id);

			commit('submitCardGuess', {
				guess, deckId, isLoggedIn, cardId,
			});
			commit('prependRecentlyPlayedListsId', deckId);
			commit('playStats/incrementGuesses');
		},

		async createLocalDeck({ commit }, {
			_id,
			name,
			private: _private,
			type,
			words,
			categories,
			categoryType,
		}) {
			const decks = normalizeArrayOfDecks([{
				_id,
				name,
				private: _private,
				type,
				words,
				categories,
				categoryType,
			}]);

			commit('storeLists', decks);
		},

		async createDeck(
			{ commit },
			{
				name,
				private: _private,
				type,
				words,
				categories,
			},
		) {
			const { data } = await axios.post('/lists', {
				name,
				private: _private,
				type,
				words,
				categories,
			});

			const decks = normalizeArrayOfDecks([data]);

			commit('storeLists', decks);
			commit('prependUserListsIds', [data._id]);

			return data;
		},

		async searchDecks({ commit }, query) {
			let { data: decks } = await axios.get(`/lists?name=${query}`);

			decks = normalizeArrayOfDecks(decks);

			commit('storeLists', decks);
			commit('addDecksSearchResult', { decksIds: Object.keys(decks), query });
		},

		async patchDeck({ commit }, { deckId, changes, isGen }) {
			const url = isGen ? `/lists/gen/${deckId}` : `/lists/${deckId}`;
			await axios.patch(url, changes);

			commit('patchDeck', { deckId, changes });
		},

		async setDeckImage({ commit }, { deckId, image }) {
			commit('setDeckImage', { deckId, image });
		},

		removeSection({ commit }, { courseId, sectionId }) {
			const url = `/lists/${courseId}/sections/${sectionId}`;

			axios.delete(url);

			commit('removeSection', { courseId, sectionId });
		},

		async addCourseSection({ commit }, {
			courseId,
			_id,
			title,
			type,
			content,
			deckId,
			index,
		}) {
			const url = `/lists/${courseId}/sections`;

			const { data: section } = await axios.post(url, {
				_id,
				title,
				type,
				content,
				deckId,
				index,
			});

			commit('addCourseSection', { courseId, section, index });
		},

		async changeSectionTitle({ commit }, { courseId, sectionId, title }) {
			axios.patch(
				`/lists/${courseId}/sections/${sectionId}`,
				{
					title,
				},
			);

			commit('changeSectionTitle', { courseId, sectionId, title });
		},

		async changeSectionPosition({ commit }, { courseId, sectionId, newIndex }) {
			axios.post(`/lists/${courseId}/sections/${sectionId}/position`, { newIndex });

			commit('changeSectionPosition', { courseId, sectionId, newIndex });
		},

		async changeCourseSectionContent({ commit }, { courseId, sectionId, content }) {
			axios.patch(
				`/lists/${courseId}/sections/${sectionId}`,
				{
					content,
				},
			);

			// TODO: Implement commit if needed?
			console.log(commit);
			// commit('changeCourseSectionContent', { courseId, sectionId, content });
		},

		async addDeckQuestion({ commit }, {
			deckId,
			question,
			answers,
			validAnswer,
			validAnswers,
		}) {
			const url = `/lists/${deckId}/words`;
			const { data: card } = await axios.post(url, {
				question,
				answers,
				validAnswer,
				validAnswers,
			});

			commit('addDeckCard', { deckId, card });
		},

		async addDeckCard({ commit }, {
			deckId,
			front,
			back,
			categoryId,
		}) {
			const url = `/lists/${deckId}/words`;
			const { data: card } = await axios.post(url, {
				word: front,
				translation: back,
				categoryId,
			});

			commit('addDeckCard', { deckId, card });
		},

		async removeDeckCard({ commit }, { deckId, cardId }) {
			axios.delete(`/lists/${deckId}/words/${cardId}`);

			commit('removeDeckCard', { deckId, cardId });
		},

		async importCards({ commit }, { deckId, cards }) {
			const url = `/lists/${deckId}/words`;
			const result = await axios.post(url, cards);
			cards = result.data;

			cards.forEach(card => commit('addDeckCard', { deckId, card }));
		},

		setToastMessage({ commit }, { message }) {
			commit('setToastMessage', { message });
		},

		registerServerError({ commit }, { error }) {
			commit('registerServerError', { error });
		},
		registerNonGetRequest({ commit }, { config }) {
			commit('registerNonGetRequest', { config });
		},
		resolveNonGetRequest({ commit }, { config }) {
			commit('resolveNonGetRequest', { config });
		},
		hideOffsetMenu({ commit }) {
			commit('hideOffsetMenu');
		},
		showOffsetMenu({ commit }) {
			commit('showOffsetMenu');
		},
		changeModalType({ commit }, type) {
			commit('changeModalType', type);
		},
	},
	modules: {
		playStats: playStats(),
		answers: answers(),
		stickers: stickers(),
		deckGenerator: deckGenerator(),
		matchMedia: matchMedia(),
	},
});

axios.interceptors.request.use((config) => {
	if (config.method !== 'get') {
		store.dispatch('registerNonGetRequest', { config });
	}

	return config;
});

axios.interceptors.response.use((response) => {
	const { config } = response;
	if (config.method !== 'get') {
		store.dispatch('resolveNonGetRequest', { config });
	}

	return response;
}, (error) => {
	const { config } = error;
	if (config.method !== 'get') {
		store.dispatch('resolveNonGetRequest', { config });
	}

	return Promise.reject(error);
});

axios.interceptors.response.use(res => res, (error) => {
	if (error.config.method !== 'get') {
		if (error.config.url !== '/auth/login') {
			store.dispatch('registerServerError', { error });
		}
	}

	return Promise.reject(error);
});

export default store;
