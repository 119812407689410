var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DecksContainer",
    {
      attrs: {
        decks: _vm.localDecks,
        title: _vm.$t("dashboard.recentlyPlayed.title"),
        "fetch-decks": _vm.fetchDecks,
        "request-more-decks": _vm.requestMoreDecks,
        "results-amount": _vm.resultsAmount,
        "more-link": _vm.resultsAmount ? "recentlyPlayed" : "",
      },
    },
    [
      _c("template", { slot: "noDecksMessage" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.$t(
                "dashboard.recentlyPlayed.playAnyDecksAndThoseWillAppearHere"
              )
            ) +
            "\n\t"
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }