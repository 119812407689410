var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !isNaN(_vm.localProgress)
    ? _c(
        "div",
        {
          staticClass: "c-progress",
          style: {
            "--c-progress-progress": _vm.localProgress + "%",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "c-progress__progress-indicator",
              class: {
                "c-progress__progress-indicator--animated": !_vm.delayAnimation,
              },
            },
            [
              _c("span", { staticClass: "c-progress__progress-text" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.progress) +
                    "%" +
                    _vm._s(_vm.progressDiffReadable) +
                    "\n\t\t"
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }