var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DecksContainer", {
    attrs: {
      decks: _vm.decks,
      title: _vm.$t("dashboard.mostFavourite.title"),
      "fetch-decks": _vm.fetchDecks,
      "request-more-decks": _vm.requestMoreDecks,
      "results-amount": _vm.resultsAmount,
      "more-link": _vm.resultsAmount ? "mostFavourite" : "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }