var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-footer" }, [
    _c(
      "div",
      { staticClass: "ui container c-footer__content" },
      [
        _c(
          "a",
          {
            staticClass:
              "ui circular facebook icon mini button c-footer__button",
            attrs: {
              title: _vm.$t("multideckerOnFacebook"),
              "aria-label": _vm.$t("multideckerOnFacebook"),
              href: "https://facebook.com/multidecker",
              target: "_blank",
            },
          },
          [_c("i", { staticClass: "facebook f icon" })]
        ),
        _vm._v(" "),
        _c("RouterLink", { attrs: { to: { name: "privacyPolicy" } } }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.$t("privacyPolicy")) + "\n\t\t"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }