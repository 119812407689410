var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DecksContainer",
    {
      attrs: {
        decks: _vm.localDecks,
        title: _vm.displayTitle ? _vm.$t("dashboard.yours.title") : undefined,
        "fetch-decks": _vm.fetchDecks,
        "request-more-decks": _vm.requestMoreDecks,
        "results-amount": _vm.resultsAmount,
        "more-link": _vm.resultsAmount ? "yours" : "",
        "show-set-theme-image-button": "",
        "show-cta-buttons": _vm.showCtaButtons,
        "is-link": _vm.isLink,
        "selected-deck-id": _vm.selectedDeckId,
      },
      on: { "card-clicked": _vm.onCardClicked },
    },
    [
      _c("template", { slot: "noDecksMessage" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.$t("dashboard.feelsLikeMakingAnyNewOnes")) +
            "\n\t"
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }