var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 250 200",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M63 134h91c.515 0 1.017-.056 1.5-.161.483.105.985.161 1.5.161h52a7 7 0 1 0 0-14h-6a7 7 0 1 1 0-14h19a7 7 0 1 0 0-14h-22a7 7 0 1 0 0-14h-64a7 7 0 1 0 0-14H79a7 7 0 1 0 0 14H39a7 7 0 1 0 0 14h25a7 7 0 1 1 0 14H24a7 7 0 1 0 0 14h39a7 7 0 1 0 0 14zm163 0a7 7 0 1 0 0-14 7 7 0 0 0 0 14z",
          fill: "var(--grey-8)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "m86.278 139.123-4.07.572a4 4 0 0 1-4.517-3.404L66.556 57.069a4 4 0 0 1 3.404-4.518l78.231-10.994a4 4 0 0 1 4.518 3.404l.957 6.808",
          fill: "#fff",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "m88.805 134.712-3.696.526a3.619 3.619 0 0 1-4.096-3.085l-9.996-71.925a3.646 3.646 0 0 1 3.098-4.108l71.037-10.096a3.618 3.618 0 0 1 4.097 3.085l.859 6.18 9.205 66.599c.306 2.212-1.22 4.257-3.407 4.566-.024.003-.047.007-.071.009l-67.03 8.249z",
          fill: "var(--grey-9)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "m86.278 139.123-4.07.572a4 4 0 0 1-4.517-3.404L66.556 57.069a4 4 0 0 1 3.404-4.518l78.231-10.994a4 4 0 0 1 4.518 3.404l.957 6.808M154.5 56.38l.5 3.12",
          stroke: "var(--grey-16)",
          "stroke-width": "2.5",
          "stroke-linecap": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "m102.844 58.04 78.567 8.259a2.75 2.75 0 0 1 2.448 3.022l-8.362 79.562a2.75 2.75 0 0 1-3.023 2.447l-78.567-8.258a2.749 2.749 0 0 1-2.447-3.022l8.362-79.562a2.75 2.75 0 0 1 3.022-2.447z",
          fill: "#fff",
          stroke: "var(--grey-16)",
          "stroke-width": "2.5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "m115 128.727 25.861 2.687M120.272 78l25.86 2.688L120.272 78zm-1.241 11.936 42.77 4.444-42.77-4.444zm-1.343 12.93 42.769 4.445-42.769-4.445zm-1.344 12.93 42.769 4.445-42.769-4.445z",
          stroke: "var(--grey-16)",
          "stroke-width": "2.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }