<template>
	<div
		class="swing-card-shell"
		:class="{
			'swing-card-shell--back': localVisibleSide === 'Back',
			'swing-card-shell--front': localVisibleSide === 'Front'
		}"
	>
		<div class="swing-card-shell__size-definer" />
		<transition name="show">
			<Progress
				v-if="displayProgress && localVisibleSide === 'Front'"
				style="--c-progress-offset: var(--swing-card-shell-border-width)"
				:progress="progress"
				:previous-progress="previousProgress"
				:delay-animation="delayAnimation"
			/>
		</transition>
		<div
			v-if="displayStickers"
			ref="stickersContainer"
			class="swing-card-shell__stickers droppable"
			@drop="onDrop($event)"
			@dragover.prevent
			@dragenter.prevent
			@build="onBuild"
		>
			<img
				v-for="{ name, x, y } in cardStickers"
				:key="`${name}-${x}-${y}`"
				class="sticker-image sticker-image--dropped"
				:src="`/img/stickers/${name}.png`"
				:alt="`sticker-${name}`"
				:style="{ top: `${y}`, left: `${x}` }"
			>
		</div>
		<div class="swing-card-shell__look" />
		<div class="swing-card-shell__content">
			<slot />
		</div>
		<div class="swing-card-shell__content swing-card-shell__content--centered">
			<slot name="centered" />
		</div>
	</div>
</template>
<script>
	import Progress from '@/components/progress/Progress';
	import { mapGetters } from 'vuex';

	export const progressDiffReadable = (progressDiff) => {
		if (progressDiff === 0) {
			return '';
		}
		const sign = progressDiff > 0 ? '+' : '';
		return `(${sign}${progressDiff}%)`;
	};

	export default {
		components: {
			Progress,
		},
		props: {
			cardId: {
				type: String,
				default: '',
			},
			displayProgress: {
				type: Boolean,
				default: false,
			},
			localVisibleSide: {
				type: String,
				default: 'Front',
			},
			progress: {
				type: Number,
				default: 0,
			},
			previousProgress: {
				type: Number,
				default: 0,
			},
			delayAnimation: {
				type: Boolean,
				default: true,
			},
			displayStickers: {
				type: Boolean,
				default: true,
			},
		},
		data: () => ({
			localProgress: NaN,
			timeoutId: NaN,
		}),
		computed: {
			...mapGetters('stickers', [
				'getStickers',
			]),
			cardStickers() {
				return this.getStickers(this.cardId);
			},
			progressDiff() {
				return this.progress - this.previousProgress;
			},
			progressDiffReadable() {
				return progressDiffReadable(this.progressDiff);
			},
		},
		watch: {
			previousProgress() {
				this.handleChange();
			},
			currentProgress() {
				this.handleChange();
			},
			delayAnimation(newVal) {
				if (newVal === false) {
					this.timeoutId && clearTimeout(this.timeoutId);
					this.timeoutId = setTimeout(() => {
						this.localProgress = this.progress;
					}, 100);
				}
			},
		},
		created() {
			this.handleChange();
		},
		methods: {
			handleChange() {
				if ([this.previousProgress, this.progress].some(isNaN)) {
					return;
				}

				this.localProgress = this.previousProgress;
			},
			onBuild() {
				alert('yes');
			},
			onDrop(evt) {
				evt = evt.detail || evt;

				const sticker = evt.dataTransfer.getData('sticker');
				if (!sticker) {
					return;
				}

				const mouseDownOffsetX = evt.dataTransfer.getData('mouseDownOffsetX');
				const mouseDownOffsetY = evt.dataTransfer.getData('mouseDownOffsetY');

				const canvasBoundingRect = this.$refs.stickersContainer.getBoundingClientRect();
				const canvasWidth = canvasBoundingRect.width;
				const canvasHeight = canvasBoundingRect.height;

				const fullPositionX = evt.offsetX - mouseDownOffsetX;
				const fullPositionY = evt.offsetY - mouseDownOffsetY;

				this.$store.dispatch('stickers/addSticker', {
					cardId: this.cardId,
					name: sticker,
					x: `${(fullPositionX / canvasWidth) * 100}%`,
					y: `${(fullPositionY / canvasHeight) * 100}%`,
				});
				this.$emit('sticker-drop');
			},
		},
	};
</script>
<style scoped>
.show-enter-active, .show-leave-active {
	transition: opacity .5s ease;
}

.show-enter, .show-leave-to {
	opacity: 0;
}

.sticker-image {
	width: 64px;
	height: 64px;
}

.sticker-image--dropped {
	width: calc(100% * calc(64/230));
	height: calc(100% * calc(64/calc(230 * var(--swing-card-dimension-ratio))));
	position: absolute;
	pointer-events: none;
}
</style>
