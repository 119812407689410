var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { "modal-variation": "large" },
          model: {
            value: _vm.imagesGalleryModalVisible,
            callback: function ($$v) {
              _vm.imagesGalleryModalVisible = $$v
            },
            expression: "imagesGalleryModalVisible",
          },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("deckCreate.selectThemeForYourDeck")) +
                "\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c("label", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("deckCreate.searchForImages")) +
                  "\n\t\t\t\t"
              ),
              _c("div", { staticClass: "ui mini input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.imagesSearchValue,
                      expression: "imagesSearchValue",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.imagesSearchValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.imagesSearchValue = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm.imageUploadFeature
              ? _c("span", [
                  _c(
                    "span",
                    { staticStyle: { margin: "0 var(--spacing-8)" } },
                    [_vm._v("or")]
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "c-btn c-btn--small" }, [
                    _vm._v("\n\t\t\t\t\tUpload file\n\t\t\t\t\t"),
                    _c("input", {
                      attrs: { type: "file", accept: "image/png, image/jpeg" },
                      on: { input: _vm.onFileInput },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _vm.errorWhileFetchingDataFromImagesEndPoint
              ? _c("div", { staticClass: "error-state" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("deckCreate.somethingWentWrong")) +
                      "\n\t\t\t"
                  ),
                ])
              : _c("div", [
                  _c("div", { staticClass: "c-gallery-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "c-gallery" },
                      _vm._l(_vm.images, function (image) {
                        return _c(
                          "div",
                          {
                            key: image._id,
                            ref: "wrappers",
                            refInFor: true,
                            class: {
                              "c-gallery__image-wrapper": true,
                              "c-gallery__image-wrapper--selected":
                                image.id ===
                                (_vm.currentImage && _vm.currentImage.id),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectAnImage(image)
                              },
                            },
                          },
                          [
                            _c("img", {
                              class: {
                                "c-gallery__image": true,
                                "c-gallery__image--pending":
                                  !image.ready || !image.src,
                              },
                              attrs: { alt: "", src: image.src },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-gallery__button-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ui right labeled icon button",
                        attrs: { type: "button" },
                        on: { click: _vm.loadMore },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.$t("deckCreate.seeMore")) +
                            "\n\t\t\t\t\t\t"
                        ),
                        _c("i", { staticClass: "chevron down icon" }),
                      ]
                    ),
                  ]),
                ]),
          ]),
          _vm._v(" "),
          _c("template", { slot: "actions" }, [
            _c(
              "button",
              {
                staticClass: "ui circular gray button",
                attrs: { type: "button" },
                on: { click: _vm.skip },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.$t("buttons.skip")) + "\n\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ui circular green button",
                class: { loading: _vm.uploading },
                attrs: { type: "button" },
                on: { click: _vm.setImage },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.$t("deckCreate.setImage")) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }