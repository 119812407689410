<template>
	<SuiModal
		v-model="isModalOpen"
		size="small"
		:closable="step !== 0"
	>
		<i
			v-if="step !== 0"
			class="close icon"
			@click="isModalOpen = false"
		/>
		<SuiModalContent scrolling>
			<FocusLock>
				<div
					class="c-sign-popup u-overlapping-elements"
				>
					<div
						v-if="step === 0"
						class="c-user-info-modal__step u-overlapping-elements__child"
					>
						<div>
							<h2
								class="c-sign-popup__header c-heading c-heading--third"
								style="padding-bottom: var(--spacing-16)"
							>
								<span>{{ $t('userInfo.almostThere') }}</span>
							</h2>
							<p
								class="c-sign-popup__subheader p"
								style="margin-bottom: var(--spacing-32)"
							>
								{{ $t('userInfo.finishCreatingYourAccount') }}
							</p>
							<form
								novalidate
								name="userDisplayNameForm"
								data-vv-scope="userDisplayNameForm"
								@submit.prevent="submitUserDisplayName"
							>
								<label>
									<span style="margin-bottom: var(--spacing-4); color: var(--grey-73); font-size: 16px; display: block">
										{{ $t('userInfo.yourFullName') }}
									</span>
									<span
										class="ui small input"
										:class="{
											error: shouldDisplayUserDisplayNameValidationError,
										}"
									>
										<input
											v-model="displayName"
											v-validate="userDisplayNameValidation.rules"
											v-focus
											name="displayName"
											style="width: 300px;"
											type="text"
										>
									</span>
								</label>
								<TransitionExpand>
									<div
										v-if="shouldDisplayUserDisplayNameValidationError"
										style="color: var(--red); margin-top: var(--spacing-8);"
										class="sign-via-email__submit-error"
									>
										{{ errors.first('userDisplayNameForm.displayName') }}
									</div>
								</TransitionExpand>

								<div style="margin-bottom: var(--spacing-24)" />

								<p style="margin-bottom: var(--spacing-4); color: var(--grey-73); font-size: 16px;">
									{{ $t('userInfo.yourEmail') }}
								</p>
								<p>{{ user.email }}</p>
								<button
									type="submit"
									style="margin-top: var(--spacing-16)"
									class="c-sign-popup__button ui button green"
									data-qa="user-info-modal__continue"
								>
									{{ $t('userInfo.continue') }}
								</button>
							</form>
						</div>
					</div>
					<Transition name="fade-alternative">
						<div
							v-if="step === 1"
							class="c-user-info-modal__step u-overlapping-elements__child"
						>
							<h2
								class="c-sign-popup__header c-heading c-heading--third"
								style="padding-bottom: var(--spacing-24)"
							>
								<span>
									{{ $t('userInfo.weWantToTailorYourExperience') }}
								</span>
							</h2>
							<div>
								<p
									class="c-sign-popup__subheader p"
									style="margin-bottom: var(--spacing-12)"
								>
									{{ $t('userInfo.howWouldYouDescribeYourself') }}
								</p>

								<button
									class="c-sign-popup__button ui button green"
									data-qa="user-info-modal__i-am-a-teacher"
									@click="yourself('teacher')"
								>
									{{ $t('userInfo.iAmATeacher') }}
								</button>
								<br>
								<button
									class="c-sign-popup__button ui button green"
									@click="yourself('student')"
								>
									{{ $t('userInfo.iAmAStudent') }}
								</button>
							</div>
						</div>
					</Transition>
					<Transition name="fade-alternative">
						<div
							v-if="step === 2"
							class="c-user-info-modal__step u-overlapping-elements__child"
						>
							<h2
								class="c-sign-popup__header c-heading c-heading--third"
								style="padding-bottom: var(--spacing-24)"
							>
								<span>
									{{ $t('userInfo.weWantToTailorYourExperience') }}
								</span>
							</h2>
							<div>
								<p
									class="c-sign-popup__subheader p"
									style="margin-bottom: var(--spacing-12)"
								>
									{{ $t('userInfo.selectTopics') }}
								</p>
								<Topics
									:topics="topics"
									:active="interests"
									:on-click="selectInterest"
								/>
								<button
									style="margin-top: var(--spacing-16)"
									class="c-sign-popup__button ui button green"
									data-qa="user-info-modal__continue"
									@click="submitInterests()"
								>
									{{ $t('userInfo.continue') }}
								</button>
							</div>
						</div>
					</Transition>
				</div>
			</FocusLock>
		</SuiModalContent>
	</SuiModal>
</template>
<script>
	import FocusLock from 'vue-focus-lock';
	import { userDisplayNameValidation } from '@/assets/js/utils/veevalidate';
	import {
		logAnalyticsEvent, events,
	} from '@/assets/js/utils/analytics';
	import { daysBetween } from '@/assets/js/utils/dateTime';
	import Topics from '@/components/topics/Topics';
	import TransitionExpand from '@/components/TransitionExpand';

	export default {
		name: 'SignModal',
		components: {
			TransitionExpand,
			FocusLock,
			Topics,
		},
		data() {
			return {
				isModalOpen: false,
				step: 1,
				topics: [
					{ key: 'english',						emoji: '', 		textKey: 'userInfo.english'						},
					{ key: 'foreign_languages', emoji: '',	 	textKey: 'userInfo.foreign_languages'	},
					{ key: 'geography', 				emoji: '🌍', 	textKey: 'userInfo.geography'					},
					{ key: 'mathematics',				emoji: '📐', 	textKey: 'userInfo.mathematics'				},
					{ key: 'biology',						emoji: '🧬', 	textKey: 'userInfo.biology'						},
					{ key: 'entertainment',			emoji: '🍿', 	textKey: 'userInfo.entertainment'			},
					{ key: 'technology',				emoji: '💻', 	textKey: 'userInfo.technology'				},
					{ key: 'business',					emoji: '💼', 	textKey: 'userInfo.business' 					},
					{ key: 'science',						emoji: '🔬', 	textKey: 'userInfo.science' 					},
					{ key: 'law',								emoji: '⚖️',	textKey: 'userInfo.law' 							},
					{ key: 'art_&_culture',			emoji: '🎭', 	textKey: 'userInfo.art_&_culture' 		},
					{ key: 'sport',							emoji: '🚴', 	textKey: 'userInfo.sport' 						},
					{ key: 'history',						emoji: '📜', 	textKey: 'userInfo.history' 					},
					{ key: 'others',						emoji: '💬', 	textKey: 'userInfo.others' 						},
				],
				userDisplayNameValidation,
				interests: [],
				displayName: '',
			};
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
			provider() {
				return this.$route.query.provider;
			},
			justCreated() {
				return this.$route.query.justCreated === 'true';
			},
			redirect() {
				return this.$route.fullPath;
			},
			shouldDisplayModal() {
				if (
					this.$route.query['create-deck-gen-ai'] === 'true'
					|| this.$route.query['create-deck']
				) {
					return false;
				}

				if (!this.$store.getters.isLoggedIn) {
					return false;
				}

				const { metadata } = this.$store.state.user;

				// Is means that definitely saw that modal before.
				if (metadata && metadata.type) {
					return false;
				}

				// Only enforcing modal when provider is magic, to be safe.
				if (this.justCreated && this.provider === 'magic') {
					return true;
				}

				const userInfoModalLastTimeDisplayed = this.$store.getters.localStorage.userInfoModalLastTimeDisplayed;
				if (!userInfoModalLastTimeDisplayed) {
					return true;
				}

				const daysSinceModalDisplayed = daysBetween(Date.now(), userInfoModalLastTimeDisplayed);

				return daysSinceModalDisplayed > 30;
			},
			shouldDisplayUserDisplayNameValidationError() {
				const touched = this.fields.$userDisplayNameForm && this.fields.$userDisplayNameForm.displayName.touched;
				const hasErrors = this.errors.has('userDisplayNameForm.displayName');

				return touched && hasErrors;
			},
		},
		watch: {
			isModalOpen(newVal) {
				if (newVal === false) {
					if (Object.keys(this.$route.query).length) {
						this.$router.replace({ query: {} });
					}

					this.closeModal();
				}

				if (newVal === true) {
					logAnalyticsEvent(...events.userInfoModalDisplay);
					this.$store.dispatch('setLocalStorage', { key: 'userInfoModalLastTimeDisplayed', value: Date.now() });
				}
			},
		},
		created() {
			if (this.justCreated && this.provider === 'magic') {
				this.step = 0;
				this.displayName = this.user.displayName;
			}

			if (this.shouldDisplayModal) {
				this.isModalOpen = true;
			}
		},
		methods: {
			closeModal() {
				this.$store.dispatch('changeModalType', '');
			},
			yourself(type) {
				this.step++;

				this.$store.dispatch('submitUserInfo', { key: 'type', value: type });
				logAnalyticsEvent(...events.userInfoTypeFormSubmit, type);
			},
			selectInterest({ key }) {
				if (this.interests.includes(key)) {
					const index = this.interests.indexOf(key);
					if (index > -1) {
						this.interests.splice(index, 1);
					}
				} else {
					this.interests.push(key);
				}
			},
			submitInterests() {
				this.$store.dispatch('submitUserInfo', { key: 'interests', value: this.interests });
				this.isModalOpen = false;
				logAnalyticsEvent(...events.userInfoInterestsFormSubmit, this.interests.toString());
			},
			submitUserDisplayName() {
				// If user didn't change any values, then we don't want to validate.
				if (this.user.displayName === this.displayName) {
					this.step = 1;
					return;
				}

				if (this.errors.has('userDisplayNameForm.displayName') || this.displayName.length === 0) {
					return;
				}

				this.$store.dispatch('updateUserDisplayName', this.displayName);
				this.step = 1;
			},
		},
	};
</script>
<style>
	.c-user-info-modal__step {
		min-height: 271px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.fade-alternative-enter-active {
		transition: all 500ms ease;
	}

	.fade-alternative-leave-active {
		transition: all 0ms ease;
	}

	.fade-alternative-enter, .fade-alternative-leave-to {
		opacity: 0;
		transform: translateY(-20px);
	}
</style>
