<template>
	<ul
		ref="container"
		class="c-topics"
		:class="{
			'c-topics--big': big,
			'c-topics--inline': inline,
		}"
	>
		<li
			v-for="topic in topics"
			:key="topic.key"
			ref="topic"
			@click="(e) => onClick(topic, e)"
		>
			<span
				:is="topic.href ? 'a' : 'span'"
				:href="topic.href"
				:target="topic.href ? '_blank' : ''"
				class="c-topics__topic"
				:class="{
					'c-topics__topic--active': topic.key === activeKey || active.includes(topic.key),
				}"
			>
				<span
					v-if="!topic.href && displayCheck !== false"
					class="c-topics__topic-check"
				>
					<svg
						class="c-topics__topic-checkmark"
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
					</svg>
				</span>
				<span class="c-topics__emoji">
					{{ `${topic.emoji || ''}`.trim() }}
				</span>
				&nbsp;
				<span>
					{{ `${topic.text || $t(topic.textKey)}`.trim() }}
				</span>
			</span>
		</li>
	</ul>
</template>
<script>
	export default {
		name: 'SignModal',
		props: {
			topics: {
				type: Array,
				default: () => [],
			},
			activeKey: {
				type: String,
			},
			active: {
				type: Array,
				default: () => [],
			},
			onClick: {
				type: Function,
				default: () => () => {},
			},
			displayCheck: {
				type: Boolean,
				default: true,
			},
			big: {
				type: Boolean,
				default: false,
			},
			inline: {
				type: Boolean,
				default: false,
			},
		},
		watch: {
			activeKey(newValue) {
				const item = this.$refs.topic[newValue];
				if (!item) {
					return;
				}
				const leftOffsetOfTheItemMiddle = item.offsetLeft + (item.offsetWidth / 2);

				this.$emit('scroll-to-middle', leftOffsetOfTheItemMiddle);
			},
		},
	};
</script>
