<template>
	<div
		class="c-deck-info-card c-deck-info-card--with-photo"
		:class="{
			'c-deck-info-card--selected': selectedDeckId === this.list._id,
		}"
		@click="cardClick"
	>
		<component
			:is="isLink ? 'RouterLink' : 'div'"
			:target="inIframe ? '_blank' : undefined"
			class="c-deck-info-card__main-link"
			:class="{ 'c-deck-info-card--loading': loading }"
			data-qa="list-card"
			:event="loading ? '' : 'click'"
			:to="{ name: list.type === 'article' ? 'courseDetails' : 'listDetails', params: { id: seoDeckId }}"
		>
			<article class="c-deck-info-card__container">
				<Notch
					simple
					class="c-deck-info-card__notch"
				>
					<AddToFavourites
						:color="loading && '#dbd0bd'"
						:list="list"
					/>
				</Notch>
				<header
					class="c-deck-info-card__header"
					style="position: relative"
				>
					<img
						v-if="image"
						v-lazy="image"
						class="c-deck-info-card__image"
						alt
					>
					<div
						v-else
						class="c-deck-info-card__image-placeholder"
					>
						<button
							v-if="showSetThemeImageButton"
							class="c-btn c-btn--white c-btn--small c-btn--rounded"
							type="button"
							@click.stop.prevent="$refs.imageUploader.open()"
						>
							<i class="c-btn__icon image icon" />
							<span
								class="c-btn__icon-text"
							>
								{{ $t('deckCreate.setThemeImage') }}
							</span>
						</button>
					</div>
				</header>
				<section class="c-deck-info-card__section">
					<div
						v-if="showCtaButtons"
						class="c-deck-info-card__btn-cta-wrapper"
					>
						<RouterLink
							:target="inIframe ? '_blank' : undefined"
							:title="$t('play')"
							class="c-btn c-btn--white c-btn--small c-btn--rounded c-deck-info-card__btn-cta"
							:to="{ name: 'listPlay',
								params: { id: seoDeckId }}"
						>
							<i class="c-btn__icon play icon" />
							<span class="c-btn__icon-text">
								{{ $t('play') }}
							</span>
						</RouterLink>
						&nbsp;&nbsp;
						<RouterLink
							:target="inIframe ? '_blank' : undefined"
							:title="$t('preview')"
							class="c-btn c-btn--white c-btn--small c-btn--rounded c-deck-info-card__btn-cta"
							:to="{ name: list.type === 'article' ? 'courseDetails' : 'listDetails',
								params: { id: seoDeckId }}"
						>
							<i class="c-btn__icon eye icon" />
							<span class="c-btn__icon-text">
								{{ $t('preview') }}
							</span>
						</RouterLink>
						<button
							v-if="isAdmin"
							type="button"
							title="Edit metadata"
							class="c-btn c-btn--white c-btn--small c-btn--rounded c-btn--just-icon c-deck-info-card__btn-cta"
							@click.stop.prevent="modalShown = true;"
						>
							<i class="c-btn__icon icon">🤖</i>
						</button>
					</div>
					<span class="c-deck-info-card__title">
						<strong>
							<span v-if="list.private">
								<i
									:title="$t('thisDeckIsPrivate')"
									class="lock icon yellow fitted"
								/>
								&nbsp;
							</span>
							<span
								v-if="list.type === 'article'"
								class="c-deck-info-card__type"
							>
								{{ $t('article') }}
							</span>
							{{ list.name }}
						</strong>
					</span>
					<div
						v-if="isAdmin"
						class="c-deck-info-card__section-counter"
						style="margin-bottom: var(--spacing-8); display: flex; flex-direction: column; gap: var(--spacing-8);"
					>
						<span>Field of Study: <strong>{{ list.gen_fieldOfStudy }}</strong></span>
						<span>Level: <strong>{{ list.gen_level }}</strong></span>
						<span>Languages: <strong>{{ list.gen_languages }}</strong></span>
						<span>Language Front: <strong>{{ list.gen_languageFront }}</strong></span>
						<span>Language Back: <strong>{{ list.gen_languageBack }}</strong></span>
						<div style="display: inline-flex; flex-wrap: wrap; gap: var(--spacing-4);">
							<Tag
								v-for="tag in list.gen_tags"
								:key="tag"
								:tag-name="tag"
								:highlighted="sourceDeckTags.includes(tag)"
							/>
						</div>
					</div>
					<div class="c-deck-info-card__section-counter">
						<span class="c-deck-info-card__cards-amount">
							<strong><i class="ui clone icon outline" /></strong>
							<strong>
								{{ list.words.length }}
							</strong>
						</span>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<span class="c-deck-info-card__stars-amount">
							<strong><i class="ui heart icon outline" /></strong>
							<strong>{{ stars }}</strong>
						</span>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<span class="c-deck-info-card__section-details">
							<UserProfile
								:style="loading ? { opacity: 0 } : {}"
								:list="list"
							/>
						</span>
					</div>
				</section>
			</article>
		</component>
		<ImageUploader
			ref="imageUploader"
			:deck-id="list._id"
		/>
		<ManageDeckMetadataModal
			v-if="isAdmin"
			v-model="modalShown"
			:deck="list"
		/>
	</div>
</template>
<script>
	import { mapGetters, mapState } from 'vuex';
	import { deckIdToSeo } from '@/assets/js/dataServices/utils';
	import UserProfile from '@/components/userProfile/UserProfile';
	import Notch from '@/components/notch/Notch';
	import AddToFavourites from '@/components/AddToFavourites';
	import ImageUploader from '@/components/deckEdit/ImageUploader';
	import ManageDeckMetadataModal from '@/components/ManageDeckMetadataModal.vue';
	import Tag from '@/components/Tag.vue';
	import { getDeckImage } from '@/assets/js/utils/meta';

	export default {
		components: {
			Tag,
			ManageDeckMetadataModal,
			ImageUploader,
			AddToFavourites,
			UserProfile,
			Notch,
		},
		props: {
			list: {
				type: Object,
			},
			sourceDeck: {
				type: Object,
				default() {
					return {};
				},
			},
			loading: {
				type: Boolean,
			},
			showSetThemeImageButton: {
				type: Boolean,
			},
			showCtaButtons: {
				type: Boolean,
				default: true,
			},
			isLink: {
				type: Boolean,
				default: true,
			},
			selectedDeckId: {
				type: [String, null],
				default: null,
			},
		},
		data: () => ({
			modalShown: false,
		}),
		computed: {
			...mapState(['inIframe']),
			...mapGetters(['isAdmin']),
			sourceDeckTags() {
				if (!this.sourceDeck) {
					return [];
				}

				return this.sourceDeck.gen_tags || [];
			},
			userId() {
				return this.$store.getters.userId;
			},
			isFavourites() {
				return this.list.stars.indexOf(this.userId) !== -1;
			},
			stars() {
				return (this.list.stars || []).length;
			},
			seoDeckId() {
				return deckIdToSeo(this.list.name, this.list._id);
			},
			image() {
				return getDeckImage(this.list) || '';
			},
		},
		methods: {
			cardClick() {
				this.$emit('card-clicked', {
					deckId: this.list._id,
				});
			},
		},
	};
</script>
