var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-feedback-answers",
      class: {
        "c-feedback-answers--no-spacing": _vm.noSpacing,
        "c-feedback-answers--left-aligned": _vm.leftAligned,
      },
    },
    _vm._l(_vm.optionsArray, function (option) {
      return _c(
        "div",
        {
          key: option.sentiment,
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "align-items": "center",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "c-feedback-answers__answer",
              class: {
                "c-feedback-answers__answer--minimized":
                  _vm.highlighted && option.sentiment !== _vm.highlighted,
              },
              attrs: {
                type: "button",
                title: _vm.$t(option.textKey),
                "aria-label": _vm.$t(option.textKey),
              },
              on: {
                click: function ($event) {
                  return _vm.onFeedback(option.sentiment)
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(option.emoji))]),
              _vm._v(" "),
              _vm.hasText
                ? _c(
                    "span",
                    { staticClass: "c-feedback-answers__answer-text" },
                    [_vm._v(_vm._s(_vm.$t(option.textKey2)))]
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }