export const loadScript = (url, callback) => {
	const head = document.head;
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = url;
	script.defer = true;
	script.crossorigin = 'anonymous';

	// Then bind the event to the callback function.
	// There are several events for cross browser compatibility.
	script.onreadystatechange = callback;
	script.onload = callback;

	// Fire the loading
	head.appendChild(script);
};

export const initFB = (callback) => {
	if (!window.fbAsyncInit) {
		window.fbAsyncInit = function () {
			FB.init({
				appId: '1152850601417405',
				xfbml: true,
				version: 'v18.0',
			});
		};
		loadScript('https://connect.facebook.net/en_US/sdk.js', callback);
	}
};
