var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "c-btn c-btn--secondary c-btn--small",
        class: { "c-btn--inverted": _vm.inverted },
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.signIn()
          },
        },
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("header.signIn")) + "\n\t")]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "u-divider" }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "c-btn c-btn--cta c-btn--small",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.signUp()
          },
        },
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("header.createAnAccount")) + "\n\t")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }