<template>
	<div
		class="notch"
		:class="{
			'notch--simple': simple,
			'notch--top-left': left,
			[`notch--${size}`]: size || false
		}"
		:style="{
			'--notch-background-color': color || null
		}"
	>
		<div class="notch__content">
			<slot />
		</div>
		<div
			v-if="!simple"
			class="notch__aside-one"
		>
			<div class="notch__aside-inner" />
		</div>
		<div
			v-if="!simple"
			class="notch__aside-two"
		>
			<div class="notch__aside-inner" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Notch',
		props: {
			left: {
				type: Boolean,
				default: false,
			},
			size: {
				type: String,
				default: '',
			},
			color: {
				type: String,
				default: '',
			},
			simple: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
