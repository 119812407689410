var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SuiModal",
    {
      attrs: { size: "small", closable: _vm.step !== 0 },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _vm.step !== 0
        ? _c("i", {
            staticClass: "close icon",
            on: {
              click: function ($event) {
                _vm.isModalOpen = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "SuiModalContent",
        { attrs: { scrolling: "" } },
        [
          _c("FocusLock", [
            _c(
              "div",
              { staticClass: "c-sign-popup u-overlapping-elements" },
              [
                _vm.step === 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "c-user-info-modal__step u-overlapping-elements__child",
                      },
                      [
                        _c("div", [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "c-sign-popup__header c-heading c-heading--third",
                              staticStyle: {
                                "padding-bottom": "var(--spacing-16)",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("userInfo.almostThere"))),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "c-sign-popup__subheader p",
                              staticStyle: {
                                "margin-bottom": "var(--spacing-32)",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.$t("userInfo.finishCreatingYourAccount")
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              attrs: {
                                novalidate: "",
                                name: "userDisplayNameForm",
                                "data-vv-scope": "userDisplayNameForm",
                              },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitUserDisplayName.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("label", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "var(--spacing-4)",
                                      color: "var(--grey-73)",
                                      "font-size": "16px",
                                      display: "block",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$t("userInfo.yourFullName")
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ui small input",
                                    class: {
                                      error:
                                        _vm.shouldDisplayUserDisplayNameValidationError,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.displayName,
                                          expression: "displayName",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            _vm.userDisplayNameValidation.rules,
                                          expression:
                                            "userDisplayNameValidation.rules",
                                        },
                                        { name: "focus", rawName: "v-focus" },
                                      ],
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        name: "displayName",
                                        type: "text",
                                      },
                                      domProps: { value: _vm.displayName },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.displayName = $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("TransitionExpand", [
                                _vm.shouldDisplayUserDisplayNameValidationError
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sign-via-email__submit-error",
                                        staticStyle: {
                                          color: "var(--red)",
                                          "margin-top": "var(--spacing-8)",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.errors.first(
                                                "userDisplayNameForm.displayName"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticStyle: {
                                  "margin-bottom": "var(--spacing-24)",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "margin-bottom": "var(--spacing-4)",
                                    color: "var(--grey-73)",
                                    "font-size": "16px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("userInfo.yourEmail")) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(_vm.user.email))]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "c-sign-popup__button ui button green",
                                  staticStyle: {
                                    "margin-top": "var(--spacing-16)",
                                  },
                                  attrs: {
                                    type: "submit",
                                    "data-qa": "user-info-modal__continue",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("userInfo.continue")) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("Transition", { attrs: { name: "fade-alternative" } }, [
                  _vm.step === 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "c-user-info-modal__step u-overlapping-elements__child",
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "c-sign-popup__header c-heading c-heading--third",
                              staticStyle: {
                                "padding-bottom": "var(--spacing-24)",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t(
                                        "userInfo.weWantToTailorYourExperience"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass: "c-sign-popup__subheader p",
                                staticStyle: {
                                  "margin-bottom": "var(--spacing-12)",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t(
                                        "userInfo.howWouldYouDescribeYourself"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "c-sign-popup__button ui button green",
                                attrs: {
                                  "data-qa": "user-info-modal__i-am-a-teacher",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.yourself("teacher")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.$t("userInfo.iAmATeacher")) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "c-sign-popup__button ui button green",
                                on: {
                                  click: function ($event) {
                                    return _vm.yourself("student")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.$t("userInfo.iAmAStudent")) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("Transition", { attrs: { name: "fade-alternative" } }, [
                  _vm.step === 2
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "c-user-info-modal__step u-overlapping-elements__child",
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "c-sign-popup__header c-heading c-heading--third",
                              staticStyle: {
                                "padding-bottom": "var(--spacing-24)",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t(
                                        "userInfo.weWantToTailorYourExperience"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "c-sign-popup__subheader p",
                                  staticStyle: {
                                    "margin-bottom": "var(--spacing-12)",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("userInfo.selectTopics")) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("Topics", {
                                attrs: {
                                  topics: _vm.topics,
                                  active: _vm.interests,
                                  "on-click": _vm.selectInterest,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "c-sign-popup__button ui button green",
                                  staticStyle: {
                                    "margin-top": "var(--spacing-16)",
                                  },
                                  attrs: {
                                    "data-qa": "user-info-modal__continue",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitInterests()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("userInfo.continue")) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }