const calculateMaximumKnownFactor = () => knownFactor(30) * 10;

const knownFactor = numberOfWords => 1 - unknownFactor(numberOfWords);

const unknownFactor = numberOfWords => (1 / Math.pow(numberOfWords, 0.2));

export const progressPercent = knownFactor => Math.round((Math.max(0, knownFactor) / calculateMaximumKnownFactor()) * 100);

export const calculatePreviousKnownFactor = (guesses = []) => {
	const previousGuesses = guesses.slice();
	previousGuesses.shift();

	return calculateKnownFactor(previousGuesses);
};

// This is very primitive and poor algorithm.
// It should rather calculate based on when last time word was swiped
// If someone swiped seven days ago last time and this time swiped that know word
// it means that he know word.
export const calculateKnownFactor = (guesses) => {
	if (guesses.length === 0) {
		return null;
	}

	guesses = guesses.slice(0, 10);

	let factor = 0;

	guesses = guesses.filter(guess => typeof guess.numberOfWords === 'number');

	guesses.forEach((guess) => {
		if (guess.known) {
			factor += knownFactor(30);
		} else {
			factor += unknownFactor(30) * -1;
		}
	});

	return factor;
};
