<template>
	<div
		:aria-hidden="!isMenuActive"
		class="c-offset-menu"
		:class="{ 'c-offset-menu--active': isMenuActive }"
	>
		<FocusLock :disabled="!isMenuActive">
			<div
				class="c-offset-menu__list"
				:class="{ 'c-offset-menu__list--fade': isMenuActive }"
			>
				<div class="c-offset-menu__item c-offset-menu__header">
					<button
						class="c-offset-menu__btn-close"
						type="button"
						@click.prevent="hideOffsetMenu"
					>
						<i class="c-offset-menu__icon-close icon close" />
					</button>
				</div>
				<PageNav :is-logged-in="isLoggedIn" />
				<hr class="separator">
				<div
					class="language-section"
					@click="onLanguageClick && onLanguageClick()"
				>
					<span class="language-section__title">
						{{ $t('language') }}:
					</span>
					<span class="language-section__value">
						{{ activeLocale.text }}
						<img
							class="language-section__flag"
							:alt="activeLocale.text"
							:src="activeLocale.flagUrl"
						>
						<i class="language-section__icon icon angle right" />
					</span>
				</div>
				<div
					v-if="isLoggedIn"
					class="c-offset-menu__item"
				>
					<a
						class="c-offset-menu__link"
						href="/auth/logout"
						@click.prevent="logout()"
					>
						{{ $t("header.logout") }}
					</a>
				</div>
			</div>
		</FocusLock>
	</div>
</template>

<script>
	import FocusLock from 'vue-focus-lock';
	import PageNav from '@/components/pageNav/PageNav';
	import PopOver from '@/components/PopOver';
	import { locales } from '@/assets/js/i18n/configuration';

	export default {
		name: 'OffsetMenu',
		components: {
			PopOver,
			PageNav,
			FocusLock,
		},
		props: {
			isMenuActive: Boolean,
			onLanguageClick: Function,
		},
		computed: {
			isLoggedIn() {
				return this.$store.getters.isLoggedIn;
			},
			activeLocale() {
				return locales.find(l => l.code === this.activeLocaleCode);
			},
			activeLocaleCode() {
				return this.$i18n.locale;
			},
		},
		methods: {
			hideOffsetMenu() {
				if (this.isMenuActive) {
					this.$store.dispatch('hideOffsetMenu');
				}
			},
			logout() {
				this.$store.dispatch('logout');
				this.$router.push('/');
			},
		},
	};
</script>
<style scoped lang="scss">
.separator {
	border: none;
	border-top: 8px solid var(--grey-8);
	margin: 0;
}
.language-section {
	margin-top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--spacing-16);
	border-left: 0;
	cursor: pointer;
	font-weight: bold;
	transition: all 200ms;
}
.language-section:hover {
	background: var(--pampas);
}

.language-section__value {
	display: flex;
	align-items: center;
}

.language-section__flag {
	height: 18px;
	margin-left: var(--spacing-12);
	margin-right: var(--spacing-16);
}

.language-section__icon {
	color: var(--grey-60);
}
</style>
