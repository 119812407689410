<template>
	<nav>
		<ul class="c-page-nav__nav-list">
			<li
				v-for="item in items"
				:key="item.link.name"
				class="c-page-nav__li"
			>
				<RouterLink
					exact-active-class="c-page-nav__link--active"
					class="c-page-nav__link"
					:tabindex="item.disabled ? -1 : 0"
					:class="{
						'c-page-nav__link--disabled': item.disabled
					}"
					:event="item.disabled ? '' : 'click'"
					:to="item.link"
					@click.native="item.disabled ? showRegisterModal() : () => {}"
				>
					<i :class="`c-page-nav__icon icon ${item.icon}`" />
					{{ $t(item.text) }}
				</RouterLink>
			</li>
		</ul>
	</nav>
</template>

<script>
	import { ctaLocations, events, logAnalyticsEvent } from '@/assets/js/utils/analytics';

	export default {
		name: 'PageNav',
		props: {
			isLoggedIn: {
				type: Boolean,
			},
		},
		computed: {
			items() {
				/* eslint-disable object-curly-newline */
				const loggedInNav = [
					{ link: { name: 'dashboard' }, icon: 'home', text: 'dashboard.nav.dashboard' },
					{ link: { name: 'recentlyPlayed' }, icon: 'time', text: 'dashboard.nav.recentlyPlayed' },
					{ link: { name: 'favourites' }, icon: 'heart', text: 'dashboard.nav.favourites' },
					{ link: { name: 'yours' }, icon: 'user', text: 'dashboard.nav.yours' },
					{ link: { name: 'mostFavourite' }, icon: 'fire', text: 'dashboard.nav.mostFavourite' },
					{ link: { name: 'others' }, icon: 'users', text: 'dashboard.nav.others' },
				];
				const notLoggedInNav = [
					{ link: { name: 'dashboard' }, icon: 'home', text: 'dashboard.nav.dashboard', disabled: false },
					{ link: { name: 'mostFavourite' }, icon: 'fire', text: 'dashboard.nav.mostFavourite', disabled: false },
					{ link: { name: 'recentlyPlayed' }, icon: 'time', text: 'dashboard.nav.recentlyPlayed', disabled: true },
					{ link: { name: 'favourites' }, icon: 'heart', text: 'dashboard.nav.favourites', disabled: true },
					{ link: { name: 'yours' }, icon: 'user', text: 'dashboard.nav.yours', disabled: true },
					{ link: { name: 'others' }, icon: 'users', text: 'dashboard.nav.others', disabled: true },
				];
				/* eslint-enable object-curly-newline */

				return this.isLoggedIn ? loggedInNav : notLoggedInNav;
			},
		},
		methods: {
			showRegisterModal() {
				this.$store.dispatch('changeModalType', 'signUpAction');
				logAnalyticsEvent(...events.signUpModalDisplay, ctaLocations.registeredUserAction);
			},
		},
	};
</script>
