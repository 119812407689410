<template>
	<div>
		<Transition name="appear">
			<div
				v-show="show"
				aria-live="polite"
				class="c-popover"
				:style="styles"
			>
				<slot />
			</div>
		</Transition>
	</div>
</template>

<script>
	export default {
		name: 'PopOver',
		props: {
			show: { type: Boolean },
			position: {
				type: String,
				default: 'top',
				validator: val => [
					'top',
					'top-left',
					'top-right',
					'bottom',
					'bottom-left',
					'bottom-right',
				].includes(val),
			},
			relativeTo: {
				type: HTMLElement,
				default: null,
			},
		},
		computed: {
			styles() {
				const [vertical, horizontal] = this.position.split('-');

				if (this.relativeTo != null) {
					const result = {
						'--movement-distance': vertical === 'top' ? '-200px' : '200px',
						position: 'absolute',
						transform: '',
					};

					if (vertical === 'top') {
						result.top = '0';
						result.transform += ' translateY(-100%) ';
					} else {
						result.bottom = '0';
						result.transform += ' translateY(100%) ';
					}

					if (!horizontal) {
						result.left = '50%';
						result.transform += ' translateX(-50%) ';
					} else if (horizontal === 'right') {
						result.right = '0';
					}

					return result;
				}

				const result = {
					'--movement-distance': vertical === 'top' ? '-200px' : '200px',
					margin: 'var(--spacing-12)',
					[vertical]: 0, // 'top' | 'bottom'
				};

				if (horizontal) {
					return {
						...result,
						[horizontal]: 0, // 'left' | 'right'
					};
				}

				return {
					...result,
					// Spread from left to right
					left: 0,
					right: 0,
				};
			},
		},
	};
</script>

<style>
	.c-popover {
		--movement-distance: 200px;
		position: fixed;
		/* Above the dialog dimmer which is 1000 */
		z-index: 1001;
	}

	/* https://css-tricks.com/ease-out-in-ease-in-out/ */
	.appear-enter-active {
		transition: all .3s ease-out;
	}

	.appear-leave-active {
		transition: all .3s ease-in;
	}

	.appear-enter,
	.appear-leave-to {
		transform: translate3d(0, var(--movement-distance), 0);
		opacity: 0;
	}

	.c-popover-body--one {
		background: var(--pure-white);
		color: var(--black);
		padding: var(--spacing-16);
		border-radius: var(--spacing-16);
		max-width: 400px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
		margin: 0 auto;
	}

	.c-popover-body--two {
		background: var(--pure-white);
		color: var(--black);
		padding: var(--spacing-16);
		border-radius: 500px;
		max-width: 400px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
		margin: 0 auto;
	}

	.c-popover-body--three {
		background: var(--pure-white);
		color: var(--black);
		padding: var(--spacing-4);
		border-radius: var(--spacing-24);
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
		margin: 0 auto;
	}

	.c-popover-body__close-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		padding-bottom: var(--spacing-8);
	}

	.c-popover-body__close-btn-wrapper--floating {
		float: right;
	}

	.c-popover-body__close-btn {
		all: unset;
		cursor: pointer;
		position: relative;
	}

	.c-popover-body__close-btn::before {
		content: '';
		position: absolute;
		top: -10px;
		right: -10px;
		bottom: 2px;
		left: -10px;
	}

	.c-popover-body__close-btn-icon {
		margin: 0;
	}
</style>
