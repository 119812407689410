const seoToDeckId = (listId = '') => {
	const match = listId.match(/.*([0-9a-fA-F]{24})$/);
	if (match) {
		return match[1];
	}

	return listId;
};

const isObjectId = id => /^[a-f\d]{24}$/i.test(id);

const deckIdToSeo = (name = '', id) => {
	if (!isObjectId(id)) {
		return id;
	}

	const seoName = name
		.trim()
		.replace(/\//g, '-')
		.replace(/\s/g, '-')
		.replace(/---/g, '-')
		.toLowerCase();
	if (!seoName) {
		return id;
	}

	return (`${seoName}-${id}`);
};

module.exports = {
	seoToDeckId,
	deckIdToSeo,
	isObjectId,
};
