var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "c-language-radio" },
    _vm._l(_vm.locales, function (locale) {
      return _c(
        "li",
        {
          key: locale.code,
          class: {
            "c-language-radio__item": true,
            "c-language-radio__item--active":
              _vm.activeLocaleCode === locale.code,
          },
          on: {
            click: function ($event) {
              return _vm.switchLocale(locale.code)
            },
          },
        },
        [
          _vm._v("\n\t\t" + _vm._s(locale.text) + "\n\t\t"),
          _c("img", {
            staticClass: "c-language-radio__flag",
            attrs: { alt: locale.text, src: locale.flagUrl },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }