import * as Sentry from '@sentry/vue';
import untruncateJson from 'untruncate-json';

const textDecoder = new TextDecoder('utf-8');

export const fetchStream = async ({ url, options }, onChunk) => new Promise(async (resolve, reject) => {
	const response = await fetch(url, options);

	if (!response.ok) {
		return reject(new Error('Network response was not ok'));
	}

	const reader = response.body.getReader();
	let concatenatedChunk = '';
	let fixedJSON;

	return new ReadableStream({
		start: async (controller) => {
			try {
				// eslint-disable-next-line no-constant-condition
				while (true) {
					// eslint-disable-next-line no-await-in-loop
					const { done, value } = await reader.read();

					if (done) {
						controller.close();
						break;
					}

					const currentChunk = textDecoder.decode(value);
					concatenatedChunk += currentChunk;
					fixedJSON = fixJSON(concatenatedChunk);
					onChunk({ currentChunk, fixedJSON, concatenatedChunk });
				}
			} catch (error) {
				controller.error(error);
				return reject(error);
			} finally {
				reader.releaseLock();
			}

			resolve({ fixedJSON, concatenatedChunk, response });
		},
	});
});

const fixJSON = (text) => {
	try {
		return JSON.parse(untruncateJson(text));
	} catch (e) {
		Sentry.captureMessage('Could not fix JSON');
		return {};
	}
};
