var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "empty-state-decks" },
    [
      _c("EmptyStateDecksIllustration", {
        staticClass: "empty-state-decks__illustration",
      }),
      _vm._v(" "),
      _c("div", { staticClass: "empty-state-decks__title" }, [
        _vm.context === "decks"
          ? _c("span", [_vm._v(_vm._s(_vm.$t("dashboard.noDecksHere")))])
          : _vm.context === "cards"
          ? _c("span", [_vm._v(_vm._s(_vm.$t("dashboard.noCardsHere")))])
          : _c("span", [_vm._v(_vm._s(_vm.$t("dashboard.noQuestionsHere")))]),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }