export const intersectionHandlerOnce = (element, callback, options = {
	root: null,
	threshold: 0.95,
}) => {
	if (!IntersectionObserver || !element) {
		return;
	}

	let observer;

	const intersectCallback = (entries) => {
		for (let entry of entries) {
			if (entry.isIntersecting) {
				callback(element);
				observer.unobserve(element);
			}
		}
	};

	observer = new IntersectionObserver(intersectCallback, options);
	observer.observe(element);
};

export const scrollToWithOffset = (element, offset) => {
	const elementPosition = element.getBoundingClientRect().top;
	const offsetPosition = elementPosition + window.pageYOffset - offset;

	window.scrollTo({
		behavior: 'smooth',
		top: offsetPosition,
	});
};
