var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.searchAISuggestionsFeature
      ? _c("div", [
          _c("h2", [_vm._v("AI Suggested Flashcards")]),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "c-deck-info-cards-grid",
              staticStyle: { padding: "0" },
            },
            _vm._l(_vm.streamedJSON.decks || [], function (deck, index) {
              return _c("li", { key: index, staticClass: "c-deck-info-card" }, [
                _c("div", { staticClass: "c-deck-info-card__section" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "flex-direction": "column",
                        "flex-grow": "1",
                      },
                    },
                    [
                      _c("span", { staticClass: "c-deck-info-card__title" }, [
                        _c("strong", [_vm._v(_vm._s(deck.title))]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("p", [_vm._v(_vm._s(deck.description))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(deck.fieldOfStudy))]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "c-btn c-btn--cta c-btn--small",
                              staticStyle: { "align-self": "end" },
                              attrs: { type: "button" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tGenerate Deck\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.streamedJSON.suggestions, function (suggestion) {
              return _c("li", { key: suggestion }, [
                _vm._v("\n\t\t\t\t" + _vm._s(suggestion) + "\n\t\t\t"),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.streamedJSON.dataCollectedSoFar, function (value, key) {
              return _c("li", { key: key }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(key) +
                    ": " +
                    _vm._s(value != null ? value : "null") +
                    "\n\t\t\t"
                ),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h3", { staticClass: "ui header" }, [
      _vm._v(
        "\n\t\t" + _vm._s(_vm.$t("dashboard.searchResultsForQuery")) + ": "
      ),
      _c("q", [_c("strong", [_vm._v(_vm._s(_vm.query))])]),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "ui centered inline loader inverted medium",
      class: { active: !this.ready },
    }),
    _vm._v(" "),
    this.ready
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listsBySearch.length === 0,
                expression: "listsBySearch.length === 0",
              },
            ],
            staticClass: "ui info message",
          },
          [
            _c("div", { staticClass: "header" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.$t("dashboard.couldNotFindAnyDeck")) +
                  "\n\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("dashboard.perhapsYouWantCreateOne"))),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-deck-info-cards-grid" },
      _vm._l(_vm.listsBySearch, function (list) {
        return _c("ListCard", { key: list._id, attrs: { list: list } })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }