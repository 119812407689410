const isEvent = (event, category, action) => event[0] === category && event[1] === action;

const logPixelEvent = (...args) => {
	const [method, event, properties] = args;
	if (localStorage && localStorage.getItem('debugAnalytics') == 'true') {
		console.log(
			`%c FBQ event: { method: "${method}", event: "${event}", properties: "${properties}" }`,
			'background: #4267B2; color: #ffffff',
		);
	}

	window.fbq(...args);
};

export function logAnalyticsEvent(...args) {
	const [category, action, label] = args;

	const eventName = action === actions.swipe
		? action : `${category.split('-').join('_')}_${action.split('-').join('_')}`;

	if (window.gtag) {
		gtag('event', eventName, {
			event_category: category,
			event_label: label,
		});
	}

	if (localStorage && localStorage.getItem('debugAnalytics') == 'true') {
		console.log(
			`%c Custom event(${eventName}): { category: "${category}", label: "${label}" }`,
			'background: #222; color: #bada55',
		);
	}

	if (window.fbq) {
		if (isEvent(events.searchFormSubmit, category, action)) {
			logPixelEvent('track', 'Search', { search_string: label, value: 5.00, currency: 'GBP' });
		}

		if (isEvent(events.userInfoTypeFormSubmit, category, action)) {
			logPixelEvent('track', 'CompleteRegistration', { value: 5.00, currency: 'GBP' });
		}

		if (category === labels.createDeckForm && action === actions.submit) {
			logPixelEvent('track', 'InitiateCheckout', { value: 5.00, currency: 'GBP' });
		}

		if (category === labels.createDeckModal && action === actions.display) {
			logPixelEvent('track', 'AddToCart', { value: 5.00, currency: 'GBP' });
		}

		if (isEvent(events.addCardButtonClick, category, action) && label === 1) {
			logPixelEvent('track', 'Purchase', { value: 5.00, currency: 'GBP' });
		}

		if (isEvent(events.addToFavouritesButtonClick, category, action)) {
			logPixelEvent('track', 'AddToWishlist', { value: 5.00, currency: 'GBP' });
		}

		if (isEvent(events.signUpModalDisplay, category, action)) {
			logPixelEvent('track', 'Lead', { value: 5.00, currency: 'GBP' });
		}
	}
}

export const actions = {
	click: 'click',
	display: 'display',
	swipe: 'swipe',
	reach: 'reach',
	submit: 'submit',
	thrown: 'thrown',
	collapse: 'collapse',
	expand: 'expand',
	evaluateEligibility: 'evaluate-eligibility',
	dismiss: 'dismiss',
	accept: 'accept',
	selectMeeting: 'select-meeting',
	validationError: 'validation-error',
	enroll: 'enroll',

};

export const categories = {
	shareButton: 'share-button',
	showAnswersButton: 'show-answers-button',
	hideAnswersButton: 'hide-answers-button',
	displayAsTableButton: 'display-as-table-button',
	displayAsCardsButton: 'display-as-cards-button',
	flipCardBackButton: 'flip-card-back-button',
	flipCardFrontButton: 'flip-card-front-button',
	hideProgressButton: 'hide-progress-button',
	showProgressButton: 'show-progress-button',
	sortByKnowledgeBetterKnownFirstButton: 'sort-by-knowledge-better-known-first-button',
	sortByKnowledgeLessKnownFirstButton: 'sort-by-knowledge-less-known-first-button',
	sortByKnowledgeNoSortingButton: 'sort-by-knowledge-no-sorting-button',
	validationErrorDeckName: 'validation-error-deck-name',
	yourActivity: 'your-activity',
	yourActivityMobile: 'your-activity-mobile',
};

export const labels = {
	createDeckForm: 'create-deck-form',
	searchForm: 'search-deck-form',
	createDeckModal: 'create-deck-modal',
	resultsModal: 'results-modal',
	deckDetails: 'deck-details',
	userInfoModal: 'user-info-modal',
	surveyInvitationPopover: 'survey-invitation-popover',
	shareButton: 'share-button',
	shareButtonCopyLink: 'share-button-copy-link',
	shareButtonFacebook: 'share-button-facebook',
	shareButtonTwitter: 'share-button-twitter',
	shareButtonWhatsApp: 'share-button-whatsapp',
	shareButtonOther: 'share-button-other',
	userInfoTypeForm: 'user-info-type-form',
	userInfoInterestsForm: 'user-info-interests-form',
	satisfactionFeedbackForm: 'satisfaction-feedback-form',
	error: 'error',
	genericErrorPopover: 'generic-error-popover',
	semanticError: 'semantic-error',
	requestError: 'request-error',
	notFoundPage: 'not-found-page',
	createAccountTeaser: 'create-account-teaser',
	firstResultsCard: 'first-results-card',
	contextualFeedbackButton: 'contextual-feedback-button',
	contextualFeedbackEmoji: 'contextual-feedback-emoji',
	contextualFeedbackForm: 'contextual-feedback-form',
	embedModal: 'embed-modal',
	copyEmbedCodeButton: 'copy-embed-code-button',
	finishCreateDeckModal: 'finish-create-deck-modal',
	deckTypeRadio: 'deck-type-radio',
	generatorAaCTAButton: 'generator-ai-cta-button',
	generatorAiMaybeLaterButton: 'generator-ai-maybe-later-button',
	generateFlashcardsForm: 'generate-flashcards-form',
	saveGeneratedCardsButton: 'save-generated-cards-button',
	discardExistingCardsButton: 'discard-existing-cards-button',
	generateMoreCardsButton: 'generate-more-cards-button',
	wizardNextStepButton: 'wizard-next-step-button',
	wizardBackButton: 'wizard-back-button',
	wizardFinishButton: 'wizard-finish-button',
	addCardButton: 'add-card-button',
	addQuestionButton: 'add-question-button',
	addCategoryButton: 'add-category-button',
	showProgressButton: 'show-progress-button',
	scrollToTopButton: 'scroll-to-top-button',
	exampleFlashcardButton: 'example-flashcard-button',
	featureGeneratorAI: 'feature-generator-ai',
	addToFavourites: 'add-to-favourites',
	aiChatSuggestion: 'ai-chat-suggestion',
	aiChatSendMessageButton: 'ai-chat-send-message-button',
	playNextDeckButton: 'play-next-deck-button',
	playAgainButton: 'play-again-button',
};

export const events = {
	resultsModalDisplay: [labels.resultsModal, actions.display],
	userInfoModalDisplay: [labels.userInfoModal, actions.display],
	surveyInvitationPopoverDisplay: [labels.surveyInvitationPopover, actions.display],
	surveyInvitationPopoverEvaluateEligibility: [labels.surveyInvitationPopover, actions.evaluateEligibility],
	surveyInvitationPopoverDismiss: [labels.surveyInvitationPopover, actions.dismiss],
	surveyInvitationPopoverAccept: [labels.surveyInvitationPopover, actions.accept],
	surveyInvitationPopoverSelectMeeting: [labels.surveyInvitationPopover, actions.selectMeeting],
	userInfoTypeFormSubmit: [labels.userInfoTypeForm, actions.submit],
	userInfoInterestsFormSubmit: [labels.userInfoInterestsForm, actions.submit],
	cardSwipe: ['card', actions.swipe],
	signUpModalDisplay: ['sign-up-modal', actions.display],
	signInModalDisplay: ['sign-in-modal', actions.display],
	signUpFacebook: ['sign-up-modal-button', actions.click, 'facebook'],
	signUpGoogle: ['sign-up-modal-button', actions.click, 'google'],
	signUpEmail: ['sign-up-modal-button', actions.click, 'email'],
	signInFacebook: ['sign-in-modal-button', actions.click, 'facebook'],
	signInGoogle: ['sign-in-modal-button', actions.click, 'google'],
	signInEmail: ['sign-in-modal-button', actions.click, 'email'],
	landingSectionReach: ['landing-section', actions.reach],
	cloudClick: ['word-cloud-item', actions.click],
	suggestedDeckCardClick: ['suggested-deck-card', actions.click],
	errorThrown: [labels.error, actions.thrown],
	genericErrorPopoverDisplay: [labels.genericErrorPopover, actions.display],
	requestErrorThrown: [labels.requestError, actions.thrown],
	semanticErrorThrown: [labels.semanticError, actions.thrown],
	notFoundPageDisplay: [labels.notFoundPage, actions.display],
	deckTypeRadioClick: [labels.deckTypeRadio, actions.click],
	aiChatSuggestionClick: [labels.aiChatSuggestion, actions.click],
	aiChatSendMessageButtonClick: [labels.aiChatSendMessageButton, actions.click],
	generatorAaCTAButtonClick: [labels.generatorAaCTAButton, actions.click],
	generatorAiMaybeLaterButtonClick: [labels.generatorAiMaybeLaterButton, actions.click],
	generateFlashcardsFormSubmit: [labels.generateFlashcardsForm, actions.submit],
	generateFlashcardsFormValidationError: [labels.generateFlashcardsForm, actions.validationError],
	saveGeneratedCardsButtonClick: [labels.saveGeneratedCardsButton, actions.click],
	discardExistingCardsButtonClick: [labels.discardExistingCardsButton, actions.click],
	generateMoreCardsButtonClick: [labels.generateMoreCardsButton, actions.click],
	wizardNextStepButtonClick: [labels.wizardNextStepButton, actions.click],
	wizardBackButtonClick: [labels.wizardBackButton, actions.click],
	wizardFinishButtonClick: [labels.wizardFinishButton, actions.click],
	addCardButtonClick: [labels.addCardButton, actions.click],
	addQuestionButtonClick: [labels.addQuestionButton, actions.click],
	addCategoryButtonClick: [labels.addCategoryButton, actions.click],
	hideProgressButtonClick: [categories.hideProgressButton, actions.click],
	showProgressButtonClick: [labels.showProgressButton, actions.click],
	scrollTopTopButtonClick: [labels.scrollToTopButton, actions.click],
	exampleFlashcardButtonClick: [labels.exampleFlashcardButton, actions.click],
	featureGeneratorAIEnroll: [labels.featureGeneratorAI, actions.enroll],
	searchFormSubmit: [labels.searchForm, actions.submit],
	addToFavouritesButtonClick: [labels.addToFavourites, actions.click],
	shareButtonCopyLinkClick: [labels.shareButtonCopyLink, actions.click],
	shareButtonFacebookClick: [labels.shareButtonFacebook, actions.click],
	shareButtonTwitterClick: [labels.shareButtonTwitter, actions.click],
	shareButtonWhatsAppClick: [labels.shareButtonWhatsApp, actions.click],
	shareButtonOtherClick: [labels.shareButtonOther, actions.click],
	shareButtonClick: [labels.shareButton, actions.click],
	hideAnswersButtonClick: [categories.hideAnswersButton, actions.click],
	showAnswersButtonClick: [categories.showAnswersButton, actions.click],
	displayAsTableButtonClick: [categories.displayAsTableButton, actions.click],
	displayAsCardsButtonClick: [categories.displayAsCardsButton, actions.click],
	flipCardFrontButtonClick: [categories.flipCardFrontButton, actions.click],
	flipCardBackButtonClick: [categories.flipCardBackButton, actions.click],
	sortByKnowledgeNoSortingButtonClick: [categories.sortByKnowledgeNoSortingButton, actions.click],
	sortByKnowledgeBetterKnownFirstButtonClick: [categories.sortByKnowledgeBetterKnownFirstButton, actions.click],
	sortByKnowledgeLessKnownFirstButtonClick: [categories.sortByKnowledgeLessKnownFirstButton, actions.click],
	embedModalDisplay: [labels.embedModal, actions.display],
	yourActivityCollapse: [categories.yourActivity, actions.collapse],
	yourActivityExpand: [categories.yourActivity, actions.expand],
	copyEmbedCodeButtonClick: [labels.copyEmbedCodeButton, actions.click],
	firstResultsCardDisplay: [labels.firstResultsCard, actions.display],
	contextualFeedbackButtonClick: [labels.contextualFeedbackButton, actions.click],
	contextualFeedbackEmojiClick: [labels.contextualFeedbackEmoji, actions.click],
	contextualFeedbackFormSubmit: [labels.contextualFeedbackForm, actions.submit],
	validationErrorDeckNameDisplay: [categories.validationErrorDeckName, actions.display],
	yourActivityMobileExpand: [categories.yourActivityMobile, actions.expand],
	yourActivityMobileCollapse: [categories.yourActivityMobile, actions.collapse],
	createDeckModalDisplay: [labels.createDeckModal, actions.display],
	createDeckFormSubmit: [labels.createDeckForm, actions.submit],
	satisfactionFeedbackFormDisplay: [labels.satisfactionFeedbackForm, actions.display],
	createAccountTeaserDisplay: [labels.createAccountTeaser, actions.display],
	satisfactionFeedbackFormSubmit: [labels.satisfactionFeedbackForm, actions.submit],
	playNextDeckButtonClick: [labels.playNextDeckButton, actions.click],
	playAgainButtonClick: [labels.playAgainButton, actions.click],
};

export const ctaLocations = {
	header: 'header',
	resultsView: 'results-view',
	landingHeader: 'landing-header',
	landingHero: 'landing-hero',
	landingPageBottom: 'landing-page-bottom',
	registeredUserAction: 'registered-user-action',
	landingHeroForTeachers: 'landing-hero-for-teachers',
	landingPageBottomForTeachers: 'landing-page-bottom-for-teachers',
	landingGeneratorAIHero: 'landing-generator-ai-hero',
	landingGeneratorAIMiddle: 'landing-generator-ai-middle',
	landingGeneratorAIBottom: 'landing-generator-ai-bottom',
	createAccountTeaser: 'create-account-teaser',
	notAuthorisedPage: 'not-authorised-page',
};
