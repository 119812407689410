<template>
	<div class="decks-container">
		<h3
			v-if="title"
			class="decks-container__heading ui header"
		>
			{{ title }}
		</h3>

		<div
			class="ui centered inline loader medium"
			:class="{ active: !this.ready && !shouldShowPlaceholders }"
		/>
		<div
			v-if="shouldDisplayEmptyState"
			style="display: flex; justify-content: center;"
		>
			<EmptyStateDecks>
				<slot name="noDecksMessage" />
			</EmptyStateDecks>
		</div>
		<div class="c-deck-info-cards-grid">
			<ListCard
				v-for="(deck, index) in _decks"
				ref="deckItem"
				:key="deckKeys(index)"
				:list="deck"
				:source-deck="sourceDeck"
				:loading="shouldShowPlaceholders"
				:show-set-theme-image-button="showSetThemeImageButton"
				:show-cta-buttons="showCtaButtons"
				:selected-deck-id="selectedDeckId"
				:is-link="isLink"
				@card-clicked="onCardClicked"
			/>
		</div>
		<div
			v-if="!shouldDisplayEmptyState"
			class="decks-container__navigation-section"
		>
			<button
				v-if="requestMoreDecks"
				class="decks-container__load-more ui right labeled icon button"
				:class="{ loading: busy }"
				@click="loadMore"
			>
				{{ noMoreItems ? $t('dashboard.noMoreDecks') : $t('dashboard.loadMore') }}
				<i class="chevron down icon" />
			</button>
			<RouterLink
				v-if="moreLink !== ''"
				class="decks-container__see-more ui tiny right labeled icon circular button"
				:to="{ name: moreLink }"
			>
				<i class="right chevron icon" />
				{{ $t('dashboard.seeMore') }}
			</RouterLink>
		</div>
	</div>
</template>
<script>
	import ListCard from '@/components/listCard/ListCard';
	import EmptyStateDecks from '@/components/EmptyStateDecks';

	const randomNumberBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
	const randomLengthText = (min, max, char = 'a') => new Array(randomNumberBetween(min, max)).join(char);

	export default {
		name: 'DecksContainer',
		components: {
			EmptyStateDecks,
			ListCard,
		},
		props: {
			moreLink: {
				type: String,
				default: '',
			},
			sourceDeck: {
				type: Object,
				default() {
					return {};
				},
			},
			decks: {
				type: Array,
				default() {
					return [];
				},
			},
			title: {
				type: [String, undefined],
			},
			fetchDecks: {
				type: Function,
				required: true,
			},
			requestMoreDecks: {
				type: Function,
			},
			resultsAmount: {
				type: Number,
			},
			showSetThemeImageButton: {
				type: Boolean,
				default: false,
			},
			showCtaButtons: {
				type: Boolean,
				default: true,
			},
			isLink: {
				type: Boolean,
				default: true,
			},
			selectedDeckId: {
				type: [String, null],
				default: null,
			},
		},
		data() {
			return {
				ready: false,
				busy: false,
				noMoreItems: false,
			};
		},
		computed: {
			deckKeys() {
				return index => this._decks[index]._id || index;
			},
			shouldShowPlaceholders() {
				return this.resultsAmount && this.ready !== true;
			},
			_decks() {
				if (!this.shouldShowPlaceholders) {
					return this.decks;
				}

				return new Array(this.resultsAmount).fill(null).map(() => ({
					name: randomLengthText(25, 32),
					words: [],
					stars: [],
					author: {
						displayName: randomLengthText(10, 18),
					},
				}));
			},
			shouldDisplayEmptyState() {
				return this._decks.length === 0 && this.ready;
			},
		},
		async created() {
			this.ready = false;
			await this.fetchDecks();
			this.ready = true;
		},
		methods: {
			onCardClicked(e) {
				this.$emit('card-clicked', e);
			},
			async loadMore() {
				const itemsAmountBefore = this.decks.length;

				this.busy = true;
				await this.requestMoreDecks();
				this.busy = false;

				const itemsAmountAfter = this.decks.length;

				if (itemsAmountBefore === itemsAmountAfter) {
					this.noMoreItems = true;
				}

				const newItem = this.$refs.deckItem[itemsAmountBefore];

				if (newItem && newItem.$el.scrollIntoView) {
					newItem.$el.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			},
		},
	};
</script>
<style>
	:root {
		--decks-container-heading-border-bottom: 5px solid var(--grey-8);
	}
	.decks-container {
		margin: 16px 0 48px 0;
	}

	.decks-container:first-of-type {
		margin-top: 0;
	}

	.decks-container__heading.decks-container__heading {
		border-bottom: var(--decks-container-heading-border-bottom);
		padding-bottom: 8px;
	}

	.decks-container__navigation-section {
		margin: 16px 0 8px 0;
		display: flex;
		justify-content: center;
	}

	.decks-container__see-more {
		margin-left: auto;
	}

	.decks-container__load-more {}
</style>
