var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "c-btn c-btn--icon c-btn--small",
      class: {
        "c-btn--white": _vm.white,
        "c-btn--secondary": _vm.secondary,
        "c-btn--inverted": _vm.inverted,
        "c-btn--cta": _vm.cta,
      },
      attrs: {
        title: _vm.$t(
          _vm.deck.private
            ? "deckCreate.makeThisDeckPublic"
            : "deckCreate.makeThisDeckPrivate"
        ),
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("i", {
        staticClass: "c-btn__icon icon",
        class: {
          lock: !_vm.deck.private,
          unlock: _vm.deck.private,
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "c-btn__icon-text" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.$t(
                _vm.deck.private
                  ? "deckCreate.makeThisDeckPublic"
                  : "deckCreate.makeThisDeckPrivate"
              )
            ) +
            "\n\t"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }