<template>
	<div>
		<button
			type="button"
			class="c-feedback"
			@click="onClick"
		>
			<span>
				{{ $t('satisfactionFeedback.tellUsWhatDoYouThink') }}
			</span>
			<svg
				class="c-feedback__icon"
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 24 24"
			><path d="M22 3v13h-11.643l-4.357 3.105v-3.105h-4v-13h20zm2-2h-24v16.981h4v5.019l7-5.019h13v-16.981zm-5 6h-14v-1h14v1zm0 2h-14v1h14v-1zm-6 3h-8v1h8v-1z" /></svg>
		</button>
		<SuiModal
			v-model="modalOpened"
			size="tiny"
		>
			<i
				class="close icon"
				@click="closeModal"
			/>
			<SuiModalContent scrolling>
				<TransitionExpand>
					<div v-if="!submitted">
						<p class="paragraph">
							{{ $t('satisfactionFeedback.whatDoYouThinkOfThis') }}
						</p>
						<FeedbackAnswers
							more-answers
							no-spacing
							left-aligned
							:on-feedback="onFeedback"
							:highlighted="sentiment"
						/>
						<TransitionExpand>
							<div v-if="sentiment">
								<div class="spacer-24" />
								<p class="paragraph">
									{{ $t('satisfactionFeedback.whatKindOfFeedback') }}
								</p>
								<div style="display: flex; flex-wrap: wrap; gap: var(--spacing-8);">
									<button
										v-for="{ value, text} of feedbackTypes"
										:key="value"
										type="button"
										class="c-btn c-btn--small"
										@click="kindOfFeedback = value"
									>
										{{ $t(text) }}
									</button>
								</div>
								<TransitionExpand>
									<div v-if="kindOfFeedback">
										<div class="spacer-24" />
										<p class="paragraph">
											{{ $t(feedbackText) }}
										</p>
										<div class="ui form">
											<div class="field">
												<textarea
													v-model="content"
													class="input-customisation"
													rows="3"
												/>
											</div>
										</div>
										<div class="spacer-12" />
										<div class="submit-container">
											<button
												type="button"
												class="c-btn c-btn--cta c-btn--small"
												@click="submit"
											>
												<i class="ui icon paper plane" />
												{{ $t('satisfactionFeedback.submit') }}
											</button>
										</div>
									</div>
								</TransitionExpand>
							</div>
						</TransitionExpand>
					</div>
				</TransitionExpand>
				<TransitionExpand>
					<div
						v-if="submitted"
					>
						<div class="feedback-thanks">
							{{ $t('satisfactionFeedback.thankYouForFeedback') }}
						</div>
						<div class="submit-container">
							<button
								type="button"
								class="c-btn c-btn--cta c-btn--small"
								@click="closeModal"
							>
								{{ $t('close') }}
							</button>
						</div>
					</div>
				</TransitionExpand>
			</SuiModalContent>
		</SuiModal>
	</div>
</template>

<script>
	import axios from 'axios';
	import ObjectID from 'bson-objectid';
	import FeedbackAnswers from '@/components/feedback/FeedbackAnswers';
	import TransitionExpand from '@/components/TransitionExpand';
	import dateFromObjectId from '@/assets/js/dataServices/dateFromObjectId';
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
	import { mapGetters } from 'vuex';

	const feedbackTypes = [
		{ value: 'bug-or-error', text: 'satisfactionFeedback.bugOrError' },
		{ value: 'complaint-or-question', text: 'satisfactionFeedback.complaintOrQuestion' },
		{ value: 'suggestion', text: 'satisfactionFeedback.suggestion' },
		{ value: 'compliment', text: 'satisfactionFeedback.compliment' },
	];

	export const context = {
		deckEditStep1: 'deck-edit-step-1',
		deckEditStep2: 'deck-edit-step-2',
		deckEditStep3: 'deck-edit-step-3',
		deckEditStep4: 'deck-edit-step-4',
		deckDetails: 'deck-details-page',
		resultsPage: 'results-page',
	};

	export default {
		name: 'Feedback',
		components: {
			FeedbackAnswers,
			TransitionExpand,
		},
		props: {
			context: {
				type: String,
			},
		},
		data: () => ({
			sentiment: null,
			modalOpened: false,
			kindOfFeedback: '',
			content: '',
			submitBusy: false,
			submitted: false,
			session: null,
		}),
		computed: {
			...mapGetters([
				'userId',
			]),
			feedbackTypes() {
				return ['love', 'like'].includes(this.sentiment) ? feedbackTypes.slice().reverse() : feedbackTypes;
			},
			feedbackText() {
				return {
					'bug-or-error': 'satisfactionFeedback.sorryWhatWentWrong',
					'complaint-or-question': 'satisfactionFeedback.whatCouldBeBetter',
					suggestion: 'satisfactionFeedback.whatWouldYouLikeToShare',
					compliment: 'satisfactionFeedback.whatIsWorkingWell',
				}[this.kindOfFeedback];
			},
			readableDate() {
				return dateFromObjectId(this.session).toUTCString();
			},
		},
		created() {
			this.session = String(ObjectID());
		},
		methods: {
			onClick() {
				logAnalyticsEvent(...events.contextualFeedbackButtonClick, this.context);
				this.modalOpened = true;
			},
			closeModal() {
				this.modalOpened = false;
			},
			onFeedback(sentiment) {
				if (this.sentiment === null) {
					const payload = {
						sentiment,
						context: this.context,
						session: this.session,
						date: this.readableDate,
						url: window.location.href,
						userid: this.userId,
					};

					axios.post(
						'https://api.sheety.co/b6bf8f69a89018e2ec81df644d928e09/surveyResults/sheet1',
						{ sheet1: payload },
					);
					logAnalyticsEvent(
						...events.contextualFeedbackEmojiClick,
						JSON.stringify(payload),
					);
				}
				this.sentiment = sentiment;
			},
			async submit() {
				const payload = {
					sentiment: this.sentiment,
					type: this.kindOfFeedback,
					content: this.content,
					context: this.context,
					session: this.session,
					date: this.readableDate,
					url: window.location.href,
					userid: this.userId,
				};

				axios.post(
					'https://api.sheety.co/b6bf8f69a89018e2ec81df644d928e09/surveyResults/sheet1',
					{ sheet1: payload },
				);
				logAnalyticsEvent(
					...events.contextualFeedbackFormSubmit,
					JSON.stringify(payload),
				);

				this.submitted = true;
			},
		},
	};
</script>

<style scoped>
	.c-feedback {
		all: unset;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: var(--spacing-16) var(--spacing-24);
		background: var(--grey-8);
		border-radius: var(--spacing-8);
		margin: var(--spacing-24) auto 0;
		color: var(--active);
		box-sizing: border-box;
		width: 100%;
		justify-content: center;
	}

	.c-feedback:hover {
		text-decoration: underline;
	}

	.c-feedback__icon {
		margin-left: var(--spacing-16);
		fill: currentColor;
	}

	.spacer-24 {
		height: var(--spacing-24);
	}

	.spacer-12 {
		height: var(--spacing-12);
	}

	.paragraph {
		font-weight: bold;
		margin-bottom: var(--spacing-8);
	}

	.input-customisation {
		border-radius: var(--spacing-4);
	}

	.submit-container {
		display: flex;
		justify-content: flex-end;
	}

	.feedback-thanks {
		font-weight: bold;
		font-size: 32px;
		padding: var(--spacing-32) 0 var(--spacing-16);
		text-align: center;
	}
</style>
