export const sortCardsBy = (sortBy, cards) => {
	switch (sortBy) {
		case 'no_sorting':
			return cards;
		case 'better_known_first':
			return cards.slice().sort((a, b) => b.knownNumber - a.knownNumber);
		case 'less_known_first':
			return cards.slice().sort((a, b) => a.knownNumber - b.knownNumber);
		default:
			return cards;
	}
};
