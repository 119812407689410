<template>
	<div class="c-footer">
		<div class="ui container c-footer__content">
			<a
				class="ui circular facebook icon mini button c-footer__button"
				:title="$t('multideckerOnFacebook')"
				:aria-label="$t('multideckerOnFacebook')"
				href="https://facebook.com/multidecker"
				target="_blank"
			>
				<i class="facebook f icon" />
			</a>
			<RouterLink :to="{ name: 'privacyPolicy' }">
				{{ $t('privacyPolicy') }}
			</RouterLink>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Footer',
    };
</script>

<style scoped>
	.c-footer {
		background: var(--shark);
		padding: var(--spacing-12) 0;
		color: var(--pure-white);
	}

	.c-footer__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.c-footer__button:hover {
		text-decoration: none;
	}

	a {
		color: var(--pure-white);
		text-decoration: underline;
	}

	a:hover {
		text-decoration: underline;
	}
</style>
