var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "deckResults",
      staticClass: "c-deck-results",
      class: { "c-deck-results--modal-look": _vm.modalLook },
    },
    [
      _c(
        "div",
        { staticClass: "c-hero c-hero--center-aligned inverted-colors" },
        [
          _c("div", { staticClass: "ui container" }, [
            _c("div", [
              _vm.initialStreak !== _vm.streak
                ? _c(
                    "div",
                    {
                      staticClass:
                        "c-deck-results__heading c-deck-results__heading--variant",
                    },
                    [
                      _c(
                        "Carousel",
                        {
                          attrs: {
                            "per-page": 1,
                            "mouse-drag": true,
                            autoplay: true,
                            "autoplay-hover-pause": true,
                            "autoplay-timeout": 3700,
                            "pagination-color": "#bdbdbd",
                            "pagination-active-color": "#ffffff",
                          },
                        },
                        [
                          _c(
                            "Slide",
                            [
                              _c("ResultsHeader", {
                                attrs: {
                                  fff: _vm.fff,
                                  ggg: _vm.ggg,
                                  "known-cards": _vm.knownCards,
                                  "question-cards-number":
                                    _vm.questionCardsNumber,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("Slide", [
                            _c(
                              "div",
                              { staticClass: "c-deck-results__heading-streak" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ui statistics small inverted",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "statistic",
                                        attrs: {
                                          "data-qa":
                                            "deck-results-heading-streak",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.$t("stats.currRunStreak")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "value" }, [
                                          _c(
                                            "span",
                                            [
                                              _vm.hhh
                                                ? _c("Roller", {
                                                    staticClass:
                                                      "roller-inline",
                                                    attrs: {
                                                      text: String(_vm.streak),
                                                      "is-number-format": true,
                                                      transition: 1,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "stats.day",
                                                      _vm.streak,
                                                      { amount: _vm.streak }
                                                    )
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "c-deck-results__heading" },
                    [
                      _c("ResultsHeader", {
                        attrs: {
                          fff: _vm.fff,
                          ggg: _vm.ggg,
                          "known-cards": _vm.knownCards,
                          "question-cards-number": _vm.questionCardsNumber,
                        },
                      }),
                    ],
                    1
                  ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "c-hero__btn-container c-hero__btn-container--centered",
              },
              [
                _c(
                  "RouterLink",
                  {
                    staticClass: "c-btn c-btn--icon",
                    class: {
                      "c-btn--cta": !_vm.nextDeckSuggestionFeature,
                      "c-btn--white": _vm.nextDeckSuggestionFeature,
                    },
                    attrs: {
                      title: _vm.$t("play"),
                      "data-qa": "play-again-btn",
                      to: { name: "listPlay", params: { id: _vm.seoDeckId } },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onPlayAgainLinkClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "c-btn__icon game icon" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-btn__icon-text" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.$t("deckPlay.playAgain")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("ShareDeck", {
                  attrs: { deck: _vm.deck, context: _vm.labels.resultsModal },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--white c-btn--just-icon",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleFavourites },
                  },
                  [
                    _c("AddToFavourites", {
                      attrs: { list: _vm.list, outline: true },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ui container c-deck-details__content" },
        [
          _c("ResultsCards", {
            attrs: {
              answers: _vm.answers,
              deck: _vm.deck,
              cards: _vm.questionCards,
              "disable-controls": { answersVisibility: true },
              "display-progress-difference": true,
            },
          }),
          _vm._v(" "),
          !_vm.isLoggedIn && _vm.showRegisterSection
            ? _c("CreateAccountTeaser", {
                ref: "createAccountTeaser",
                attrs: {
                  "on-sign-up-click": function () {
                    return _vm.onSignUpClick("signUpAction")
                  },
                  "on-sign-in-click": _vm.onSignInClick,
                  "header-text": "youTooCanCreateDeck",
                  "button-text": "createDeckNow",
                  "show-already-signed-up-section": false,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldDisplay
            ? _c(
                "div",
                {
                  ref: "feedbackForm",
                  staticClass: "c-feedback",
                  attrs: { "data-qa": "do-you-like-md-section" },
                },
                [
                  _c("p", { staticClass: "c-feedback__title ui header" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.$t("satisfactionFeedback.doYouLikeMultidecker")
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("FeedbackAnswers", {
                    attrs: { "on-feedback": _vm.feedback },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-deck-results__decks-teaser" },
            [
              _vm.isAdmin
                ? _c("SimilarDecks2", {
                    attrs: { "results-amount": 12, deck: _vm.deck },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("SimilarDecks", { attrs: { "results-amount": 12 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-deck-results__feedback" },
            [_c("Feedback", { attrs: { context: _vm.context.resultsPage } })],
            1
          ),
          _vm._v(" "),
          _c(
            "PopOver",
            { attrs: { show: _vm.feedbackPopOverOpened, position: "bottom" } },
            [
              _c("div", { staticClass: "c-popover-body--one" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "c-popover-body__close-btn-wrapper c-popover-body__close-btn-wrapper--floating",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "c-popover-body__close-btn",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.feedbackPopOverOpened = false
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "ui close icon c-popover-body__close-btn-icon",
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.feedbackType === "like"
                  ? _c("div", [
                      _c("p", { staticClass: "ui medium header" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.$t(
                                "satisfactionFeedback.greatPleaseTakeAMoment"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("iframe", {
                        staticStyle: {
                          border: "none",
                          overflow: "hidden",
                          width: "auto",
                        },
                        attrs: {
                          src: "https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Ffacebook.com%2Fmultidecker&width=450&layout=standard&action=like&size=large&share=false&height=35&appId=1152850601417405",
                          height: "35",
                          scrolling: "no",
                          frameborder: "0",
                          allowTransparency: "true",
                          allow: "encrypted-media",
                        },
                      }),
                    ])
                  : _c("div", [
                      _c("p", { staticClass: "ui medium header" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.$t(
                                "satisfactionFeedback.thankYouForYourFeedback"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("satisfactionFeedback.weAreConstantlyTrying")
                          ),
                        },
                      }),
                    ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.nextDeckSuggestionFeature
            ? _c(
                "div",
                { staticStyle: { "--movement-distance": "80px" } },
                [
                  _c("Transition", { attrs: { name: "appear" } }, [
                    _vm.suggestedNextDeck && _vm.zzz
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "c-deck-results__suggested-deck-container",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ui container" },
                              [
                                _c("DeckPlayerInfo", {
                                  staticClass:
                                    "c-deck-results__suggested-deck-player",
                                  attrs: {
                                    inverted: "",
                                    "play-next-buttons": "",
                                    deck: _vm.suggestedNextDeck,
                                  },
                                  on: {
                                    "play-next-deck": _vm.onPlayNextButtonClick,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }