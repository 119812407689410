<template>
	<span>
		<slot
			:foreground="foreground"
			:background="background"
		/>
	</span>
</template>

<script>
import { getColorFromText, getContrastColor } from '@/assets/js/utils/textColors';

export default {
	name: 'TextColorsRenderer',
	props: {
		text: {
			type: String,
			required: true,
		},
	},
	computed: {
		foreground() {
			return getContrastColor(this.background);
		},
		background() {
			return getColorFromText(this.text);
		},
	},
};
</script>
