var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "heart icon c-btn__icon",
    class: { outline: !_vm.isFavourites },
    style: _vm.color ? { color: _vm.color } : { color: "var(--orange-red)" },
    on: {
      click: function ($event) {
        $event.preventDefault()
        $event.stopPropagation()
        return _vm.toggleFavourites.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }