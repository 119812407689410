<template>
	<h2
		class="h3 c-hero__title"
		style="min-height: 105px;"
	>
		<Transition name="t-fade">
			<span
				v-if="fff"
				style="display: inline-block"
				data-qa="deck-results-heading-one"
			>
				{{ $t('deckPlay.scoreIsPrefix') }}
				<Roller
					class="roller-inline"
					:text="String(knownCards)"
					:is-number-format="true"
					:transition="1"
				/>
				{{ $t('deckPlay.scoreIsSuffix', { total: questionCardsNumber }) }}
			</span>
		</Transition>
		<br>
		<Transition name="t-fade">
			<span
				v-if="ggg"
				style="display: inline-block"
				data-qa="deck-results-heading-two"
			>{{ getResultMessage(knownCards/questionCardsNumber) }}</span>
		</Transition>
	</h2>
</template>

<script>
	import Roller from 'vue-roller';

	export default {
		name: 'ResultsHeader',
		components: {
			Roller,
		},
		props: {
			fff: {
				type: Boolean,
				default: false,
			},
			ggg: {
				type: Boolean,
				default: false,
			},
			knownCards: {
				type: Number,
			},
			questionCardsNumber: {
				type: Number,
			},
		},
		data() {
			return {
				resultMessages: [
					[1, this.$t('deckPlay.results.correctPercent100')],
					[0.75, this.$t('deckPlay.results.correctPercent75')],
					[0.6, this.$t('deckPlay.results.correctPercent60')],
					[0.4, this.$t('deckPlay.results.correctPercent40')],
					[0.25, this.$t('deckPlay.results.correctPercent25')],
					[0.1, this.$t('deckPlay.results.correctPercent10')],
					[0, this.$t('deckPlay.results.correctPercent0')],
				],
			};
		},
		methods: {
			getResultMessage(knownFactor) {
				const messageData = this.resultMessages.find(message => knownFactor >= message[0]);
				return messageData ? messageData[1] : '';
			},
		},
	};
</script>
