<template>
	<DecksContainer
		:decks="decks"
		:title="$t('dashboard.mostFavourite.title')"
		:fetch-decks="fetchDecks"
		:request-more-decks="requestMoreDecks"
		:results-amount="resultsAmount"
		:more-link="resultsAmount ? 'mostFavourite' : ''"
	/>
</template>
<script>
	import DecksContainer from '@/components/dashboard/DecksContainer';

	export default {
		name: 'MostFavourite',
		components: {
			DecksContainer,
		},
		props: {
			resultsAmount: {
				type: Number,
				default: null,
			},
		},
		computed: {
			decks() {
				if (typeof this.resultsAmount === 'number') {
					return this.$store.getters.mostFavouriteLists.slice(0, this.resultsAmount);
				}
				return this.$store.getters.mostFavouriteLists;
			},
			requestMoreDecks() {
				if (typeof this.resultsAmount === 'number') {
					return;
				}
				return () => this.$store.dispatch('loadMoreMostFavouriteLists');
			},
		},
		methods: {
			fetchDecks() {
				return this.$store.dispatch('fetchMostFavouriteLists');
			},
		},
	};
</script>
