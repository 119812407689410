var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", [
    _c(
      "ul",
      { staticClass: "c-page-nav__nav-list" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "li",
          { key: item.link.name, staticClass: "c-page-nav__li" },
          [
            _c(
              "RouterLink",
              {
                staticClass: "c-page-nav__link",
                class: {
                  "c-page-nav__link--disabled": item.disabled,
                },
                attrs: {
                  "exact-active-class": "c-page-nav__link--active",
                  tabindex: item.disabled ? -1 : 0,
                  event: item.disabled ? "" : "click",
                  to: item.link,
                },
                nativeOn: {
                  click: function ($event) {
                    item.disabled ? _vm.showRegisterModal() : function () {}
                  },
                },
              },
              [
                _c("i", { class: "c-page-nav__icon icon " + item.icon }),
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.$t(item.text)) + "\n\t\t\t"),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }