<template>
	<div
		class="empty-state-decks"
	>
		<EmptyStateDecksIllustration class="empty-state-decks__illustration" />
		<div class="empty-state-decks__title">
			<span v-if="context === 'decks'">{{ $t('dashboard.noDecksHere') }}</span>
			<span v-else-if="context === 'cards'">{{ $t('dashboard.noCardsHere') }}</span>
			<span v-else>{{ $t('dashboard.noQuestionsHere') }}</span>
		</div>
		<slot />
	</div>
</template>

<script>
    import EmptyStateDecksIllustration from '@/components/EmptyStateDecksIllustration';

	export default {
		name: 'EmptyStateDecks',
		components: { EmptyStateDecksIllustration },
		props: {
			context: {
				type: String,
				default: 'decks',
				validator: val => ['cards', 'decks', 'questions'].includes(val),
			},
		},
	};
</script>

<style scoped>
	.empty-state-decks {
		display: flex;
		flex-direction: column;
		width: 250px;
		align-items: center;
		text-align: center;
		justify-self: center;
	}

	.empty-state-decks__illustration {
		width: 200px;
	}

	.empty-state-decks__title {
		font-weight: bold;
		font-size: 16px;
		margin-bottom: var(--spacing-4);
	}
</style>
