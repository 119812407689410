<template>
	<DecksContainer
		:decks="localDecks"
		:title="displayTitle ? $t('dashboard.yours.title') : undefined"
		:fetch-decks="fetchDecks"
		:request-more-decks="requestMoreDecks"
		:results-amount="resultsAmount"
		:more-link="resultsAmount ? 'yours' : ''"
		show-set-theme-image-button
		:show-cta-buttons="showCtaButtons"
		:is-link="isLink"
		:selected-deck-id="selectedDeckId"
		@card-clicked="onCardClicked"
	>
		<template slot="noDecksMessage">
			{{ $t('dashboard.feelsLikeMakingAnyNewOnes') }}
		</template>
	</DecksContainer>
</template>
<script>
	import DecksContainer from '@/components/dashboard/DecksContainer';

	export default {
		name: 'Yours',
		components: {
			DecksContainer,
		},
		props: {
			resultsAmount: {
				type: Number,
				default: null,
			},
			showCtaButtons: {
				type: Boolean,
				default: true,
			},
			isLink: {
				type: Boolean,
				default: true,
			},
			selectedDeckId: {
				type: [String, null],
				default: null,
			},
			displayTitle: {
				type: Boolean,
				default: true,
			},
			decks: {
				type: [Array, undefined],
				default: undefined,
			},
		},
		computed: {
			localDecks() {
				this.resultsAmount;
				this.$store.getters.userLists;

				if (Array.isArray(this.decks)) {
					return this.decks;
				}

				if (typeof this.resultsAmount === 'number') {
					return this.$store.getters.userLists.slice(0, this.resultsAmount);
				}

				return this.$store.getters.userLists;
			},
			requestMoreDecks() {
				if (typeof this.resultsAmount === 'number') {
					return;
				}
				return () => this.$store.dispatch('loadMoreUserLists');
			},
		},
		methods: {
			fetchDecks() {
				return this.$store.dispatch('fetchUserLists');
			},
			onCardClicked(e) {
				this.$emit('card-clicked', e);
			},
		},
	};
</script>
