var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-create-account-teaser c-hero inverted-colors" },
    [
      _c("div", { staticClass: "ui basic center aligned segment" }, [
        _c(
          "div",
          { staticClass: "h3 c-hero__title c-create-account-teaser__title" },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.$t(_vm.headerText)) + "\n\t\t")]
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "c-btn c-btn--cta",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.onSignUpClick && _vm.onSignUpClick()
                },
              },
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(_vm.$t(_vm.buttonText)) + "\n\t\t\t")]
          ),
          _c("br"),
          _vm._v(" "),
          _vm.showAlreadySignedUpSection
            ? _c("span", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.$t("header.alreadyHaveAnAccount")) +
                    "\n\t\t\t\t"
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "c-btn c-btn--tertiary c-btn--small c-btn--inverted c-create-account-teaser__sign-in-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.onSignInClick && _vm.onSignInClick()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t("header.signIn")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }