<template>
	<div
		ref="cursor"
		class="c-cursor"
	/>
</template>

<script>
	export default {
		name: 'CustomCursor',
		mounted() {
			document.addEventListener('mousemove', (e) => {
				this.$refs.cursor.style.left = `${e.clientX}px`;
				this.$refs.cursor.style.top = `${e.clientY}px`;
			});

			document.addEventListener('mousedown', () => {
				this.$refs.cursor.classList.add('c-cursor--pressed');
			});

			document.addEventListener('mouseup', () => {
				this.$refs.cursor.classList.remove('c-cursor--pressed');
			});
		},
	};
</script>

<style scoped>
	* {
		/*cursor: none !important;*/
	}

	.c-cursor {
		--size: 40px;
		--pre-size: calc(var(--size) * 1.2);
		--animation-timing: 180ms;

		z-index: 100000;
		width: var(--pre-size);
		height: var(--pre-size);
		border-radius: 100%;
		background-color: rgba(233, 30, 99, 0.3);
		border-width: 2px;
		border-style: solid;
		border-color: rgba(233, 30, 99, .6);
		position: fixed;
		transform: translate(-50%, -50%);
		pointer-events: none;
		transition:
				width ease-in-out var(--animation-timing),
				height ease-in-out var(--animation-timing),
				margin-top ease-in-out var(--animation-timing),
				margin-left ease-in-out var(--animation-timing),
				border-width ease-in-out calc(var(--animation-timing) * 0.9),
				background-color ease-in-out var(--animation-timing);
	}

	.c-cursor--pressed {
		border-width: 5px;
		background-color: rgba(233, 30, 99, 1);
		width: var(--size);
		height: var(--size);
	}
</style>
