export const categories = Object.freeze({
	DEFAULT: Symbol('default'),
	CUSTOM: Symbol('custom'),
	WORD_CUSTOM: Symbol('word_custom'),
	SINGLE_CHOICE: Symbol('single_choice'),
	MULTIPLE_CHOICE: Symbol('multiple_choice'),
});

export const categoriesSymbolToString = new Map([
	[categories.DEFAULT, 'default'],
	[categories.CUSTOM, 'two_categories'],
	[categories.WORD_CUSTOM, 'word_custom'],
	[categories.SINGLE_CHOICE, 'single_choice'],
	[categories.MULTIPLE_CHOICE, 'multiple_choice'],
]);

export const categoriesStringToSymbol = new Map([
	['default', categories.DEFAULT],
	['custom', categories.CUSTOM],
	['two_categories', categories.CUSTOM],
	['word_custom', categories.WORD_CUSTOM],
	['single_choice', categories.SINGLE_CHOICE],
	['multiple_choice', categories.MULTIPLE_CHOICE],
]);

export const defaultCategories = $t => ([
	{ displayName: $t('deckPlay.notYet') },
	{ displayName: $t('deckPlay.IKnow') },
]);
