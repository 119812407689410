export const throwOutConfidence = {
	horizontal(xOffset, _, element) {
		xOffset = Math.abs(xOffset);

		return Math.min(
			xOffset / (element.offsetWidth * 0.3),
			1,
		);
	},
	both(xOffset, yOffset, element) {
		xOffset = Math.abs(xOffset);
		yOffset = Math.abs(yOffset);

		const offset = Math.max(xOffset, yOffset);

		return Math.min(
			offset / (element.offsetWidth * 0.3),
			1,
		);
	},
};

export const SIDES = {
	FRONT: 'Front',
	BACK: 'Back',
};
