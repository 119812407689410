<template>
	<span>
		<button
			type="button"
			class="ui mini button"
			@click="flipToFront"
		>
			<i class="share icon" />
			{{ $t('deckCreate.front') }}
		</button>
		<button
			type="button"
			class="ui mini button"
			@click="flipToBack"
		>
			<i class="reply icon" />
			{{ $t('deckCreate.back') }}
		</button>
	</span>
</template>

<script>
	import { SIDES } from '@/components/swing/swing';

	export default {
		name: 'FlipCardsButtons',
		props: {
			visibleSide: String,
		},
		methods: {
			flipToFront() {
				this.$emit('visible-side', SIDES.FRONT);
			},
			flipToBack() {
				this.$emit('visible-side', SIDES.BACK);
			},
		},
	};
</script>
