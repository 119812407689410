import Vue from 'vue';

const getDefaultState = () => ({
	answers: [],
	sessionCardsOrder: {},
});

export default () => ({
	namespaced: true,
	state: getDefaultState(),
	getters: {
		sessionAnswers(state) {
			state.answers;
			return (sessionId) => {
				const result = {};

				state.answers
					.filter(answer => answer.sessionId === sessionId)
					.forEach(({ cardId, answer }) => {
						result[cardId] = answer;
					});
				return result;
			};
		},
	},
	mutations: {
		setAnswer(state, { cardId, answer, sessionId }) {
			state.answers.push({
				cardId,
				answer,
				sessionId,
			});
		},
		setSessionCardsOrder(state, { sessionId, cardIds }) {
			Vue.set(state.sessionCardsOrder, sessionId, cardIds);
		},
	},

	actions: {
		setAnswer({ commit }, payload) {
			commit('setAnswer', payload);
		},
		setSessionCardsOrder({ commit }, payload) {
			commit('setSessionCardsOrder', payload);
		},
	},
});
