<template>
	<div
		class="c-tracker"
		:class="{ 'c-tracker--active': pressed }"
	/>
</template>

<script>
	export default {
		name: 'ClickTracker',
		props: {
			pressed: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>

<style scoped>
	.c-tracker {
		--size: 25px;
		--pre-size: calc(var(--size) * 1.2);
		--animation-timing: 180ms;

		width: var(--pre-size);
		height: var(--pre-size);
		border-radius: 100px;
		background-color: rgba(233, 30, 99, 0);
		top: 50%;
		left: 50%;
		margin-top: calc(var(--pre-size) * -0.5);
		margin-left: calc(var(--pre-size) * -0.5);
		position: absolute;
		z-index: 2;

		border-width: 0;
		border-style: solid;
		border-color: rgba(233, 30, 99, 1);

		transition:
				width ease-in-out var(--animation-timing),
				height ease-in-out var(--animation-timing),
				margin-top ease-in-out var(--animation-timing),
				margin-left ease-in-out var(--animation-timing),
				border-width ease-in-out calc(var(--animation-timing) * 0.9),
				background-color ease-in-out var(--animation-timing);
	}

	.c-tracker--active {
		border-width: 5px;
		background-color: rgba(233, 30, 99, 1);
		width: var(--size);
		height: var(--size);
		margin-top: calc(var(--size) * -0.5);
		margin-left: calc(var(--size) * -0.5);

	}
</style>
