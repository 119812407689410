/* eslint no-bitwise: 0 */

// https://chatgpt.com/c/f548fd32-17ca-43f5-b5a3-b5707578c74d


// Hash function to convert text to a numeric value
function hashCode(str) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		// eslint-disable-next-line no-bitwise
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

// Convert the hash to an RGB color
// function intToRGB(i) {
// 	let c = (i & 0x00FFFFFF)
// 		.toString(16)
// 		.toUpperCase();
// 	return '00000'.substring(0, 6 - c.length) + c;
// }

function intToPastelRGB(hash) {
	// Use the hash to generate RGB values
	let r = (hash >> 16) & 0xFF;
	let g = (hash >> 8) & 0xFF;
	let b = hash & 0xFF;

	// Convert to pastel by averaging with white (255, 255, 255)
	r = Math.floor((r + 255) / 2);
	g = Math.floor((g + 255) / 2);
	b = Math.floor((b + 255) / 2);

	// Convert RGB to hex and ensure it has 6 characters
	let hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).toUpperCase().slice(1);

	return hex;
}

// Function to convert hex color to RGB
function hexToRGB(hex) {
	// Remove the hash at the start if it's there
	hex = hex.replace(/^#/, '');

	// Parse the r, g, b values
	let bigint = parseInt(hex, 16);
	let r = (bigint >> 16) & 255;
	let g = (bigint >> 8) & 255;
	let b = bigint & 255;

	return { r, g, b };
}

// Function to calculate the brightness of a color
function calculateBrightness(r, g, b) {
	// Using the formula for luminance perception
	return (r * 299 + g * 587 + b * 114) / 1000;
}

// Function to determine the best contrast color (black or white) based on the input color
export function getContrastColor(hexColor) {
	const { r, g, b } = hexToRGB(hexColor);
	const brightness = calculateBrightness(r, g, b);
	// If brightness is less than 128, return white, otherwise return black
	return brightness < 128 ? '#FFFFFF' : '#000000';
}

// Function to generate a color based on text
export function getColorFromText(text) {
	// Generate hash from the text
	const hash = hashCode(text);

	// Convert hash to RGB color and return as HEX string
	return `#${intToPastelRGB(hash)}`;
}
