var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass: "ui mini button",
        attrs: { type: "button" },
        on: { click: _vm.flipToFront },
      },
      [
        _c("i", { staticClass: "share icon" }),
        _vm._v("\n\t\t" + _vm._s(_vm.$t("deckCreate.front")) + "\n\t"),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "ui mini button",
        attrs: { type: "button" },
        on: { click: _vm.flipToBack },
      },
      [
        _c("i", { staticClass: "reply icon" }),
        _vm._v("\n\t\t" + _vm._s(_vm.$t("deckCreate.back")) + "\n\t"),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }