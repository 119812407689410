<template>
	<div
		class="settings-container"
		:class="{ 'settings-container--knowledge-progress': knowledgeProgress }"
	>
		<div
			v-if="toggleShowAnswers && disableControls.answersVisibility !== true"
			class="control-with-title"
			data-qa="control--answers-visibility"
			:class="{ 'control-with-title--inline': inline }"
		>
			<div class="control-with-title__title">
				{{ $t('answersVisibility') }}:
			</div>
			<div class="control-with-title__control">
				<div class="ui icon mini buttons">
					<button
						class="ui button"
						:class="{ 'grey': showAnswers }"
						type="button"
						:title="'Show cards progress'"
						@click="_toggleShowAnswers(true)"
					>
						<i class="eye icon" />
						{{ $t('show') }}
					</button>
					<button
						class="ui button"
						:class="{ 'grey': !showAnswers }"
						type="button"
						:title="'Hide cards progress'"
						@click="_toggleShowAnswers(false)"
					>
						<i class="eye slash icon" />
						{{ $t('hide') }}
					</button>
				</div>
			</div>
		</div>
		<div
			v-if="resultDisplayType && disableControls.displayAs !== true"
			class="control-with-title"
			data-qa="control--display-as"
			:class="{ 'control-with-title--inline': inline }"
		>
			<div class="control-with-title__title">
				{{ $t('displayAs') }}:
			</div>
			<div class="control-with-title__control">
				<div class="ui icon mini buttons">
					<button
						class="ui button"
						data-qa="control--display-as-cards-btn"
						:class="{ grey: resultDisplayType === resultDisplayTypes.CARDS}"
						@click="_changeResultDisplayType(resultDisplayTypes.CARDS)"
					>
						<i class="th icon" />
						{{ $t('cards') }}
					</button>
					<button
						class="ui button"
						data-qa="control--display-as-table-btn"
						:class="{ grey: resultDisplayType === resultDisplayTypes.TABLE}"
						@click="_changeResultDisplayType(resultDisplayTypes.TABLE)"
					>
						<i class="th list icon" />
						{{ $t('table') }}
					</button>
				</div>
			</div>
		</div>
		<div
			v-if="disableControls.flipAllCardsTo !== true"
			class="control-with-title"
			data-qa="control--flip-all-cards-to"
			:class="{ 'control-with-title--inline': inline }"
		>
			<div class="control-with-title__title">
				{{ $t('flipAllCardsTo') }}:
			</div>
			<div class="control-with-title__control">
				<FlipCardsButtons
					:visible-side="visibleCardsSide"
					@visible-side="_updateVisibleCardsSide"
				/>
			</div>
		</div>
		<div
			v-if="knowledgeProgress && disableControls.progressVisibility !== true"
			class="control-with-title"
			data-qa="control--progress-visibility"
			:class="{ 'control-with-title--inline': inline }"
		>
			<div class="control-with-title__title">
				{{ $t('progressVisibility') }}:
			</div>
			<div class="control-with-title__control">
				<div class="ui icon mini buttons">
					<button
						class="ui button"
						:class="{ 'grey': progressVisible }"
						type="button"
						:title="'Show cards progress'"
						@click="_toggleProgressVisible(true)"
					>
						<i class="eye icon" />
						{{ $t('show') }}
					</button>
					<button
						class="ui button"
						:class="{ 'grey': !progressVisible }"
						type="button"
						:title="'Hide cards progress'"
						@click="_toggleProgressVisible(false)"
					>
						<i class="eye slash icon" />
						{{ $t('hide') }}
					</button>
				</div>
			</div>
		</div>
		<div
			v-if="knowledgeProgress && disableControls.sortCardsBy !== true"
			class="control-with-title"
			data-qa="control--sort-cards-by"
			:class="{ 'control-with-title--inline': inline }"
		>
			<div class="control-with-title__title">
				{{ $t('sortCardsBy') }}:
			</div>
			<div class="control-with-title__control">
				<div class="ui icon mini buttons">
					<button
						class="ui button"
						:class="{ grey: sortBy === 'no_sorting'}"
						@click="_updateSortBy('no_sorting')"
					>
						<i class="sort icon" />
					</button>
					<button
						class="ui button"
						:class="{ grey: sortBy === 'better_known_first'}"
						@click="_updateSortBy('better_known_first')"
					>
						<i class="sort amount down icon" />
					</button>
					<button
						class="ui button"
						:class="{ grey: sortBy === 'less_known_first'}"
						@click="_updateSortBy('less_known_first')"
					>
						<i class="sort amount up icon" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { logAnalyticsEvent, events } from '@/assets/js/utils/analytics';
	import { SIDES } from '@/components/swing/swing';
	import FlipCardsButtons from '@/components/buttons/FlipCardsButtons';

	export const resultDisplayTypes = {
		TABLE: 'TABLE',
		CARDS: 'CARDS',
	};

	export default {
		name: 'DeckCardsConfiguration',
		components: { FlipCardsButtons },
		props: {
			disableControls: {
				type: Object,
				default: () => ({
					answersVisibility: false,
					displayAs: false,
					flipAllCardsTo: false,
					progressVisibility: false,
					sortCardsBy: false,
				}),
			},
			inline: {
				type: Boolean,
				default: false,
			},

			knowledgeProgress: {
				type: Boolean,
				default: false,
			},

			visibleCardsSide: {
				type: String,
				default: SIDES.FRONT,
			},
			updateVisibleCardsSide: {
				type: Function,
			},

			progressVisible: {
				type: Boolean,
				default: true,
			},
			toggleProgressVisible: {
				type: Function,
			},

			showAnswers: {
				type: Boolean,
				default: true,
			},
			toggleShowAnswers: {
				type: Function,
			},

			resultDisplayType: {
				type: String,
				default: null,
			},
			changeResultDisplayType: {
				type: Function,
			},

			sortBy: {
				type: String,
				default: 'no_sorting',
			},
			updateSortBy: {
				type: Function,
			},
		},
		data() {
			return {
				resultDisplayTypes,
			};
		},
		methods: {
			_toggleShowAnswers(showIntention) {
				if (showIntention === this.showAnswers) {
					return;
				}

				logAnalyticsEvent(
						...(this.showAnswers ? events.hideAnswersButtonClick : events.showAnswersButtonClick),
);
				this.toggleShowAnswers();
			},
			_changeResultDisplayType(displayType) {
				if (displayType === this.resultDisplayType) {
					return;
				}

				logAnalyticsEvent(
						...(displayType === resultDisplayTypes.TABLE ? events.displayAsTableButtonClick : events.displayAsCardsButtonClick),
				);
				this.changeResultDisplayType(displayType);
			},
			_updateVisibleCardsSide(cardSide) {
				logAnalyticsEvent(
					...(cardSide === 'Front' ? events.flipCardFrontButtonClick : events.flipCardBackButtonClick),
				);
				this.updateVisibleCardsSide(cardSide);
			},
			_toggleProgressVisible(showIntention) {
				if (showIntention === this.progressVisible) {
					return;
				}

				logAnalyticsEvent(
					...(this.progressVisible ? events.hideProgressButtonClick : events.showProgressButtonClick),
				);
				this.toggleProgressVisible();
			},
			_updateSortBy(sortBy) {
				if (sortBy === this.sortBy) {
					return;
				}

				const event = {
					no_sorting: events.sortByKnowledgeNoSortingButtonClick,
					better_known_first: events.sortByKnowledgeBetterKnownFirstButtonClick,
					less_known_first: events.sortByKnowledgeLessKnownFirstButtonClick,
				};

				logAnalyticsEvent(...event[sortBy]);
				this.updateSortBy(sortBy);
			},
		},
	};
</script>
<style lang="scss" scoped>
@mixin inline {
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.settings-container {
	display: flex;
	grid-column-gap: var(--spacing-12);
	grid-row-gap: var(--spacing-12);
	flex-wrap: wrap;
}

.control-with-title {
	display: flex;
	flex-direction: column;
}

:not(.settings-container--knowledge-progress) .control-with-title {
	@media only screen and (max-width: 330px) {
		@include inline;
	}
}

.control-with-title--inline {
	@include inline;
}

.control-with-title__title {
	font-weight: bold;
	font-size: 12px;
	margin-bottom: var(--spacing-4);
}
</style>
