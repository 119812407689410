import pl from '@/assets/svg/pl.svg';
import gb from '@/assets/svg/gb.svg';
import us from '@/assets/svg/us.svg';

const isLocaleUS = navigator && navigator.language.toLowerCase() === 'en-us';

const flags = {
	pl,
	gb,
	us,
};

export const locales = [
	{
		text: 'Polski',
		flagUrl: flags.pl,
		code: 'pl',
	},
	{
		text: 'English',
		flagUrl: isLocaleUS ? flags.us : flags.gb,
		code: 'en',
	},
];
