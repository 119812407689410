<template>
	<Transition name="fade">
		<div
			v-show="active"
			:class="{ 'dimmer': true, 'dimmer--blur': blur }"
		>
			<slot />
		</div>
	</Transition>
</template>

<script>
	export default {
		name: 'Dimmer',
		props: {
			active: {
				type: Boolean,
				default: false,
			},
			blur: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>

<style scoped>
	.dimmer {
		--z-index: 1000;
		position: fixed;
		top: 0 !important;
		left: 0 !important;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		padding: 1em;
		background-color: rgba(0, 0, 0, 0.85);
		line-height: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		will-change: opacity;
		z-index: var(--z-index);
	}

	.dimmer--blur {
		background-color: unset;
		backdrop-filter: blur(2px);
	}

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s ease;
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>
